import { AUTH_STATUS, CUSTOM_AUTH_STATUS, FLOW_TYPE } from 'appConstants/auth';
import { LazyLoadOptions, LazyLoadedComponentsObject } from 'types/LazyLoad';

export const loadComponentLazily = async (
  lazyLoadedComponents: LazyLoadedComponentsObject,
  status: string,
  lazyLoadOptions: LazyLoadOptions = { isCCPA: true },
  flowType = '',
) => {
  /**
   * Specify all the components that need to be lazy loaded based on the status here.
   * Make sure to add a magic comment for Webpack to name the chunk correctly.
   */
  switch (status) {
    case AUTH_STATUS.VERIFICATION_REQUIRED:
      /**
       * Loading multiple components in parallel based on the type of component requested, eg: in case of MFA, we may need
       * `Verify` another way screen as well - so loading both the components in parallel.
       * Need to specify the path of the components to be loaded.
       * Webpack needs to know the path at compile time to create a separate chunk for the component.
       * This is why we can't use dynamic import with a variable.
       */
      lazyLoadedComponents['MFALandingScreen'] = (
        await import(/* webpackChunkName: "MFALandingScreen" */ 'components/MFAscreens/Landing/MFALandingScreen')
      ).default;

      break;
    case AUTH_STATUS.EMAIL_REQUIRED:
      /**
       * If the user is in CCPA flow, then load the CCPA OTP screen beforehand.
       */
      if (lazyLoadOptions.isCCPA) {
        lazyLoadedComponents['CcpaOtpScreen'] = (
          await import(/* webpackChunkName: "CcpaOtpScreen" */ 'components/CcpaOtpScreen/CcpaOtpScreen')
        ).default;
      }
      break;
    case AUTH_STATUS.OTP_REQUIRED:
      /**
       * Loading to handle the edge case this screen is rendered directly. We can remove this later if its not needed.
       */
      if (lazyLoadOptions.isCCPA) {
        lazyLoadedComponents['CcpaOtpScreen'] = (
          await import(/* webpackChunkName: "CcpaOtpScreen" */ 'components/CcpaOtpScreen/CcpaOtpScreen')
        ).default;
      } else {
        lazyLoadedComponents['MFALandingScreen'] = (
          await import(/* webpackChunkName: "MFALandingScreen" */ 'components/MFAscreens/Landing/MFALandingScreen')
        ).default;
      }
      break;
    case AUTH_STATUS.EMAIL_OTP_REQUIRED:
      if (flowType !== FLOW_TYPE.EMAIL_VERIFICATION) {
        const screensToLoadPromises = [
          import(
            /* webpackChunkName: "VerifyEmailOtpScreen" */ 'common/components/VerifyEmailOtpScreen/VerifyEmailOtpScreen'
          ),
          import(
            /* webpackChunkName: "RegisterUserScreen" */ 'components/CreateAccountScreens/RegisterUser/RegisterUserScreen'
          ),
          import(/* webpackChunkName: "SuccessScreen" */ 'common/components/SuccessScreen/SuccessScreen'),
        ];
        const screens = await Promise.all(screensToLoadPromises);
        lazyLoadedComponents['VerifyEmailOtpScreen'] = screens[0].default;
        lazyLoadedComponents['CreateAccountRegisterUserScreen'] = screens[1].default;
        lazyLoadedComponents['SuccessScreen'] = screens[2].default;
      } else {
        const screensToLoadPromises = [
          import(
            /* webpackChunkName: "VerifyEmailOtpScreen" */ 'common/components/VerifyEmailOtpScreen/VerifyEmailOtpScreen'
          ),
          import(/* webpackChunkName: "SuccessScreen" */ 'common/components/SuccessScreen/SuccessScreen'),
          import(/* webpackChunkName: "ErrorWithAlert" */ 'common/components/ErrorWithAlert/ErrorWithAlert'),
        ];
        const screens = await Promise.all(screensToLoadPromises);
        lazyLoadedComponents['VerifyEmailOtpScreen'] = screens[0].default;
        lazyLoadedComponents['SuccessScreen'] = screens[1].default;
        lazyLoadedComponents['ErrorWithAlert'] = screens[2].default;
      }
      break;
    case AUTH_STATUS.TOS_REQUIRED:
      lazyLoadedComponents['TermsOfServiceScreen'] = (
        await import(
          /* webpackChunkName: "TermsOfServiceScreen" */ 'components/TermsOfServiceScreen/TermsOfServiceScreen'
        )
      ).default;
      break;
    case CUSTOM_AUTH_STATUS.TOTP_AND_SMS_MAX_ATTEMPTS_LIMIT:
      lazyLoadedComponents['MaxRetriesReachedScreen'] = (
        await import(
          /* webpackChunkName: "MaxRetriesReachedScreen" */ 'components/MFAscreens/MaxRetriesReachedScreen/MaxRetriesReachedScreen'
        )
      ).default;
      break;
    case CUSTOM_AUTH_STATUS.PRODUCT_SIGNED_IN:
      /**
       * loading logic wrapped in block to address no-case-declarations eslint error.
       */
      {
        const screensToLoadPromises = [
          import(
            /* webpackChunkName: "ProductSignedInScreen" */ 'components/IDSDKscreens/ProductSignedInScreen/ProductSignedInScreen'
          ),
          import(
            /* webpackChunkName: "OpenProductScreen" */ 'components/IDSDKscreens/OpenProductScreen/OpenProductScreen'
          ),
          import(/* webpackChunkName: "ErrorWithAlert" */ 'common/components/ErrorWithAlert/ErrorWithAlert'),
        ];
        const screens = await Promise.all(screensToLoadPromises);
        lazyLoadedComponents['ProductSignedInScreen'] = screens[0].default;
        lazyLoadedComponents['OpenProductScreen'] = screens[1].default;
        lazyLoadedComponents['ErrorWithAlert'] = screens[2].default;
      }
      break;
    case AUTH_STATUS.MFA_FAILED:
    case CUSTOM_AUTH_STATUS.EMAIL_OTP_MAX_ATTEMPTS_LIMIT:
    case CUSTOM_AUTH_STATUS.EMAIL_RESEND_MAX_ATTEMPTS_LIMIT:
    case CUSTOM_AUTH_STATUS.CREATE_ACCOUNT_LOGIN_DISABLED:
    case CUSTOM_AUTH_STATUS.SIGN_IN_REQUEST_EXPIRED:
      lazyLoadedComponents['ErrorWithAlert'] = (
        await import(/* webpackChunkName: "ErrorWithAlert" */ 'common/components/ErrorWithAlert/ErrorWithAlert')
      ).default;
      break;
    case CUSTOM_AUTH_STATUS.OPT_OUT_OVERLAY_SCREEN:
      lazyLoadedComponents['OptOutOverlay'] = (
        await import(/* webpackChunkName: "OptOutOverlay" */ 'components/OptOutOverlay/OptOutOverlay')
      ).default;
      break;
    case CUSTOM_AUTH_STATUS.FORBID_ENTRY:
    case CUSTOM_AUTH_STATUS.INTERNAL_SERVER_ERROR:
    case CUSTOM_AUTH_STATUS.PAGE_NOT_FOUND:
    case CUSTOM_AUTH_STATUS.REQUEST_ERROR:
    case CUSTOM_AUTH_STATUS.SESSION_ENDED:
    case CUSTOM_AUTH_STATUS.SAML_ASSERTION_ERROR:
    case CUSTOM_AUTH_STATUS.DOMAIN_NOT_FOUND:
    case CUSTOM_AUTH_STATUS.CONNECTION_NOT_FOUND:
    case CUSTOM_AUTH_STATUS.JIT_DISABLED:
    case CUSTOM_AUTH_STATUS.DOMAIN_CONNECTION_MISMATCH:
    case CUSTOM_AUTH_STATUS.USER_DEACTIVATED:
    case CUSTOM_AUTH_STATUS.UNABLE_TO_SIGN_IN_SSO:
    case CUSTOM_AUTH_STATUS.DOMAIN_DISABLED:
      lazyLoadedComponents['GlobalError'] = (
        await import(/* webpackChunkName: "GlobalError" */ 'common/components/GlobalError/GlobalError')
      ).default;
      break;
    // This is to handle scenario in which user refreshes the page on Create Account Register User screen
    // Fetch the screens as it will not be part of the initial load.
    case AUTH_STATUS.USER_INFO_REQUIRED:
      {
        const screensToLoadPromises = [
          import(
            /* webpackChunkName: "RegisterUserScreen" */ 'components/CreateAccountScreens/RegisterUser/RegisterUserScreen'
          ),
          import(/* webpackChunkName: "SuccessScreen" */ 'common/components/SuccessScreen/SuccessScreen'),
        ];
        const screens = await Promise.all(screensToLoadPromises);
        lazyLoadedComponents['CreateAccountRegisterUserScreen'] = screens[0].default;
        lazyLoadedComponents['SuccessScreen'] = screens[1].default;
      }
      break;

    case AUTH_STATUS.RESET_PASSWORD_OTP_REQUIRED:
      {
        const screensToLoadPromises = [
          import(
            /* webpackChunkName: "VerifyEmailOtpScreen" */ 'common/components/VerifyEmailOtpScreen/VerifyEmailOtpScreen'
          ),
          import(/* webpackChunkName: "ResetPasswordScreen" */ 'components/ResetPasswordScreen/ResetPasswordScreen'),
          import(/* webpackChunkName: "SuccessScreen" */ 'common/components/SuccessScreen/SuccessScreen'),
        ];
        const screens = await Promise.all(screensToLoadPromises);
        lazyLoadedComponents['VerifyEmailOtpScreen'] = screens[0].default;
        lazyLoadedComponents['ResetPasswordScreen'] = screens[1].default;
        lazyLoadedComponents['SuccessScreen'] = screens[2].default;
      }
      break;

    case AUTH_STATUS.PASSWORD_UPDATE_REQUIRED:
      {
        const screensToLoadPromises = [
          import(/* webpackChunkName: "ResetPasswordScreen" */ 'components/ResetPasswordScreen/ResetPasswordScreen'),
          import(/* webpackChunkName: "SuccessScreen" */ 'common/components/SuccessScreen/SuccessScreen'),
        ];
        const screens = await Promise.all(screensToLoadPromises);
        lazyLoadedComponents['ResetPasswordScreen'] = screens[0].default;
        lazyLoadedComponents['SuccessScreen'] = screens[1].default;
      }
      break;
    default:
      break;
  }
  return lazyLoadedComponents;
};
