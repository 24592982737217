/** @jsxImportSource @emotion/react */
import Card from '@digital-hig/card';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import CardContent from '@mui/material/CardContent';
import AutodeskLogo from 'common/components/AutodeskLogo/AutodeskLogo';
import Divider from 'common/components/Divider/Divider';
import AppContext from 'context/appContext';
import ErrorBoundary from 'hoc/ErrorBoundary/ErrorBoundary';
import React, { useContext } from 'react';
import { cardContentStyles, cardStyles, logoStyles } from './AppWrapperStyles';

interface AppWrapperProps {
  children: React.ReactNode | EmotionJSX.Element;
}

export const AppWrapper = ({ children }: AppWrapperProps) => {
  const appContext = useContext(AppContext);
  const isGlobalError = appContext.globalError || appContext.fetchingFlowStatus;
  return (
    <Card css={cardStyles(isGlobalError)}>
      <div className="dhig-p-6">
        <div css={logoStyles}>
          <AutodeskLogo />
        </div>
      </div>
      {!isGlobalError && <Divider />}
      <CardContent css={cardContentStyles}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </CardContent>
    </Card>
  );
};
