import { Flow } from 'types';
import { CUSTOM_AUTH_STATUS } from './auth';

// Represents the transition direction of Main Card (Content box) on the screen
const NAVIGATION_DIRECTIONS = {
  FORWARD: 'forward' as Flow['dir'],
  REVERSE: 'reverse' as Flow['dir'],
};

// Routes used in the application
const ROUTES = {
  // Route specific to CCPA
  DOWNLOAD: '/v2/authn/signin',
  // All the below routes are specific to IDP
  BASE: '/',
  LOGOUT: '/logout',
  LOGGED_OUT: '/loggedout',
  IDSDK: '/idmgr/callback',
  SAML_ASSERTION_ERROR: '/saml-error',
  REQUEST_ERROR: '/request-error',
};

// Open routes don't require a flowId to be present in the URL, that's why they are denoted as open routes.
const OPEN_ROUTES = {
  [ROUTES.LOGOUT]: {
    status: CUSTOM_AUTH_STATUS.LOGOUT,
  },
  [ROUTES.LOGGED_OUT]: {
    status: CUSTOM_AUTH_STATUS.LOGGED_OUT,
  },
  [ROUTES.IDSDK]: {
    status: CUSTOM_AUTH_STATUS.PRODUCT_SIGNED_IN,
  },
  [ROUTES.SAML_ASSERTION_ERROR]: {
    status: CUSTOM_AUTH_STATUS.SAML_ASSERTION_ERROR,
  },
  [ROUTES.REQUEST_ERROR]: {
    status: CUSTOM_AUTH_STATUS.REQUEST_ERROR,
  },
};

export { NAVIGATION_DIRECTIONS, OPEN_ROUTES, ROUTES };
