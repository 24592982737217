/* eslint-disable @typescript-eslint/ban-ts-comment */
/** @jsxImportSource @emotion/react */
import Typography from '@digital-hig/typography';
import { EVENT_TEXT, FLOW_NAME, PAGE_VIEWS } from 'appConstants/analytics';
import { CUSTOM_AUTH_STATUS } from 'appConstants/auth';
import { IDP_OPT_OUT } from 'appConstants/optout';
import CustomAlert from 'common/components/CustomAlert/CustomAlert';
import CustomLink from 'common/components/CustomLink/CustomLink';
import authNamespaces from 'nameSpaces/authNameSpaces';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LazyLoadedComponentsObject } from 'types/LazyLoad';
import { getEventLocation, withRetry } from 'utilities/commonUtils';
import { loadComponentLazily } from 'utilities/lazyLoad';
import { trackClick } from 'utilities/tealiumAnalytics';
import { OptOutBannerStyles } from './OptOutBannerStyles';
const OptOutBanner = () => {
  const { t } = useTranslation(authNamespaces.email);
  const [isOptOutOverlayOpen, setOptOutOverlayOpen] = useState(false);
  const lazyLoadedComponents = useRef<LazyLoadedComponentsObject>({});

  useEffect(() => {
    const lazyLoadOptOutOverlay = async () => {
      const lazilyLoadedComponentsWithRetry = withRetry(loadComponentLazily);
      try {
        lazyLoadedComponents.current = await lazilyLoadedComponentsWithRetry(
          lazyLoadedComponents.current,
          CUSTOM_AUTH_STATUS.OPT_OUT_OVERLAY_SCREEN,
          {},
        );
      } catch (error) {
        console.error('Error loading Opt Out Overlay Screen ', error);
      }
    };

    lazyLoadOptOutOverlay();
  }, []);

  const handleClick = () => {
    setOptOutOverlayOpen(true);
    trackClick({
      link_text: EVENT_TEXT.OPT_OUT_OVERLAY,
      link_url: IDP_OPT_OUT,
      outbound: true,
      link_location: getEventLocation(
        document.querySelector('.EMAIL_REQUIRED') ? FLOW_NAME.SIGN_IN : FLOW_NAME.CREATE_ACCOUNT,
        PAGE_VIEWS.EMAIL_SCREEN,
      ),
    });
  };

  return (
    <>
      <CustomAlert cssStyles={OptOutBannerStyles} type="info" variant="filled">
        <Typography>
          <Trans t={t} i18nKey="OPT_OUT_BANNER">
            <CustomLink onClick={handleClick} href="#"></CustomLink>
          </Trans>
        </Typography>
      </CustomAlert>
      {isOptOutOverlayOpen && lazyLoadedComponents.current.OptOutOverlay && (
        <lazyLoadedComponents.current.OptOutOverlay onClose={setOptOutOverlayOpen} />
      )}
    </>
  );
};

export default OptOutBanner;
