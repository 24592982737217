/** @jsxImportSource @emotion/react */
import { AlertIcon } from '@digital-hig/alert';
import Alert from '@mui/material/Alert';
import { customAlert } from 'types';

const CustomAlert: React.FC<customAlert> = ({ type, variant, children, cssStyles }) => {
  return (
    <Alert
      css={cssStyles}
      severity={type}
      variant={variant}
      icon={<AlertIcon severity={type} variant={variant} data-testid={`alert-icon-${type}`} />}
    >
      {children}
    </Alert>
  );
};

export default CustomAlert;
