import Auth from 'components/Auth/Auth';
import { AppWrapper } from 'components/Wrappers/AppWrapper/AppWrapper';

const App = () => {
  return (
    <AppWrapper>
      <Auth />
    </AppWrapper>
  );
};

export default App;
