/** @jsxImportSource @emotion/react */
import IconButton from '@digital-hig/icon-button';
import SvgError from '@digital-hig/icon/lib/esm/display-icons/error';
import Typography from '@digital-hig/typography';
import { SerializedStyles } from '@emotion/utils/types';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input, { InputProps } from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import SvgIcon from '@mui/material/SvgIcon';
import CustomLink from 'common/components/CustomLink/CustomLink';
import { disabledLinkStyles } from 'common/styles/commonStyles';
import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { CustomLinkTrackingData } from 'types/Link';
import { errorLinkStyles, errorTextStyles } from './CustomInputStyles';

export interface CustomInputProps extends InputProps {
  errorMessage?: string;
  showErrorMessage?: boolean;
  label?: string;
  fullWidth?: boolean;
  shrink?: boolean;
  formControlProps?: {
    css?: (SerializedStyles | string)[] | SerializedStyles;
    key?: string;
    sx?: object;
  };
  css?: (SerializedStyles | string)[] | SerializedStyles;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  showEndAdornment?: boolean;
  onErrorTextLinkClick?: () => void;
  errorLinkTarget?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  endAdornmentProps?: {
    id: string;
    ariaLabel: string;
    icon: ReactNode;
    onClick: () => void;
  };
  errorLinkTrackingData?: CustomLinkTrackingData | undefined;
}

const CustomInput: React.FC<CustomInputProps> = ({
  id,
  css,
  name,
  value,
  label,
  onChange,
  disabled,
  errorMessage,
  showErrorMessage = true, // showErrorMessage is for components which want to show error message
  endAdornmentProps,
  formControlProps,
  fullWidth = true,
  required = true,
  shrink = true,
  type = 'text',
  showEndAdornment = false,
  onErrorTextLinkClick,
  errorLinkTarget = '#',
  target = '_blank',
  inputRef = null,
  errorLinkTrackingData,
  ...rest
}) => {
  const onLinkClick = (e: React.FormEvent) => {
    if (onErrorTextLinkClick && errorLinkTarget === '#') {
      e.preventDefault();
      onErrorTextLinkClick();
    }
  };

  return (
    <FormControl disabled={disabled} fullWidth={fullWidth} error={Boolean(errorMessage)} {...formControlProps}>
      {label && (
        <InputLabel shrink={shrink} htmlFor={id}>
          {label}
        </InputLabel>
      )}

      <Input
        id={id}
        value={value}
        name={name}
        onChange={onChange}
        type={type}
        error={Boolean(errorMessage)}
        required={required}
        css={css}
        endAdornment={
          showEndAdornment ? (
            <InputAdornment position="end">
              <IconButton
                id={endAdornmentProps?.id}
                data-testid={endAdornmentProps?.id}
                aria-label={endAdornmentProps?.ariaLabel}
                variant="text"
                size="small"
                onClick={endAdornmentProps?.onClick}
              >
                <SvgIcon viewBox="0 0 24 24">{endAdornmentProps?.icon}</SvgIcon>
              </IconButton>
            </InputAdornment>
          ) : null
        }
        {...(inputRef && { inputRef })}
        {...(errorMessage && { 'aria-describedby': `${name}-error-text` })}
        {...rest}
      />

      {showErrorMessage && errorMessage && (
        <FormHelperText id={`${name}-error-text`}>
          <SvgIcon>
            <SvgError data-testid={`${name}-error-icon`} />
          </SvgIcon>
          <Typography component="span" css={errorTextStyles} data-testid={`${name}-error-text`}>
            <Trans defaults={errorMessage}>
              <CustomLink
                id={`${name}-error-link`}
                href={errorLinkTarget}
                target={target}
                cssStyles={[errorLinkStyles, disabled ? disabledLinkStyles : '']}
                tracking={errorLinkTrackingData}
                {...(onErrorTextLinkClick && { onClick: onLinkClick })}
              />
            </Trans>
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomInput;
