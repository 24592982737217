// Actions that backend accepts for different flows
const AUTH_ACTIONS = {
  // Action to submit email in Sign In / Create Account Email flow
  SUBMIT_EMAIL: 'submitEmail',
  // Action to submit OTP (check correct or not) in OTP based flows (CCPA, Email verification, Create account email verification)
  SUBMIT_OTP: 'submitOtp',
  // Action to submit an OTP (check correct or not) in MFA flow
  CHECK_OTP: 'checkOtp',
  // Action to resend OTP in OTP based flows
  RESEND_OTP: 'resendOtp',
  // Action to send OTP first time, in OTP based flows
  SEND_OTP: 'sendOtp',
  // Action to resend OTP in MFA flow
  SELECT_DEVICE: 'selectDevice',
  /* Action to go back to email screen from
   * Password screen in Sign In flow
   * OTP screen in case of email verification & create account flow
   */
  CLEAR_EMAIL: 'clearEmail',
  // Action to check whether password is valid or not in Sign In flow
  CHECK_PASSWORD: 'checkPassword',
  /* Action to be called -
   * to start the MFA flow after password screen
   * after "cancelAuthentication" action call to switch the MFA device e.g. TOTP to Email OTP
   */
  AUTHENTICATE: 'authenticate',
  // Action to accept Terms of Service in TOS flow
  ACCEPT_TOS: 'acceptTOS',
  // Action to be called after successful MFA completion
  CONTINUE_AUTHENTICATION: 'continueAuthentication',
  // Action to cancel current MFA authentication to switch to another device e.g. switch from TOTP to Email OTP
  CANCEL_AUTHENTICATION: 'cancelAuthentication',
  // Action to start the Create account flow from Sign In screen
  BEGIN_EMAIL_VERIFICATION: 'beginEmailVerification',
  // Action to submit all the user details in Register User screen of Create account flow
  SUBMIT_USER_INFO: 'submitUserInfo',
  // Action to switch to Sign In flow from Create account flow
  SWITCH_TO_LOGIN: 'setAuthnFlowToLogin', // Switch to Sign In flow from Create user flow
  // Action to initiate Password reset flow
  BEGIN_RESET_PASSWORD: 'beginResetPassword ',
  // Action to submit new password in password reset flow
  SUBMIT_PASSWORD: 'submitPassword',
};

// Statuses that we receive from backend
const AUTH_STATUS = {
  // Render email screen in Sign In flow
  EMAIL_REQUIRED: 'EMAIL_REQUIRED',
  // Render email screen in create account flow
  EMAIL_VERIFICATION_REQUIRED: 'EMAIL_VERIFICATION_REQUIRED',
  // Render Password screen in Sign In flow
  PASSWORD_REQUIRED: 'PASSWORD_REQUIRED',
  // Render OTP screen in OTP based flows (MFA, CCPA OTP)
  OTP_REQUIRED: 'OTP_REQUIRED',
  // Render OTP screen in OTP based flows (Email verification, Create account flow email verification)
  EMAIL_OTP_REQUIRED: 'EMAIL_OTP_REQUIRED',
  // Received when the Authentication flow is complete
  RESUME: 'RESUME',
  // MFA flow start
  AUTHENTICATION_REQUIRED: 'AUTHENTICATION_REQUIRED',
  // Render Email OTP screen in MFA flow
  VERIFICATION_REQUIRED: 'VERIFICATION_REQUIRED',
  // Render Terms of Service screen
  TOS_REQUIRED: 'TOS_REQUIRED',
  // Status after successful MFA completion
  MFA_COMPLETED: 'MFA_COMPLETED',
  // Status to render error screen for error in MFA
  MFA_FAILED: 'MFA_FAILED',
  DEVICE_SELECTION_REQUIRED: 'DEVICE_SELECTION_REQUIRED',
  // Render Register User screen in Create account flow
  USER_INFO_REQUIRED: 'USER_INFO_REQUIRED',
  // Render Password reset OTP screen
  RESET_PASSWORD_OTP_REQUIRED: 'RESET_PASSWORD_OTP_REQUIRED',
  // Render Password reset screen
  PASSWORD_UPDATE_REQUIRED: 'PASSWORD_UPDATE_REQUIRED',
};

// Custom statuses to handle UI functionalities, these statuses are not returned by backend
const CUSTOM_AUTH_STATUS = {
  // Too Many Attempts Screen for MFA TOTP and SMS flows
  TOTP_AND_SMS_MAX_ATTEMPTS_LIMIT: 'TOTP_AND_SMS_MAX_ATTEMPTS_LIMIT',
  // Too Many Attempts Screen for MFA Email OTP / Email Verification flows
  EMAIL_OTP_MAX_ATTEMPTS_LIMIT: 'EMAIL_OTP_MAX_ATTEMPTS_LIMIT',
  // Too Many Attempts Screen for Email Resend
  EMAIL_RESEND_MAX_ATTEMPTS_LIMIT: 'EMAIL_RESEND_MAX_ATTEMPTS_LIMIT',
  // Success screen after Email Verification
  EMAIL_VERIFICATION_SUCCESSFUL: 'EMAIL_VERIFICATION_SUCCESSFUL',
  // Loader Skeleton screen
  SHOW_SKELETON: 'SHOW_SKELETON',
  // Page Not Found screen
  PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
  // Internal Server Error Screen
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  // Forbidden Entry Screen
  FORBID_ENTRY: 'FORBID_ENTRY',
  // Session Expired Screen (Flavour of Forbid entry screen with session restart link)
  SESSION_ENDED: 'SESSION_ENDED',
  // Success screen after account creation
  CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
  // Login Disabled screen in Create Account flow
  CREATE_ACCOUNT_LOGIN_DISABLED: 'CREATE_ACCOUNT_LOGIN_DISABLED',
  // Sign In Request Expired screen
  SIGN_IN_REQUEST_EXPIRED: 'SIGN_IN_REQUEST_EXPIRED',
  // IDSDK "Opening Product Loader Screen" after clicked on "Open Product" link
  OPEN_PRODUCT: 'OPEN_PRODUCT',
  // IDSDK "Product Signed In Screen" after successfully signed in
  PRODUCT_SIGNED_IN: 'PRODUCT_SIGNED_IN',
  // IDSDK "Product Sign Out Screen" after signing out from Product (IDSDK)
  PRODUCT_SIGN_OUT: 'PRODUCT_SIGN_OUT',
  // Request error from Server
  REQUEST_ERROR: 'REQUEST_ERROR',
  // Logout screen
  LOGOUT: 'LOGOUT',
  // Default logged out screen
  LOGGED_OUT: 'LOGGED_OUT',
  // SAML assertion error screen
  SAML_ASSERTION_ERROR: 'SAML_ASSERTION_ERROR',
  // Domain not found screen
  DOMAIN_NOT_FOUND: 'DOMAIN_NOT_FOUND',
  // Connection not found screen
  CONNECTION_NOT_FOUND: 'CONNECTION_NOT_FOUND',
  // Domain connection mismatched screen
  DOMAIN_CONNECTION_MISMATCH: 'DOMAIN_CONNECTION_MISMATCH',
  // Domain disabled screen
  DOMAIN_DISABLED: 'DOMAIN_DISABLED',
  // JIT disabled screen when access denied
  JIT_DISABLED: 'JIT_DISABLED',
  // User deactived screen
  USER_DEACTIVATED: 'USER_DEACTIVATED',
  // SSO generic error screen
  UNABLE_TO_SIGN_IN_SSO: 'UNABLE_TO_SIGN_IN_SSO',
  // Password reset success screen
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  // Opt out overlay screen
  OPT_OUT_OVERLAY_SCREEN: 'OPT_OUT_OVERLAY_SCREEN',
};

// Error codes that we receive from backend
const AUTH_ERROR_CODES = {
  // Server Error in case of MFA
  SERVER_ERROR: 'SERVER_ERROR',
  // When all the devices for MFA are exhausted
  NO_USABLE_DEVICES: 'NO_USABLE_DEVICES',
};

// Statuses particular to Terms of Service flow
const TOS_STATUS = {
  ACCEPTED: 'ACCEPTED',
  NOT_ACCEPTED: 'NOT_ACCEPTED',
  UPDATE_REQUIRED: 'UPDATE_REQUIRED',
};

// Query params to be used in backend calls (To be used in case of Silent reinitiation of flow from Session ended screen)
// const QUERY_PARAMS = {
//   RESPONSE_MODE: 'response_mode',
//   PI_FLOW: 'pi.flow',
// };

const FLOW_TYPE = {
  EMAIL_VERIFICATION: 'EMAIL VERIFICATION',
  CREATE_ACCOUNT: 'CREATE ACCOUNT',
  SIGN_IN: 'SIGN IN',
  INVITE: 'INVITE',
  IDSDK: 'IDSDK',
  RESET_PASSWORD: 'RESET PASSWORD',
};

const HISTORY_ACTIONS = {
  PUSH: 'PUSH',
  REPLACE: 'REPLACE',
};

export { AUTH_ACTIONS, AUTH_ERROR_CODES, AUTH_STATUS, CUSTOM_AUTH_STATUS, FLOW_TYPE, HISTORY_ACTIONS, TOS_STATUS };
