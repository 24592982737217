import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';
import { css } from '@emotion/react';

const containerBoxStyles = css({
  backgroundColor: lightTheme.primitives.colors.tint['slate-100'],
});

const innerBoxStyles = css({
  display: 'flex',
  alignItems: 'center',
  marginTop: lightTheme.primitives.spacings[2],
});

const cancelIconStyles = css({
  path: {
    '&:first-of-type': {
      fill: lightTheme.tokens.colors['ink-error'],
    },
  },
});

export { cancelIconStyles, containerBoxStyles, innerBoxStyles };
