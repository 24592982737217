/** @jsxImportSource @emotion/react */
import { Global } from '@emotion/react';
import VanilaHcaptcha from '@hcaptcha/react-hcaptcha';
import { HCAPTCHA_CONFIG, HCAPTCHA_LOG_TEXTS } from 'appConstants/hCaptcha';
import AppContext from 'context/appContext';
import obs from 'logging/observability/observability';
import UILogger from 'logging/UILogger';
import { MutableRefObject, RefObject, forwardRef, useContext, useEffect, useState } from 'react';
import { HCaptchaCustomProps } from './HCaptcha.d';
import { HcaptchaCustomIdpTheme, hCaptchaGlobalStyles } from './HCaptchaStyles';

export const executeHcaptcha = async (captchaRef: RefObject<VanilaHcaptcha>) => {
  /**
   * This function returns a promise which gives the token upon resolveing. Promise resolves once token is recieved.
   * Above promise includes rendering challenge window (based on hCaptcha's judgment), user solving the challenge
   * and getting the hCaptcha token.
   * Challenge window is rendered based on hCaptcha configuration (99.9% passive mode, always challnege, etc) and hCaptcha's judgement.
   */
  try {
    const hcaptchaResp = await captchaRef?.current?.execute({ async: true });
    return hcaptchaResp?.response;
  } catch (error) {
    /**
     * Handles scenarios like incorrect data (eg wrong sitekey),
     * Challenge closed (user clicks outside challenge window), etc.
     */
    return '';
  }
};

const HCaptcha = forwardRef<VanilaHcaptcha, HCaptchaCustomProps>(
  (
    {
      onClose,
      onError,
      onOpen,
      flow,
      onHCaptchaLoad,
      onHCaptchaError,
      showChallengeOnLoad,
      size = 'invisible',
      ...props
    }: HCaptchaCustomProps,
    ref,
  ) => {
    const [useHcaptchaGlobalStyles, setUseHcaptchaGlobalStyles] = useState(false);
    const { uiLocale } = useContext(AppContext);

    const onLoad = () => {
      /**
       * To show hcaptcha challenge on page load pass showChallengeOnLoad as true
       * Token will be shared via onVerify callback
       * Eg
       * <CustomHCaptcha
       *  ref={captchaRef}
       *  flow={HCAPTCHA_FLOWS.SIGN_IN as KeysOfHcaptchaFlows}
       *  onVerify={(token, ekey) => {}}
       *  showChallengeOnLoad={true}
       * />
       */
      onHCaptchaLoad();
      if (showChallengeOnLoad && ref) {
        (ref as MutableRefObject<VanilaHcaptcha>)?.current?.execute();
      }
    };

    const defaultOnOpen = () => {
      /**
       * Called when user is presented with challenge.
       * We can update to this to implement tracking in Analytics based
       * on requirement.
       */
      UILogger.infoLogMethod({
        message: HCAPTCHA_LOG_TEXTS.OPENED,
        componentStack: `Hcaptcha ${flow}`,
      });

      setUseHcaptchaGlobalStyles(true);

      if (onOpen) {
        onOpen();
      }
    };

    const defaultOnClose = () => {
      /**
       * Called when user closes dialog, eg clicks outisde challenge dialog.
       * We can update to this to implement tracking in Analytics based
       * on requirement.
       */
      UILogger.infoLogMethod({
        message: HCAPTCHA_LOG_TEXTS.CLOSED,
        componentStack: `Hcaptcha ${flow}`,
      });

      setUseHcaptchaGlobalStyles(false);

      if (onClose) {
        onClose();
      }
    };

    const defaultOnError = (error: string) => {
      /**
       * This section can be updated once based on requirement.
       * Eg We can store it in our Monitoring tool, etc.
       */
      onHCaptchaError();
      UILogger.errorLogMethod({
        message: 'HCaptcha Error',
        error: error,
        componentStack: `Hcaptcha ${flow}`,
      });
      try {
        obs.logError({ name: 'HCaptcha Error', message: error.toString() }, { flow: flow });
      } catch (error) {
        // no op
      }

      if (onError) {
        onError(error);
      }
    };

    const onChalExpired = () => {
      /**
       * user display of a challenge times out with no answer
       */
      (ref as MutableRefObject<VanilaHcaptcha>)?.current?.resetCaptcha();
    };

    const sitekey = HCAPTCHA_CONFIG[flow]?.siteKey;

    /**
     * This useEffect hook sets up a 'click' event listener on the document object when the component mounts.
     * This makes sure that HCaptcha model dialog is not closed when the user clicks outside the dialog.
     * Event capturing is used to make sure, this event executes before hCaptcha's event listener
     * `captchaShim` is a DOM element that represents the shim (overlay) for the HCaptcha popup.
     *
     * The cleanup function removes the event listener when the component unmounts.
     */
    useEffect(() => {
      const handleClickOutside = (e: MouseEvent) => {
        const captchaShim = document.getElementsByTagName('iframe')[2]?.parentElement?.nextSibling;
        if (e.target === captchaShim) {
          e.stopPropagation();
        }
      };
      // Adding the event listener
      document.addEventListener('click', handleClickOutside, true);
      // Cleanup function to remove the event listener
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, []);

    return (
      <>
        {sitekey && (
          <>
            {useHcaptchaGlobalStyles && <Global styles={hCaptchaGlobalStyles} />}
            <VanilaHcaptcha
              {...props}
              sitekey={sitekey}
              ref={ref}
              size={size}
              theme={HcaptchaCustomIdpTheme}
              custom={true}
              languageOverride={uiLocale}
              onClose={defaultOnClose}
              onOpen={defaultOnOpen}
              onError={defaultOnError}
              onChalExpired={onChalExpired}
              onLoad={onLoad}
            />
          </>
        )}
      </>
    );
  },
);

export default HCaptcha;
