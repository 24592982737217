import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';
import { css } from '@emotion/react';
import MailVerifiedLogo from 'static/images/mailverify.png';

const lgMaxWidth = `@media (max-width: ${lightTheme.tokens.grid.lg.breakpoint})`;
const linkStyles = css({
  '&&': { textDecorationColor: 'inherit', color: 'inherit' },
  '&&:hover': {
    color: 'inherit',
    textDecorationColor: 'inherit',
  },
});

const headlineStyles = css`
  ${lgMaxWidth} {
    font-size: 1.32em;
  }
`;

const textStyles = css`
  ${lgMaxWidth} {
    font-size: 1em;
  }
`;

const verifiedEmailLogoStyles = css`
  background: url(${MailVerifiedLogo}) no-repeat;
  height: 58px;
`;

const disabledLinkStyles = css({
  pointerEvents: 'none',
  opacity: `${lightTheme.tokens.opacities.disabled}`,
});

const commonLinkStyles = css({
  '&& ,&&:hover ,&&:visited ,&&:visited:hover': { textDecorationColor: 'inherit', color: 'inherit' },
});

const errorIconStyles = css({
  marginBottom: lightTheme.primitives.spacings[6],
});

export {
  commonLinkStyles,
  disabledLinkStyles,
  errorIconStyles,
  headlineStyles,
  linkStyles,
  textStyles,
  verifiedEmailLogoStyles,
};
