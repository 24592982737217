/** @jsxImportSource @emotion/react */
import Button from '@digital-hig/button';
import { SerializedStyles } from '@emotion/utils/types';
import { EVENT_TEXT } from 'appConstants';
import { trackClick } from 'utilities/tealiumAnalytics';
import { buttonLoaderStyles } from './LoadingButtonStyles';

interface LoadingButtonProps {
  isDisabled?: boolean;
  text: string;
  buttonType?: string;
  id?: string;
  showLoader?: boolean;
  buttonLocation?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | React.FormEventHandler<HTMLFormElement>;
  fullWidth?: boolean;
  variant?: string;
  cssStyles?: SerializedStyles;
}

const LoadingButton = ({
  text,
  isDisabled = false,
  showLoader = false,
  buttonType = 'submit',
  id = '',
  buttonLocation = '',
  onClick,
  fullWidth = true,
  variant = 'contained',
  cssStyles,
}: LoadingButtonProps) => {
  const onButtonClick: React.MouseEventHandler<HTMLButtonElement> & React.FormEventHandler<HTMLFormElement> = (
    event: React.MouseEvent<HTMLButtonElement> & React.FormEvent<HTMLFormElement>,
  ) => {
    trackClick({
      link_location: buttonLocation,
      link_text: EVENT_TEXT.SUBMIT_BUTTON,
    });
    onClick && onClick(event);
  };

  const btnProps = {
    layout: 'hug',
    disabled: isDisabled,
    fullWidth: fullWidth,
    onClick: onButtonClick,
    type: buttonType,
    variant: variant,
    css: cssStyles,
    startIcon: showLoader ? (
      <div data-testid="btn-spinner" className="circular-loader" css={buttonLoaderStyles} />
    ) : (
      <></>
    ),
  };
  return <Button {...(id ? { ...btnProps, id } : btnProps)}>{text}</Button>;
};

export default LoadingButton;
