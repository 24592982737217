/** @jsxImportSource @emotion/react */
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { dividerStyles } from './DividerStyles';

type DividerProps = {
  sx?: SxProps;
};

const Divider = (props: DividerProps) => {
  return <Box data-testid="divider" css={dividerStyles} {...props} />;
};

export default Divider;
