const TERMS_OF_USE = 'https://www.autodesk.com/termsofservice';
const PRIVACY_STATEMENT = 'https://www.autodesk.com/privacy';
const MANAGE_STATEMENT = 'https://www.autodesk.com/mkto-unsubscribe';
const AUTODESK_HOMEPAGE = 'https://www.autodesk.com';
const TERMS_OF_USE_zh_CN = 'https://www.autodesk.com/company/terms-of-use/cn/general-terms';
const PRIVACY_STATEMENT_zh_CN = 'https://www.autodesk.com/company/legal-notices-trademarks/privacy-statement-zh-hans';
const PWD_POLICY_URL =
  'https://www.autodesk.com/support/technical/article/caas/tsarticles/ts/2GViMvP9DpEqf1apISdQMn.html';
const SLACK_IDENTITY_INTEGRATION = 'https://autodesk.enterprise.slack.com/archives/C020BUZNR7W';
const LEARN_MORE =
  'https://help.autodesk.com/view/SSOGUIDE/ENU/?guid=SSOGUIDE_Okta_Guide_About_Single_Sign_on_SSO_Test_and_turn_on_SSO_html';
const CONTACT_SUPPORT = 'https://www.autodesk.com/support/contact-support';
const TROUBLESHOOTING_GUIDE =
  'https://help.autodesk.com/view/SSOGUIDE/ENU/?guid=SSOGUIDE_Okta_Guide_About_Single_Sign_on_SSO_Troubleshooting_Error_Oops_html';
const OPT_OUT_FEEDBACK_FORM_LINKS: {
  [key: string]: string;
} = {
  dev: 'https://autodeskfeedback.az1.qualtrics.com/jfe/form/SV_9ZAntpioNjpPqWq',
  stg: 'https://autodeskfeedback.az1.qualtrics.com/jfe/form/SV_9ZAntpioNjpPqWq',
  prod: 'https://autodeskfeedback.az1.qualtrics.com/jfe/preview/previewId/c211ac11-851a-482d-a315-5c4cd3963cc4/SV_85I24jU5CMzuJee?Q_CHL=preview&Q_SurveyVersionID=current',
};
export {
  AUTODESK_HOMEPAGE,
  CONTACT_SUPPORT,
  LEARN_MORE,
  MANAGE_STATEMENT,
  OPT_OUT_FEEDBACK_FORM_LINKS,
  PRIVACY_STATEMENT,
  PRIVACY_STATEMENT_zh_CN,
  PWD_POLICY_URL,
  SLACK_IDENTITY_INTEGRATION,
  TERMS_OF_USE,
  TERMS_OF_USE_zh_CN,
  TROUBLESHOOTING_GUIDE,
};
