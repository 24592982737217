/** @jsxImportSource @emotion/react */
import Link from '@mui/material/Link';
import { EVENT_TEXT } from 'appConstants/analytics';
import React from 'react';
import { CustomLinkProps } from 'types';
import { trackClick } from 'utilities/tealiumAnalytics';

const CustomLink: React.FC<CustomLinkProps> = ({
  href = '#',
  tracking = { linkText: '', linkLocation: '' },
  onClick,
  ariaLabel,
  id,
  children,
  cssStyles,
  target = '_blank',
  outbound = false,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (tracking.linkLocation && tracking.linkText) {
      trackClick({
        link_text: `${tracking.linkText} ${EVENT_TEXT.LINK}`,
        link_location: tracking.linkLocation,
        link_url: tracking.linkUrl ?? href,
        outbound: outbound,
      });
    }

    if (onClick && href === '#') {
      event.preventDefault();
      onClick(event);
    }
  };

  return (
    <Link
      id={id}
      href={href}
      onClick={handleClick}
      css={cssStyles}
      target={target}
      {...(ariaLabel && {
        'aria-label': ariaLabel,
      })}
    >
      {children}
    </Link>
  );
};

export default CustomLink;
