import { URL_DOMAIN_ENV } from './common';

const PRODUCT_SIGN_IN_EXPIRED_TIMEOUT_MS = 300000;
const PRODUCT_SIGN_IN_LOADER_TIMEOUT_MS = 5000;

const IDSDK_FRAGMENT_PARAMS = {
  ID_TOKEN: 'id_token',
  STATE: 'state',
  CODE: 'code',
};

const IDSDK_ALTERNATE_PROTOCOL_INDICATOR = 'use_alternative_product_path';

const IDSDK_PROTOCOL = {
  OLD: 'adsk.idmgr:/login',
  NEW: 'adskidmgr:/login',
};

const PROFILE_IMAGE_BUCKET = `https://images.profile${URL_DOMAIN_ENV}.autodesk.com`;
const PROFILE_IMAGE_PATH = '/profilepictures/x80.jpg?r=';

const IDSDK_SIGNOUT_SESSION_CHECK_URL = `oauth2/v1/authorize?client_id=${window.idp?.env?.constants?.IDSDK_LOGOUT_CLIENT_ID}&response_type=none&prompt=none`;

const IDSDK_AUTH_CODE_PREFIX = 'native.';
export {
  IDSDK_ALTERNATE_PROTOCOL_INDICATOR,
  IDSDK_AUTH_CODE_PREFIX,
  IDSDK_FRAGMENT_PARAMS,
  IDSDK_PROTOCOL,
  IDSDK_SIGNOUT_SESSION_CHECK_URL,
  PRODUCT_SIGN_IN_EXPIRED_TIMEOUT_MS,
  PRODUCT_SIGN_IN_LOADER_TIMEOUT_MS,
  PROFILE_IMAGE_BUCKET,
  PROFILE_IMAGE_PATH,
};
