import { BASE_URL, ENV } from './common';

const IDP_FEEDBACK_FORM_DIMENSIONS = {
  WIDTH: 770,
  HEIGHT: 700,
  LEFT: window.screen.width / 2 - 770 / 2,
  TOP: window.screen.height / 2 - 700 / 2,
};

const IDP_OPT_OUT = `${BASE_URL[ENV]}/accounts/v1/idp-opt-out`;

const POPUP_CHECK_INTERVAL_IN_MS = 500;

const IDP_FEEDBACK_FORM_LANGUAGE_MAPPING: { [key: string]: string } = {
  en: 'EN',
  cs: 'CS',
  de: 'DE',
  da: 'DA',
  es: 'ES-ES',
  fi: 'FI',
  fr: 'FR',
  fr_CA: 'FR-CA',
  hu: 'HU',
  it: 'IT',
  ja: 'JA',
  ko: 'KO',
  nb: 'NO',
  nl: 'NL',
  pl: 'PL',
  pt_BR: 'PT-BR',
  pt_PT: 'PT',
  sv: 'SV',
  tr: 'TR',
  zh_CN: 'ZH-S',
  zh_TW: 'ZH-T',
  ZH_HK: 'ZH-T',
};

export { IDP_FEEDBACK_FORM_DIMENSIONS, IDP_FEEDBACK_FORM_LANGUAGE_MAPPING, IDP_OPT_OUT, POPUP_CHECK_INTERVAL_IN_MS };
