import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';
import { css } from '@emotion/react';

const cardHeadingLargeStyles = css({
  marginBottom: lightTheme.primitives.spacings[6],
});

const cardHeadingMediumStyles = css({
  marginBottom: lightTheme.primitives.spacings[4],
});

const cardHeadingSmallStyles = css({
  marginBottom: lightTheme.primitives.spacings[3],
});

const subHeadingSmallStyles = css({
  marginTop: lightTheme.primitives.spacings[1],
});

const subHeadingMediumStyles = css({
  marginTop: lightTheme.primitives.spacings[3],
});

const subHeadingLargeStyles = css({
  marginTop: lightTheme.primitives.spacings[6],
});

const footerSmallStyles = css({
  marginTop: lightTheme.primitives.spacings[3],
});

const footerMediumStyles = css({
  marginTop: lightTheme.primitives.spacings[6],
});

const footerLargeStyles = css({
  marginTop: lightTheme.primitives.spacings[7],
});

export {
  cardHeadingLargeStyles,
  cardHeadingMediumStyles,
  cardHeadingSmallStyles,
  footerLargeStyles,
  footerMediumStyles,
  footerSmallStyles,
  subHeadingLargeStyles,
  subHeadingMediumStyles,
  subHeadingSmallStyles,
};
