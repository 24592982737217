/** @jsxImportSource @emotion/react */
import Typography from '@digital-hig/typography';
import React from 'react';
import { CardSkeletonElementType } from 'types';
import {
  cardHeadingLargeStyles,
  cardHeadingMediumStyles,
  cardHeadingSmallStyles,
  footerLargeStyles,
  footerMediumStyles,
  footerSmallStyles,
  subHeadingLargeStyles,
  subHeadingMediumStyles,
  subHeadingSmallStyles,
} from './CardSkeletonStyles';

interface CardSkeletonProps {
  heading?: string | React.ReactNode;
  headingComponentType?: React.ElementType;
  subHeading?: React.ReactNode;
  subHeadingType?: CardSkeletonElementType;
  body: React.ReactNode;
  footer?: React.ReactNode;
  footerType?: CardSkeletonElementType;
  headingType?: CardSkeletonElementType;
  headingAssistiveText?: string | React.ReactNode;
}

const footerStyles = {
  small: footerSmallStyles,
  medium: footerMediumStyles,
  large: footerLargeStyles,
};

const subHeadingStyles = {
  small: subHeadingSmallStyles,
  medium: subHeadingMediumStyles,
  large: subHeadingLargeStyles,
};

const cardHeadingStyles = {
  small: cardHeadingSmallStyles,
  medium: cardHeadingMediumStyles,
  large: cardHeadingLargeStyles,
};

const CardSkeleton: React.FC<CardSkeletonProps> = ({
  headingType = 'large',
  heading,
  subHeadingType = 'small',
  subHeading = null,
  body,
  footer = null,
  footerType = 'small',
  headingComponentType = 'h1',
  headingAssistiveText,
}) => {
  return (
    <>
      <div css={cardHeadingStyles[headingType]}>
        {heading && (
          <Typography variant="headline-medium" component={headingComponentType}>
            {heading}
            {headingAssistiveText && <span className="sr-only">{headingAssistiveText}</span>}
          </Typography>
        )}
        {subHeading && <div css={heading && subHeadingStyles[subHeadingType]}>{subHeading}</div>}
      </div>
      {body}
      {footer && <div css={footerStyles[footerType]}>{footer}</div>}
    </>
  );
};

export default CardSkeleton;
