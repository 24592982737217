import { css, keyframes } from '@emotion/react';

const buttonLoaderStyles = css({
  // borderTopColor: dhigLightTheme.resolvedRoles['tokens.colors.row-selected-pressed.rgb'],
  // Update this to use the variable from DHIG once updated. Currently this is not available in the theme.
  borderTopColor: 'rgb(0, 0, 0, 0.8)',
  height: 16,
  width: 16,
  borderRadius: '50%',
  position: 'relative',
  borderWidth: 2,
  borderStyle: 'Solid',
  animation: `1.1s linear infinite none ${keyframes({
    '0%': {
      transform: 'rotate(0)',
    },
    '100%': { transform: 'rotate(360deg)' },
  })}`,
});
export { buttonLoaderStyles };
