import { createContext } from 'react';

type ClientDetails = {
  clientID: string;
  status: string;
  applicationName: string;
};
type AppContextValue = {
  globalError: boolean;
  uiLocale: string;
  fetchingFlowStatus?: boolean;
  clientDetails: ClientDetails;
  setFetchingFlowStatus: (value: boolean) => void;
  setUiLocale: (locale: string) => void;
  setGlobalError: (value: boolean) => void;
  setShowOptOutBanner: (value: boolean) => void;
  setClientDetails: (value: ClientDetails) => void;
};

export const appContextInitialValue = {
  globalError: false,
  uiLocale: '',
} as AppContextValue;

const AppContext = createContext(appContextInitialValue);

export default AppContext;
