import I18nConfigs from 'configs/I18nConfigs';
import { createInstance } from 'i18next';
import { ReactNode } from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { useTranslationsByAppLanguage } from './i18nHelper';

interface Props {
  children?: ReactNode;
  locale: string;
  // any props that come into the component
}

const i18nInstance = createInstance();
i18nInstance
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(I18nConfigs);

const I18nProvider = ({ children, locale }: Props) => {
  useTranslationsByAppLanguage(i18nInstance, locale, I18nConfigs.ns);
  return <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>;
};

export default I18nProvider;
