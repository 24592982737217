/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/utils/types';

const ErrorImage = ({ errorStyles }: { errorStyles: SerializedStyles }) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="global-error-screen-error-icon"
      css={errorStyles}
    >
      <g id="pictogram">
        <g id="fill">
          <path
            d="M11.5 26.5637C12.3284 26.5637 13 25.8921 13 25.0637C13 24.2353 12.3284 23.5637 11.5 23.5637C10.6716 23.5637 10 24.2353 10 25.0637C10 25.8921 10.6716 26.5637 11.5 26.5637Z"
            fill="black"
          />
          <path
            d="M16.5 26.5637C17.3284 26.5637 18 25.8921 18 25.0637C18 24.2353 17.3284 23.5637 16.5 23.5637C15.6716 23.5637 15 24.2353 15 25.0637C15 25.8921 15.6716 26.5637 16.5 26.5637Z"
            fill="black"
          />
          <path
            d="M23 25.0637C23 25.8921 22.3284 26.5637 21.5 26.5637C20.6716 26.5637 20 25.8921 20 25.0637C20 24.2353 20.6716 23.5637 21.5 23.5637C22.3284 23.5637 23 24.2353 23 25.0637Z"
            fill="black"
          />
          <path
            d="M51.5 47.9804C51.5 47.152 50.8284 46.4804 50 46.4804C49.1716 46.4804 48.5 47.152 48.5 47.9804V56.3137C48.5 57.1421 49.1716 57.8137 50 57.8137C50.8284 57.8137 51.5 57.1421 51.5 56.3137V47.9804Z"
            fill="black"
          />
          <path
            d="M51.5 62.147C51.5 61.3186 50.8284 60.647 50 60.647C49.1716 60.647 48.5 61.3186 48.5 62.147V62.3322C48.5 63.1606 49.1716 63.8322 50 63.8322C50.8284 63.8322 51.5 63.1606 51.5 62.3322V62.147Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.2929 35.5532C51.0234 35.095 50.5315 34.8137 50 34.8137C49.4684 34.8137 48.9766 35.095 48.7071 35.5532L30.3738 66.7199C30.1009 67.1836 30.0974 67.758 30.3646 68.2251C30.6317 68.6922 31.1286 68.9804 31.6667 68.9804H68.3333C68.8714 68.9804 69.3683 68.6922 69.6354 68.2251C69.9025 67.758 69.899 67.1836 69.6262 66.7199L51.2929 35.5532ZM50 39.2722L65.7107 65.9804H34.2893L50 39.2722Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 18.8137C6.067 18.8137 4.5 20.3807 4.5 22.3137V78.3137C4.5 80.2467 6.067 81.8137 8 81.8137H92C93.933 81.8137 95.5 80.2467 95.5 78.3137V22.3137C95.5 20.3807 93.933 18.8137 92 18.8137H8ZM7.5 22.3137C7.5 22.0376 7.72386 21.8137 8 21.8137H92C92.2761 21.8137 92.5 22.0376 92.5 22.3137V28.3137L7.5 28.3137V22.3137ZM7.5 29.3137L92.5 29.3137V78.3137C92.5 78.5899 92.2761 78.8137 92 78.8137H8C7.72386 78.8137 7.5 78.5899 7.5 78.3137V29.3137Z"
            fill="black"
          />
        </g>
      </g>
    </svg>
  );
};
export default ErrorImage;
