/** @jsxImportSource @emotion/react */
import SvgCancel from '@digital-hig/icon/lib/esm/display-icons/cancel';
import SvgComplete from '@digital-hig/icon/lib/esm/display-icons/complete';
import SvgCircle from '@digital-hig/icon/lib/esm/icons/circle';
import Typography from '@digital-hig/typography';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import authNamespaces from 'nameSpaces/authNameSpaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PasswordHintProps } from 'types/PasswordHint';
import { cancelIconStyles, containerBoxStyles, innerBoxStyles } from './PasswordHintStyles';

const PasswordHint: React.FC<PasswordHintProps> = ({
  id,
  passwordHints,
  showPasswordHints,
  showPasswordValidations,
}) => {
  const { t } = useTranslation([authNamespaces.password]);

  const getValidationStatusIconToRender = (
    showPasswordValidations: boolean,
    isValid: boolean,
    key: string,
  ): JSX.Element => {
    if (!showPasswordValidations) {
      return (
        <SvgIcon data-testid="icon-circle">
          <SvgCircle />
        </SvgIcon>
      );
    }
    if (isValid) {
      return (
        <SvgIcon aria-hidden={false} aria-label={t('COMPLETE')} data-testid={`${key}-complete`}>
          <SvgComplete />
        </SvgIcon>
      );
    } else {
      return (
        <SvgIcon aria-hidden={false} aria-label={t('PENDING')} data-testid={`${key}-pending`}>
          <SvgCancel css={cancelIconStyles} />
        </SvgIcon>
      );
    }
  };

  const getPasswordHintsToShow = () => {
    return passwordHints.filter((hint) => hint.showHint);
  };

  return (
    <Box aria-hidden={!showPasswordHints} sx={{ px: 2, py: 3 }} css={containerBoxStyles} id={id} data-testid={id}>
      <Typography variant="body-copy-small" color="ink-on-background-60">
        {t('PASSWORD_MUST_INCLUDE')}
      </Typography>
      <Box aria-atomic={true} aria-live="polite">
        {getPasswordHintsToShow().map(({ key, text, numberOfCharacters, isValid }) => (
          <Box css={innerBoxStyles} key={key}>
            {getValidationStatusIconToRender(showPasswordValidations, isValid, key)}
            <Typography sx={{ ml: 2 }} variant="body-copy-small">
              {t(text, { numberOfCharacters })}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PasswordHint;
