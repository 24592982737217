import {
  HCAPTCHA_CONFIG,
  HCAPTCHA_ERRORS,
  HCAPTCHA_FAILURE_RETRY_LIMIT,
  HCAPTCHA_REQUIRED_HEADER,
  HCAPTCHA_SITE_TYPE,
} from 'appConstants/hCaptcha';
import { KeysOfHcaptchaFlows } from 'common/components/HCaptcha/HCaptcha.d';
import { ApiError, AuthCallbackParams, AuthCallbackType, CallbackResponse } from 'types';

export const isHcaptchaRequired = (response: Response) => {
  const hCaptchaRequired = !!(
    response.headers.get(HCAPTCHA_REQUIRED_HEADER) ?? response.headers.get(HCAPTCHA_REQUIRED_HEADER.toLowerCase())
  );
  return hCaptchaRequired;
};

export const isHcaptchaInvalidGlobalError = (error: ApiError, retriesLeft?: number) => {
  // In case of hCaptcha we are getting error in 'error' and not in 'error.code' & 'error.description'.
  // retriesLeft === 0 implies this is the last retry and we need to show the forbid entry screen
  // for first call to server, retriesLeft will be undefined, in that case we will not show forbid entry screen
  return (
    (error?.error === HCAPTCHA_ERRORS.HCAPTCHA_REQUIRED || error?.error === HCAPTCHA_ERRORS.INVALID_HCAPTCHA) &&
    retriesLeft === 0
  );
};

export const isPassiveImplicitHcaptcha = (hCaptchaFlowType: string | undefined): boolean =>
  !!hCaptchaFlowType &&
  HCAPTCHA_CONFIG[hCaptchaFlowType as KeysOfHcaptchaFlows]?.hcaptchaType === HCAPTCHA_SITE_TYPE.PASSIVE_IMPLICIT;

export const isPassiveExplicitHcaptcha = (
  hCaptchaFlowType: string | undefined,
  hcaptchaRequiredStatus: boolean,
): boolean =>
  !!hCaptchaFlowType &&
  hcaptchaRequiredStatus &&
  HCAPTCHA_CONFIG[hCaptchaFlowType as KeysOfHcaptchaFlows]?.hcaptchaType === HCAPTCHA_SITE_TYPE.PASSIVE_EXPLICIT;

export const hCaptchaRetryHandler = async (
  getFlowResponse: CallbackResponse,
  authCallBack: AuthCallbackType,
  authCallbackParams: AuthCallbackParams,
  retriesLeft?: number,
): Promise<CallbackResponse> => {
  const firstFailure = retriesLeft === undefined;
  const isInvalidHcaptchaError = getFlowResponse.error?.error === HCAPTCHA_ERRORS.INVALID_HCAPTCHA;
  const isHcaptchaRequiredError = getFlowResponse.error?.error === HCAPTCHA_ERRORS.HCAPTCHA_REQUIRED;

  // If Hcaptcha token is empty, then retry the call with extra retries. This is becuase
  // token will be sent empty only if user is already enrolled in Hcpatcha flow on server side (based on past activity). -
  // isHcaptchaRequiredError with retriesLeft === undefined implies this was the first request
  // to server and SPA did not know the token was required to be sent. Hence we will allow an extra retry in this case.
  const extraRetryForEmptyHcaptchaToken = isHcaptchaRequiredError && firstFailure ? 1 : 0;
  const updatedRetriesLeft = firstFailure
    ? HCAPTCHA_FAILURE_RETRY_LIMIT + extraRetryForEmptyHcaptchaToken
    : retriesLeft;

  if (!getFlowResponse.isSuccess && (isInvalidHcaptchaError || isHcaptchaRequiredError) && updatedRetriesLeft > 0) {
    authCallbackParams[authCallbackParams.length - 1] = updatedRetriesLeft - 1;
    return authCallBack(...authCallbackParams);
  }
  return getFlowResponse;
};
