/** @jsxImportSource @emotion/react */
import { autodeskLogoSvgStyles } from './AutodeskLogoStyles';
import darkTheme from '@digital-hig/theme-data/lib/build/json/dark/design-tokens.json';
interface AutodeskLogoProps {
  color?: string;
}

const AutodeskLogo: React.FC<AutodeskLogoProps> = ({ color = darkTheme.tokens.colors['background'] }) => {
  return (
    <svg
      css={autodeskLogoSvgStyles}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      data-testid="autodesk-logo"
      x="0px"
      y="0px"
      viewBox="0 0 90 9.23"
      fill={color}
    >
      <g id="logo">
        <g id="wordmark">
          <polygon points="90,0.24 87.34,0.24 84.14,3.81 84.14,0.24 81.91,0.24 81.92,9.01 84.14,9.01 84.14,5.68 87.17,9.01 90,9.01     85.92,4.68   " />
          <path d="M78.3,3.85l-2.04-0.23c-1.2-0.13-1.49-0.43-1.49-0.83c0-0.45,0.61-0.86,1.81-0.86c1.15,0,1.75,0.43,1.86,1.07h2.19    c-0.11-1.85-1.67-3-3.94-3c-2.7,0-4.06,1.07-4.06,2.97c0,1.55,0.9,2.27,2.76,2.47l2.07,0.22c0.79,0.09,1.15,0.39,1.15,0.79    c0,0.45-0.53,0.86-1.8,0.86c-1.47,0-2.06-0.37-2.16-1.12h-2.15c0.1,2.02,1.41,3.05,4.23,3.05c2.58,0,4.01-1.13,4.01-2.83    C80.77,4.92,79.91,4.04,78.3,3.85z" />
          <polygon points="64.47,9.01 71.53,9.01 71.53,7.12 66.71,7.12 66.71,5.53 70.59,5.53 70.59,3.62 66.71,3.62 66.71,2.14     71.53,2.14 71.53,0.24 64.47,0.24   " />
          <path d="M59.21,0.24h-4.1v8.77h4.1c3.15,0,4.01-1.17,4.01-4.45C63.22,1.48,62.39,0.24,59.21,0.24z M59.12,7.12h-1.78V2.14h1.78    c1.4,0,1.82,0.38,1.82,2.42C60.94,6.43,60.47,7.12,59.12,7.12z" />
          <path d="M49.03,0c-3.19,0-4.85,1.96-4.85,4.61c0,2.65,1.66,4.63,4.85,4.63c3.2,0,4.86-1.97,4.86-4.63C53.89,1.96,52.23,0,49.03,0z     M49.03,7.26c-1.66,0-2.57-1.01-2.57-2.65c0-1.61,0.91-2.63,2.57-2.63c1.67,0,2.57,1.02,2.57,2.63C51.6,6.25,50.7,7.26,49.03,7.26    z" />
          <polygon points="35.94,2.14 38.77,2.14 38.77,9.01 41,9.01 41,2.14 43.82,2.14 43.82,0.24 35.94,0.24   " />
          <path d="M32.68,5.66c0,1.14-0.7,1.6-1.9,1.6c-1.16,0-1.92-0.48-1.92-1.6V0.24h-2.24v5.67c0,2.53,2.26,3.32,4.16,3.32    c1.89,0,4.13-0.79,4.13-3.32V0.24h-2.24V5.66z" />
          <path d="M20.08,0.24l-3.37,8.77h2.31l0.59-1.64h3.76l0.58,1.64h2.39l-3.41-8.77H20.08z M20.28,5.5l1.23-3.44l1.21,3.44H20.28z" />
        </g>
        <path
          id="symbol"
          d="M0.07,9.01l7.86-4.89H12c0.13,0,0.24,0.1,0.24,0.24c0,0.11-0.05,0.16-0.11,0.2L8.27,6.88   C8.02,7.03,7.93,7.33,7.93,7.56l0,1.46h4.9V0.54c0-0.16-0.13-0.3-0.31-0.3H7.84L0,5.11v3.91H0.07z"
        />
      </g>
    </svg>
  );
};
export default AutodeskLogo;
