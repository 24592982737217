/** Map the languages defined in json file to the browser language mapping code */
// Locales https://wiki.autodesk.com/display/EFDE/Universal+Header
const BrowserLanguageCodeMappings: { [key: string]: string } = {
  en: 'en-US',
  de: 'de-DE',
  de_de: 'de-DE',
  cs: 'cs-CZ',
  cs_cz: 'cs-CZ',
  da: 'da-DK',
  da_dk: 'da-DK',
  es: 'es-ES',
  es_es: 'es-ES',
  fi: 'fi-FI',
  fi_fi: 'fi-FI',
  fr: 'fr-FR',
  fr_fr: 'fr-FR',
  fr_ca: 'fr-CA',
  hu: 'hu-HU',
  hu_hu: 'hu-HU',
  it: 'it-IT',
  it_it: 'it-IT',
  ja: 'ja-JP',
  ja_jp: 'ja-JP',
  ko: 'ko-KR',
  ko_kr: 'ko-KR',
  nb: 'no-NO',
  no_no: 'no-NO',
  nl: 'nl-NL',
  nl_nl: 'nl-NL',
  pl: 'pl-PL',
  pl_pl: 'pl-PL',
  pt: 'pt-PT',
  pt_br: 'pt-BR',
  pt_pt: 'pt-PT',
  ru: 'en-US',
  ru_ru: 'en-US',
  sv: 'sv-SE',
  sv_se: 'sv-SE',
  tr: 'tr-TR',
  tr_tr: 'tr-TR',
  zh: 'zh-CN',
  zh_hk: 'zh-HK',
  zh_cn: 'zh-CN',
  zh_tw: 'zh-TW',
  zh_mo: 'zh-HK',
  zh_hant: 'zh-TW',
  zh_hans: 'zh-CN',
};

export default BrowserLanguageCodeMappings;
