/** @jsxImportSource @emotion/react */
import CircularProgress from '@digital-hig/circular-progress';
import {
  CUSTOM_AUTH_STATUS,
  ENDPOINTS,
  IDSDK_SIGNOUT_SESSION_CHECK_URL,
  LOGIN_REQUIRED,
  NAVIGATION_DIRECTIONS,
  OAUTH_PARAMS,
  ROUTES,
  URL_PARAMS,
} from 'appConstants';
import CardSkeleton from 'common/components/CardSkeleton/CardSkeleton';
import LoadingSkeleton from 'common/components/LoadingSkeleton/LoadingSkeleton';
import AppContext from 'context/appContext';
import AuthContext from 'context/authContext';
import authNamespaces from 'nameSpaces/authNameSpaces';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLogoutURL, getOktaSessionOptions, getOptions } from 'utilities/apiUtils';

const LogoutScreen = () => {
  const url = new URL(window.location.href);
  const [isIdsdkLogout, setIsIdsdkLogout] = useState(
    url.searchParams.get(URL_PARAMS.IDSDK_DESKTOP_LOGOUT)?.toLowerCase() === 'true',
  );
  const { t } = useTranslation(authNamespaces.logout);
  const { setGlobalError, uiLocale } = useContext(AppContext);
  const { updateStatus } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      if (isIdsdkLogout) {
        checkUserSessionInBrowser();
      } else {
        await logoutOktaSession();
        // If post_logout_redirect_uri is not provided, pass the default logged out page
        let postLogoutUri = encodeURIComponent(
          `${window.location.origin}${ROUTES.LOGGED_OUT}?${OAUTH_PARAMS.UI_LOCALES}=${uiLocale}`,
        );
        const postLogoutRedirectUriFromParams = url.searchParams.get(URL_PARAMS.POST_LOGOUT_REDIRECT_URI);
        if (postLogoutRedirectUriFromParams) {
          try {
            postLogoutUri = encodeURIComponent(postLogoutRedirectUriFromParams);
          } catch {
            // no op, fallback to default logged out page
          }
        }
        const logoutUrl = getLogoutURL(postLogoutUri);
        window.location.href = logoutUrl;
      }
    })();
  }, [isIdsdkLogout]);

  const logoutOktaSession = async () => {
    try {
      const customOktaFetch = fetch(ENDPOINTS.CUSTOM_OKTA_SESSION_URL, getOktaSessionOptions);
      const defaultOktaFetch = fetch(ENDPOINTS.DEFAULT_OKTA_SESSION_URL, getOktaSessionOptions);
      await Promise.all([customOktaFetch, defaultOktaFetch]);
    } catch {
      // ignore error
    }
  };

  const handleError = () => {
    updateStatus({
      status: CUSTOM_AUTH_STATUS.INTERNAL_SERVER_ERROR,
      dir: NAVIGATION_DIRECTIONS.FORWARD,
    });
    setGlobalError(true);
  };

  const checkUserSessionInBrowser = async () => {
    const { BASE_URL } = ENDPOINTS;
    const url = `${BASE_URL}/${IDSDK_SIGNOUT_SESSION_CHECK_URL}`;

    try {
      const response: Response = await fetch(url, getOptions(null, 'GET', false));
      switch (response.status) {
        case 204:
          // status 204 means user has signed out of desktop but has active session in browser
          updateStatus({ status: CUSTOM_AUTH_STATUS.PRODUCT_SIGN_OUT, dir: NAVIGATION_DIRECTIONS.FORWARD });
          break;
        case 400: {
          const responseBody = await response.json(); // Parse the response body as JSON
          if (responseBody.error === LOGIN_REQUIRED) {
            // Handle the specific case when the error is 'login_required'
            setIsIdsdkLogout(false);
          } else {
            // For all other cases when the status is 400 and error is not 'login_required'.
            updateStatus({ status: CUSTOM_AUTH_STATUS.REQUEST_ERROR, dir: NAVIGATION_DIRECTIONS.FORWARD });
            setGlobalError(true);
          }
          break;
        }
        default:
          if (response.status > 400 && response.status < 500) {
            // for all other 4XX status codes apart from 400 show request error screen
            updateStatus({ status: CUSTOM_AUTH_STATUS.REQUEST_ERROR, dir: NAVIGATION_DIRECTIONS.FORWARD });
            setGlobalError(true);
          } else {
            // for all the other status codes we will show internal server error
            handleError();
          }
          break;
      }
    } catch (error) {
      handleError();
    }
  };

  return !isIdsdkLogout ? (
    <CardSkeleton heading={t('SIGNING_OUT')} body={<CircularProgress role="progressbar" size="medium" />} />
  ) : (
    <LoadingSkeleton />
  );
};

export default LogoutScreen;
