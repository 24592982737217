import { validateUniqueCharacters } from 'utilities/validation';
import { MAX_PASSWORD_ALLOWED_LENGTH } from './createAccount';

const PASSWORD_HINTS = [
  {
    key: 'min-letter',
    text: 'PASSWORD_MINIMUM_LETTER',
    validator: (value: string) => /[a-zA-Z]/.test(value),
    isValid: true,
    numberOfCharacters: 1,
    showHint: true,
  },
  {
    key: 'min-number',
    text: 'PASSWORD_MINIMUM_NUMBER',
    validator: (value: string) => /\d/.test(value),
    isValid: true,
    numberOfCharacters: 1,
    showHint: true,
  },
  {
    key: 'min-characters',
    text: 'PASSWORD_MINIMUM_CHARACTERS',
    validator: (value: string) => /(.){8,}/.test(value),
    isValid: true,
    numberOfCharacters: 8,
    showHint: true,
  },
  {
    key: 'min-unique-characters',
    text: 'PASSWORD_MINIMUM_UNIQUE_CHARACTERS',
    validator: (value: string) => validateUniqueCharacters(value, 3),
    isValid: true,
    numberOfCharacters: 3,
    showHint: true,
  },
  {
    key: 'contains-white-space',
    text: 'PASSWORD_DISALLOWED_BY_POLICY',
    validator: (value: string) => !/\s/.test(value),
    isValid: true,
    numberOfCharacters: 3,
    showHint: false,
  },
  {
    key: 'contains-max-chars',
    text: 'PASSWORD_DISALLOWED_BY_POLICY',
    validator: (value: string) => value.length <= MAX_PASSWORD_ALLOWED_LENGTH,
    isValid: true,
    numberOfCharacters: MAX_PASSWORD_ALLOWED_LENGTH,
    showHint: false,
  },
];

export { PASSWORD_HINTS };
