import authNamespaces from 'nameSpaces/authNameSpaces';
import { LinkObj, TosFields } from 'types';
import { EVENT_TEXT } from './analytics';
import { MANAGE_STATEMENT, PRIVACY_STATEMENT, TERMS_OF_USE } from './links';

const termsOfUseLabel = 'TERMS_OF_USE_LABEL';
const privacyStatementLabel = 'PRIVACY_STATEMENT_LABEL';
const manageLabel = 'MANAGE_LABEL';

const TERMS_OF_SERVICE_TYPE = {
  MARKETING: 'marketingTerms',
  GENERAL: 'generalTerms',
  LOCAL: 'localTerms',
};

const links: LinkObj[] = [
  { link: TERMS_OF_USE, key: 'termsOfUse', label: termsOfUseLabel, eventText: EVENT_TEXT.GENERAL_TERMS_OF_USE_LINK },
  {
    link: PRIVACY_STATEMENT,
    key: 'privacyStatement',
    label: privacyStatementLabel,
    eventText: EVENT_TEXT.GENERAL_PRIVACY_STATEMENT_LINK,
  },
];

const marketingOptInLinks = [
  { link: MANAGE_STATEMENT, key: 'manage', label: manageLabel, eventText: EVENT_TEXT.MANAGE_STATEMENT_LINK },
  links[1],
];

const TOS_CONTENT: Record<string, TosFields[]> = {
  // Simplified Chinese
  // NOTE: using same key 'zh-CN' which is used in the languageMappings.ts file
  'zh-CN': [
    {
      type: TERMS_OF_SERVICE_TYPE.GENERAL,
      // There can be multiple texts in the TOS, so we can use an array of strings, for example in case of marketingOptIn there are two texts 'RECEIVE_ELECTRONIC_MARKET_COMMUNICATIONS' and 'VIEW_AUTODESK_PRIVACY_STATEMENT'
      primaryTexts: ['AUTODESK_GENERAL_TERMS_zh_CN'],
      links,
      optional: false,
      nameSpace: authNamespaces.localeSpecific,
      errorMessage: 'AUTODESK_TERMS_OF_USE',
      ariaDescribedBy: 'generalTermsError',
    },
    {
      type: TERMS_OF_SERVICE_TYPE.LOCAL,
      primaryTexts: ['AUTODESK_LOCAL_TERMS_zh_CN'],
      links: [],
      optional: false,
      nameSpace: authNamespaces.localeSpecific,
      errorMessage: 'AUTODESK_TERMS_OF_USE',
      ariaDescribedBy: 'localTermsError',
    },
  ],
  // Korean
  ko: [
    {
      type: TERMS_OF_SERVICE_TYPE.GENERAL,
      primaryTexts: ['AUTODESK_GENERAL_TERMS_KO'],
      links: [links[0]],
      optional: false,
      nameSpace: authNamespaces.localeSpecific,
      errorMessage: 'AUTODESK_TERMS_OF_USE',
      ariaDescribedBy: 'generalTermsError',
    },
    {
      type: TERMS_OF_SERVICE_TYPE.LOCAL,
      primaryTexts: ['AUTODESK_LOCAL_TERMS_KO'],
      // expanded text for TOS
      showMoreExpandedText: 'AUTODESK_LOCAL_TERMS_EXPANDED_KO',
      links: [
        {
          link: PRIVACY_STATEMENT,
          key: 'privacyStatement',
          label: privacyStatementLabel,
          eventText: EVENT_TEXT.LOCAL_PRIVACY_STATEMENT_LINK,
        },
      ],

      // sequence (example boldText or link) for expanded TOS, when type is boldText, it will be render as bold text and when type is link, it will be render as link
      showMoreExpandedTextSequence: [
        { type: 'boldText' },
        { type: 'boldText' },
        {
          type: 'link',
          link: PRIVACY_STATEMENT,
          key: 'privacyStatement',
          label: privacyStatementLabel,
          eventText: EVENT_TEXT.LOCAL_PRIVACY_STATEMENT_LINK,
        },
        { type: 'boldText' },
      ],
      optional: false,
      nameSpace: authNamespaces.localeSpecific,
      errorMessage: 'AUTODESK_TERMS_OF_USE',
      ariaDescribedBy: 'localTermsError',
    },
  ],
};

// Default English terms of service
const DEFAULT_TOS_CONTENT: TosFields[] = [
  {
    type: TERMS_OF_SERVICE_TYPE.GENERAL,
    primaryTexts: ['COMMON_GENERAL_TOS'],
    links: links,
    optional: false,
    nameSpace: authNamespaces.termsOfService,
    errorMessage: 'AUTODESK_TERMS_OF_USE',
    ariaDescribedBy: 'generalTermsError',
  },
];

const MARKETING_CONTENT: TosFields = {
  type: TERMS_OF_SERVICE_TYPE.MARKETING,
  primaryTexts: ['RECEIVE_ELECTRONIC_MARKET_COMMUNICATIONS'],
  links: marketingOptInLinks,
  optional: true,
  nameSpace: authNamespaces.createAccount,
  ariaDescribedBy: 'marketingTermsError',
};

const TOS_FIELDS = [TERMS_OF_SERVICE_TYPE.GENERAL, TERMS_OF_SERVICE_TYPE.LOCAL];

export { DEFAULT_TOS_CONTENT, MARKETING_CONTENT, TERMS_OF_SERVICE_TYPE, TOS_CONTENT, TOS_FIELDS };
