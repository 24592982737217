import { css } from '@emotion/react';

const checkboxLabelStyles = css({
  alignItems: 'flex-start',
});

const errorTextStyles = css({
  fontSize: 'inherit',
  color: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
});

const marketingOptinLinkStyles = css({
  'a, a:hover,a:visited,a:visited:hover': {
    /* styles specific to <a> tags */
    textDecorationColor: 'inherit',
    color: 'inherit',
  },
});
export { checkboxLabelStyles, errorTextStyles, marketingOptinLinkStyles };
