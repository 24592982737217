// /** @jsxImportSource @emotion/react */
import { ERROR_TYPE, EVENT_TEXT, FLOW_NAME, PAGE_VIEWS } from 'appConstants/analytics';
import { AUTH_ACTIONS } from 'appConstants/auth';
import EmailScreen from 'common/components/EmailScreen/EmailScreen';
import AuthContext from 'context/authContext';
import authNamespaces from 'nameSpaces/authNameSpaces';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AuthError } from 'types';
import { getEventLocation, removeHtmlTags } from 'utilities/commonUtils';
import { getErrorMessages } from 'utilities/errorUtils';
import { trackClick, trackError } from 'utilities/tealiumAnalytics';

const SignInEmailScreen: React.FC = () => {
  const { isCCPA, authCallBack } = useContext(AuthContext);
  const { t } = useTranslation([authNamespaces.common, authNamespaces.email]);

  const onCreateAccountClick = async () => {
    // tracking the create account link here instead of the CustomInput component
    trackClick({
      link_location: getEventLocation(FLOW_NAME.SIGN_IN, PAGE_VIEWS.EMAIL_SCREEN),
      link_text: EVENT_TEXT.CREATE_ACCOUNT_LINK,
      link_url: '#',
    });
    return authCallBack(null, AUTH_ACTIONS.BEGIN_EMAIL_VERIFICATION);
  };

  const handleErrorCallback = (error: AuthError, responseStatus: string) => {
    const errorText = t(getErrorMessages(error, authNamespaces.email)[0], { ns: authNamespaces.email });
    trackError({
      error_code: responseStatus,
      error_reason: error.code ?? '',
      error_message: removeHtmlTags(
        t(getErrorMessages(error, authNamespaces.email)[0], {
          ns: authNamespaces.email,
          lng: 'root',
        }),
      ),
      error_type: ERROR_TYPE.SERVER_SIDE_VALIDATION,
      error_location: getEventLocation(FLOW_NAME.SIGN_IN, PAGE_VIEWS.EMAIL_SCREEN),
    });
    return errorText;
  };

  return (
    <EmailScreen
      heading={
        isCCPA ? (
          t('ENTER_EMAIL', { ns: authNamespaces.email })
        ) : (
          <Trans t={t} i18nKey={'SIGN_IN_HEADING'}>
            <span className="sr-only"></span>
          </Trans>
        )
      }
      formSubmitAction={AUTH_ACTIONS.SUBMIT_EMAIL}
      showFooter={!isCCPA}
      footerText={t('NEW_TO_AUTODESK', { ns: authNamespaces.email })}
      footerLinkText={t('CREATE_ACCOUNT', { ns: authNamespaces.common })}
      handleFlowChange={onCreateAccountClick}
      handleErrorCallback={handleErrorCallback}
      pageFlow={FLOW_NAME.SIGN_IN}
    />
  );
};

export default SignInEmailScreen;
