/** @jsxImportSource @emotion/react */
import Typography from '@digital-hig/typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ERROR_CODES, ERROR_TYPE, EVENT_TEXT, FLOW_NAME, PAGE_VIEWS } from 'appConstants/analytics';
import { AUTH_ACTIONS, AUTH_STATUS, CUSTOM_AUTH_STATUS } from 'appConstants/auth';
import { HCAPTCHA_FLOWS } from 'appConstants/hCaptcha';
import { RESEND_STATUS } from 'appConstants/otp';
import { NAVIGATION_DIRECTIONS } from 'appConstants/routing';
import CardSkeleton from 'common/components/CardSkeleton/CardSkeleton';
import CustomLink from 'common/components/CustomLink/CustomLink';
import EditEmail from 'common/components/EditEmail/EditEmail';
import LoadingButton from 'common/components/LoadingButton/LoadingButton';
import PasswordInput from 'common/components/PasswordInput/PasswordInput';
import ProfilePicture from 'common/components/ProfilePicture/ProfilePicture';
import { disabledLinkStyles } from 'common/styles/commonStyles';
import AuthContext from 'context/authContext';
import authNamespaces from 'nameSpaces/authNameSpaces';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthError, AuthPayload, ValidationResult } from 'types';
import { getEventLocation } from 'utilities/commonUtils';
import { getErrorMessages, validatePassword } from 'utilities/errorUtils';
import { setResendDataInLocalStorage } from 'utilities/otpScreenUtils';
import { trackClick, trackError } from 'utilities/tealiumAnalytics';

type GetBodyProps = {
  disableForm: boolean;
  setDisableForm: (disable: boolean) => void;
};

type GetSubHeadingProps = {
  disableForm: boolean;
  setDisableForm: (disable: boolean) => void;
};

const GetSubHeading: React.FC<GetSubHeadingProps> = ({ disableForm, setDisableForm }) => {
  return (
    <>
      <EditEmail
        linkLocation={getEventLocation(FLOW_NAME.SIGN_IN, PAGE_VIEWS.PASSWORD_SCREEN)}
        disableForm={disableForm}
        setDisableForm={setDisableForm}
      />
      <ProfilePicture />
    </>
  );
};

const GetBody: React.FC<GetBodyProps> = ({ disableForm, setDisableForm }) => {
  const { authCallBack } = useContext(AuthContext);
  const [password, setPassword] = useState('');
  const [passwordValidation, setPasswordValidation] = useState<ValidationResult>({ isValid: true, errorMessage: '' });
  const [showLoader, setShowLoader] = useState(false);
  const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);
  const { t } = useTranslation([authNamespaces.password, authNamespaces.common]);
  const showKeepMeSignedIn = window.idp?.env?.constants?.SHOW_KEEP_ME_SIGNED_IN;

  const focusPasswordInput = () => {
    const passwordInput = document.getElementById('password') as HTMLInputElement;
    passwordInput?.focus();
  };

  const handlePasswordInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!passwordValidation.isValid) {
      setPasswordValidation({ isValid: true, errorMessage: '' });
    }
    setPassword(e.target.value);
  };

  const onSignIn = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationResult = validatePassword(password);
    setPasswordValidation({
      isValid: validationResult.isValid,
      errorMessage: t(validationResult.errorMessage),
    });
    if (!validationResult.isValid) {
      trackError({
        error_code: ERROR_CODES.CLIENT_ERROR,
        error_type: ERROR_TYPE.CLIENT_SIDE_VALIDATION,
        error_message: t(validationResult.errorMessage, { lng: 'root' }),
        error_reason: validationResult.errorMessage,
        error_location: getEventLocation(FLOW_NAME.SIGN_IN, PAGE_VIEWS.PASSWORD_SCREEN),
      });
      // Focus on input on error for better accessibility and screen reader support
      focusPasswordInput();
      return;
    }
    setDisableForm(true);
    setShowLoader(true);

    /**
     * Encode password before sending it to the server
     */
    const encodedPassword = encodeURIComponent(password);
    const payload = { password: encodedPassword, keepMeSignedIn } as AuthPayload;
    const signInResponse = await authCallBack(
      payload,
      AUTH_ACTIONS.CHECK_PASSWORD,
      '',
      NAVIGATION_DIRECTIONS.FORWARD,
      false,
      HCAPTCHA_FLOWS.SIGN_IN,
    );
    if (signInResponse.error) {
      /*
       * Set disableForm to false only in case of error. In case of success the page will be redirected to next screen*
       * When MFA is enabled, multiple calls will happen on submitting password which is handled in Auth component.
       * So can't rely on success of this one call to set disableForm to false. Hence setting it only in case of error.
       */
      setDisableForm(false);
      setShowLoader(false);
      const errorMessage = getErrorMessages(signInResponse.error, authNamespaces.password)[0];
      trackError({
        error_code: signInResponse.responseStatus ?? ERROR_CODES.SERVER_ERROR,
        error_type: ERROR_TYPE.SERVER_SIDE_VALIDATION,
        error_message: t(errorMessage, {
          ns: authNamespaces.password,
          lng: 'root',
        }),
        error_reason: signInResponse.error.code ?? '',
        error_location: getEventLocation(FLOW_NAME.SIGN_IN, PAGE_VIEWS.PASSWORD_SCREEN),
      });
      // Focus on input on error for better accessibility and screen reader support
      focusPasswordInput();
      setPasswordValidation({
        isValid: false,
        errorMessage: t(errorMessage),
      });
    }
  };

  // Below handler is required for Keep me signed in functionality after R1
  const handleKeepMeSignedIn = (e: React.FormEvent<HTMLInputElement>) => {
    const result = e.currentTarget.checked;
    trackClick({
      link_location: getEventLocation(
        getEventLocation(FLOW_NAME.SIGN_IN, PAGE_VIEWS.PASSWORD_SCREEN),
        EVENT_TEXT.CHECKBOX,
      ),
      link_text: EVENT_TEXT.KEEP_ME_SIGNED_IN,
    });
    setKeepMeSignedIn(result);
  };

  return (
    <form noValidate className="form-container" onSubmit={onSignIn}>
      <PasswordInput
        id="password"
        showHidePasswordLinkText="password"
        label={t('PASSWORD')}
        value={password}
        disabled={disableForm}
        error={t(passwordValidation.errorMessage)}
        onChange={handlePasswordInputChange}
        showHidePasswordLinkLocation={getEventLocation(FLOW_NAME.SIGN_IN, PAGE_VIEWS.PASSWORD_SCREEN)}
        autoComplete="current-password"
        autoFocus
      />

      {showKeepMeSignedIn && (
        <FormControlLabel
          sx={{ mt: 6, mr: 0 }}
          disabled={disableForm}
          checked={keepMeSignedIn}
          control={<Checkbox size="small" sx={{ my: 0 }} onChange={handleKeepMeSignedIn} />}
          label={<Typography variant="smallprint">{t('STAY_SIGNED_IN')} </Typography>}
        />
      )}

      <LoadingButton
        isDisabled={disableForm}
        showLoader={showLoader}
        buttonType="submit"
        text={t('SIGN_IN', { ns: 'common' })}
        id="btnSubmit"
        buttonLocation={getEventLocation(FLOW_NAME.SIGN_IN, PAGE_VIEWS.PASSWORD_SCREEN)}
      />
    </form>
  );
};

const PasswordScreen = () => {
  const [disableForm, setDisableForm] = useState(false);
  const { authCallBack, updateStatus, flowType } = useContext(AuthContext);
  const { t } = useTranslation([authNamespaces.password, authNamespaces.common]);
  const trackLocation = getEventLocation(FLOW_NAME.SIGN_IN, PAGE_VIEWS.PASSWORD_SCREEN);

  const handleForgotPassword = async (e: React.MouseEvent) => {
    e.preventDefault();
    setDisableForm(true);

    const response = await authCallBack(null, AUTH_ACTIONS.BEGIN_RESET_PASSWORD);
    if (response.isSuccess && response.response?.status === AUTH_STATUS.RESET_PASSWORD_OTP_REQUIRED) {
      // Temp fix to handle race condition when clearEmail is triggered before sendOtp completes
      // Should be removed once loader screen is implemented for Hcaptcha flows.
      window.sendingOtp = true;
      document.dispatchEvent(new CustomEvent('UPDATE_SENDING_OTP_STATUS'));
      await authCallBack(
        null,
        AUTH_ACTIONS.SEND_OTP,
        '',
        NAVIGATION_DIRECTIONS.FORWARD,
        false,
        HCAPTCHA_FLOWS.EMAIL_VERIFICATION,
      );
      setResendDataInLocalStorage({
        status: RESEND_STATUS.TIMER,
        flowType,
      });
    } else {
      setDisableForm(false);
      trackError({
        error_code: response.responseStatus as string,
        error_type: ERROR_TYPE.SERVER_SIDE_VALIDATION,
        error_message: t(getErrorMessages(response.error as AuthError, authNamespaces.password)[0], { lng: 'root' }),
        error_reason: response.error?.code as string,
        error_location: trackLocation,
      });
      // No specific error handling is required here, hence show generic error page
      updateStatus({ status: CUSTOM_AUTH_STATUS.INTERNAL_SERVER_ERROR, dir: NAVIGATION_DIRECTIONS.FORWARD });
    }
  };

  return (
    <CardSkeleton
      heading={t('WELCOME', { ns: 'common' })}
      body={<GetBody disableForm={disableForm} setDisableForm={setDisableForm} />}
      subHeading={<GetSubHeading disableForm={disableForm} setDisableForm={setDisableForm} />}
      footerType="small"
      footer={
        <Typography variant="short-copy-small" sx={{ mt: 6 }}>
          <CustomLink
            tracking={{
              linkLocation: trackLocation,
              linkText: EVENT_TEXT.FORGOT_PASSWORD_LINK,
            }}
            cssStyles={disableForm ? disabledLinkStyles : ''}
            href="#"
            onClick={handleForgotPassword}
            id="forgot-password-link"
          >
            {t('FORGOT_PASSWORD')}
          </CustomLink>
        </Typography>
      }
      headingAssistiveText={t('ENTER_PASSWORD', { ns: authNamespaces.password })}
    />
  );
};

export default PasswordScreen;
