/** Map the languages defined in json file to the code being sent from the UI */
const LanguageCodeMappings: { [key: string]: string } = {
  en: 'root',
  de: 'de',
  de_de: 'de',
  cs: 'cs',
  cs_cz: 'cs',
  da: 'da',
  da_dk: 'da',
  es: 'es',
  es_es: 'es',
  fi: 'fi',
  fi_fi: 'fi',
  fr: 'fr',
  fr_fr: 'fr',
  fr_ca: 'fr',
  hu: 'hu',
  hu_hu: 'hu',
  it: 'it',
  it_it: 'it',
  ja: 'ja',
  ja_jp: 'ja',
  ko: 'ko',
  ko_kr: 'ko',
  nb: 'no',
  no_no: 'no',
  nl: 'nl',
  nl_nl: 'nl',
  pl: 'pl',
  pl_pl: 'pl',
  pt: 'pt-PT',
  pt_br: 'pt-BR',
  pt_pt: 'pt-PT',
  ru: 'root',
  ru_ru: 'root',
  sv: 'sv',
  sv_se: 'sv',
  tr: 'tr',
  tr_tr: 'tr',
  zh: 'zh-CN',
  zh_hk: 'zh-HK',
  zh_cn: 'zh-CN',
  zh_tw: 'zh-TW',
  zh_mo: 'zh-HK',
  zh_hant: 'zh-TW',
  zh_hans: 'zh-CN',
};

export default LanguageCodeMappings;
