/** @jsxImportSource @emotion/react */
import Typography from '@digital-hig/typography';
import { TypographyProps } from '@digital-hig/typography/lib/types/typography';
import { SerializedStyles } from '@emotion/utils/types';
import Skeleton from '@mui/material/Skeleton';
import CardSkeleton from '../CardSkeleton/CardSkeleton';
import { footerStyles, headingStyles, labelStyles } from './LoadingSkeletonStyles';

const renderSkeleton = (variant: TypographyProps['variant'], css?: SerializedStyles | null) => (
  <Typography css={css} variant={variant}>
    <Skeleton role="progressbar" aria-label="Loading skeleton" />
  </Typography>
);

const LoadingSkeleton = () => {
  return (
    <CardSkeleton
      heading={<Skeleton css={headingStyles} role="progressbar" aria-label="Loading skeleton" />}
      headingComponentType="p"
      footer={renderSkeleton('body-copy-small', footerStyles)}
      footerType="medium"
      body={
        <>
          {renderSkeleton('body-copy-small', labelStyles)}
          {renderSkeleton('headline-largest')}
          {renderSkeleton('headline-largest')}
        </>
      }
    />
  );
};

export default LoadingSkeleton;
