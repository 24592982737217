import { AUTH_STATUS, CUSTOM_AUTH_STATUS } from './auth';

// Page views constants
const PAGE_VIEWS = {
  EMAIL_SCREEN: 'email screen',
  PASSWORD_SCREEN: 'password screen',
  OTP_SCREEN: 'otp screen',
  TERMS_OF_SERVICE_SCREEN: 'terms of service screen',
  SUCCESS_SCREEN: 'success screen',
  REGISTER_USER_SCREEN: 'register user screen',
  MAXIMUM_RETRIES_REACHED_SCREEN: 'maximum retries reached screen',
  LOGOUT_SCREEN: 'logout screen',
  FORBID_ENTRY_SCREEN: 'forbid entry screen',
  INTERNAL_SERVER_ERROR_SCREEN: 'internal server error screen',
  PAGE_NOT_FOUND_SCREEN: 'page not found screen',
  REQUEST_ERROR_SCREEN: 'request error screen',
  SESSION_ENDED_SCREEN: 'session ended screen',
  IDSDK_PRODUCT_SIGNED_IN_SCREEN: 'product signed in screen',
  IDSDK_OPEN_PRODUCT_SCREEN: 'open product screen',
  IDSDK_PRODUCT_SIGN_OUT_SCREEN: 'product sign out screen',
  EMAIL_OTP_MAX_ATTEMPTS_LIMIT_SCREEN: 'email otp max attempts limit screen',
  EMAIL_RESEND_MAX_ATTEMPTS_LIMIT_SCREEN: 'email resend max attempts limit screen',
  CREATE_ACCOUNT_LOGIN_DISABLED_SCREEN: 'create account login disabled screen',
  SIGN_IN_REQUEST_EXPIRED_SCREEN: 'sign in request expired screen',
  MFA_FAILED_SCREEN: 'mfa failed screen',
  LOGGED_OUT_SCREEN: 'logged out screen',
  SAML_ASSERTION_ERROR_SCREEN: 'saml assertion error screen',
  DOMAIN_NOT_FOUND_SCREEN: 'domain not found screen',
  CONNECTION_NOT_FOUND_SCREEN: 'connection not found screen',
  DOMAIN_CONNECTION_MISMATCH_SCREEN: 'domain connection mismatch screen',
  DOMAIN_DISABLED_SCREEN: 'domain disabled screen',
  JIT_DISABLED_SCREEN: 'jit disabled screen',
  USER_DEACTIVATED_SCREEN: 'user deactivated screen',
  UNABLE_TO_SIGN_IN_SSO_SCREEN: 'unable to sign in with sso screen',
  RESET_PASSWORD_SCREEN: 'reset password screen',
};

// Text on link and button
const EVENT_TEXT = {
  CREATE_ACCOUNT_LINK: 'create account link',
  KEEP_ME_SIGNED_IN: 'keep me signed in',
  RESEND_LINK: 'resend',
  SWITCH_LINK: 'switch',
  RETRY_OPEN_PRODUCT_LINK: 'retry open product',
  FORGOT_PASSWORD_LINK: 'forgot password',
  SIGN_OUT_OF_BROWSER_LINK: 'sign out of browser',
  SIGN_IN_WITH_DIFFERENT_ACCOUNT_LINK: 'sign in with different account',
  SIGN_IN_LINK: 'sign in link',
  GENERAL_TERMS_OF_USE_LINK: 'general terms of use',
  LOCAL_TERMS_OF_USE_LINK: 'local terms of use',
  GENERAL_PRIVACY_STATEMENT_LINK: 'general privacy statement',
  LOCAL_PRIVACY_STATEMENT_LINK: 'local privacy statement',
  MANAGE_STATEMENT_LINK: 'manage statement',
  SUBMIT_BUTTON: 'submit button',
  LINK: 'link',
  INVALID_PASSWORD: 'invalid password',
  MISSING: 'missing',
  HIDE: 'hide',
  SHOW: 'show',
  NEW_PASSWORD: 'new password',
  CONFIRM_PASSWORD: 'confirm password',
  INVALID: 'invalid',
  AUTODESK_HOME_PAGE: 'autodesk home page',
  TROUBLESHOOTING_GUIDE: 'troubleshooting guide',
  CONTACT_SUPPORT: 'contact support',
  LEARN_MORE: 'learn more',
  CHECKBOX: 'checkbox',
  SLACK_IDENTITY_INTEGRATION_LINK: 'slack identity integration link',
  OPT_OUT_FEEDBACK_FORM: 'opt out feedback form link',
  OPT_OUT_OVERLAY: 'opt out overlay link',
  CLOSE_OPT_OUT_BANNER: 'close opt out banner button',
  CLOSE_OPT_OUT_OVERLAY: 'opt out overlay close button',
  RECOVER_ACCOUNT: 'recover account',
};

const FLOW_NAME = {
  CREATE_ACCOUNT: 'create account',
  SIGN_IN: 'sign in',
  LOGOUT: 'logout',
  IDSDK: 'idsdk',
  ERROR: 'error',
  INVITE: 'invite',
  ESSO: 'esso',
  RESET_PASSWORD: 'reset password',
  OPT_OUT_OVERLAY: 'opt out overlay',
};

const FLOW_STATUS = {
  INITIATE: 'initiate',
  IN_PROGRESS: 'in progress',
  COMPLETE: 'complete',
  ERROR: 'error',
};

const ESSO_FLOW = {
  flow_name: FLOW_NAME.ESSO,
  flow_status: FLOW_STATUS.ERROR,
};

const ERROR_TYPE = {
  CLIENT_SIDE_VALIDATION: 'client side validation',
  SERVER_SIDE_VALIDATION: 'server side validation',
};

const ERROR_CODES = {
  CLIENT_ERROR: '000',
  SERVER_ERROR: '400',
  GLOBAL_ERROR: '001',
};

const ERROR_REASONS = {
  TOS_NOT_ACCEPTED: 'TOS_NOT_ACCEPTED',
  APP_DOWN: 'APP_DOWN',
  HCAPTCHA_FAILED_TO_LOAD: 'HCAPTCHA_FAILED_TO_LOAD',
};

const PAGE_SITE_NAME = 'idp';

const STATUS_NOT_TO_BE_TRACKED = [
  AUTH_STATUS.AUTHENTICATION_REQUIRED,
  AUTH_STATUS.DEVICE_SELECTION_REQUIRED,
  AUTH_STATUS.MFA_COMPLETED,
  AUTH_STATUS.VERIFICATION_REQUIRED,
  CUSTOM_AUTH_STATUS.SHOW_SKELETON,
];

const AUTH_STATUS_BASED_PAGE_VIEWS = {
  [AUTH_STATUS.EMAIL_REQUIRED]: {
    page_content_name: PAGE_VIEWS.EMAIL_SCREEN,
    flow_name: FLOW_NAME.SIGN_IN,
    flow_status: FLOW_STATUS.INITIATE,
  },
  [AUTH_STATUS.PASSWORD_REQUIRED]: {
    page_content_name: PAGE_VIEWS.PASSWORD_SCREEN,
    flow_name: FLOW_NAME.SIGN_IN,
    flow_status: FLOW_STATUS.IN_PROGRESS,
  },
  [AUTH_STATUS.TOS_REQUIRED]: {
    page_content_name: PAGE_VIEWS.TERMS_OF_SERVICE_SCREEN,
    flow_name: FLOW_NAME.SIGN_IN,
    flow_status: FLOW_STATUS.IN_PROGRESS,
  },
  [AUTH_STATUS.OTP_REQUIRED]: {
    page_content_name: PAGE_VIEWS.OTP_SCREEN,
    flow_name: FLOW_NAME.SIGN_IN,
    flow_status: FLOW_STATUS.IN_PROGRESS,
  },
  [AUTH_STATUS.EMAIL_VERIFICATION_REQUIRED]: {
    page_content_name: PAGE_VIEWS.EMAIL_SCREEN,
    flow_name: FLOW_NAME.CREATE_ACCOUNT,
    flow_status: FLOW_STATUS.INITIATE,
  },
  [AUTH_STATUS.EMAIL_OTP_REQUIRED]: {
    page_content_name: PAGE_VIEWS.OTP_SCREEN,
    // flow name to be added from tealium analytics utils.Since the screen is used for multiple flows
    flow_name: '',
    flow_status: FLOW_STATUS.IN_PROGRESS,
  },
  [AUTH_STATUS.USER_INFO_REQUIRED]: {
    page_content_name: PAGE_VIEWS.REGISTER_USER_SCREEN,
    flow_name: FLOW_NAME.CREATE_ACCOUNT,
    flow_status: FLOW_STATUS.IN_PROGRESS,
  },
  [AUTH_STATUS.RESET_PASSWORD_OTP_REQUIRED]: {
    page_content_name: PAGE_VIEWS.OTP_SCREEN,
    flow_name: FLOW_NAME.RESET_PASSWORD,
    flow_status: FLOW_STATUS.INITIATE,
  },
  [AUTH_STATUS.PASSWORD_UPDATE_REQUIRED]: {
    page_content_name: PAGE_VIEWS.RESET_PASSWORD_SCREEN,
    flow_name: FLOW_NAME.RESET_PASSWORD,
    flow_status: FLOW_STATUS.IN_PROGRESS,
  },
  [CUSTOM_AUTH_STATUS.TOTP_AND_SMS_MAX_ATTEMPTS_LIMIT]: {
    page_content_name: PAGE_VIEWS.MAXIMUM_RETRIES_REACHED_SCREEN,
    flow_name: FLOW_NAME.SIGN_IN,
    flow_status: FLOW_STATUS.IN_PROGRESS,
  },
  [CUSTOM_AUTH_STATUS.CREATE_ACCOUNT_SUCCESS]: {
    page_content_name: PAGE_VIEWS.SUCCESS_SCREEN,
    flow_name: '',
    flow_status: FLOW_STATUS.COMPLETE,
  },
  [CUSTOM_AUTH_STATUS.EMAIL_VERIFICATION_SUCCESSFUL]: {
    page_content_name: PAGE_VIEWS.SUCCESS_SCREEN,
    flow_name: FLOW_NAME.SIGN_IN,
    flow_status: FLOW_STATUS.COMPLETE,
  },
  [CUSTOM_AUTH_STATUS.PASSWORD_RESET_SUCCESS]: {
    page_content_name: PAGE_VIEWS.SUCCESS_SCREEN,
    flow_name: FLOW_NAME.RESET_PASSWORD,
    flow_status: FLOW_STATUS.COMPLETE,
  },
  [CUSTOM_AUTH_STATUS.LOGOUT]: {
    page_content_name: PAGE_VIEWS.LOGOUT_SCREEN,
    flow_name: FLOW_NAME.LOGOUT,
    flow_status: FLOW_STATUS.COMPLETE,
  },
  [CUSTOM_AUTH_STATUS.FORBID_ENTRY]: {
    page_content_name: PAGE_VIEWS.FORBID_ENTRY_SCREEN,
    flow_name: FLOW_NAME.ERROR,
    flow_status: FLOW_STATUS.ERROR,
  },
  [CUSTOM_AUTH_STATUS.INTERNAL_SERVER_ERROR]: {
    page_content_name: PAGE_VIEWS.INTERNAL_SERVER_ERROR_SCREEN,
    // flow name to be added from tealium analytics utils.Since the screen is used for multiple flows
    flow_name: '',
    flow_status: FLOW_STATUS.ERROR,
  },
  [CUSTOM_AUTH_STATUS.PAGE_NOT_FOUND]: {
    page_content_name: PAGE_VIEWS.PAGE_NOT_FOUND_SCREEN,
    flow_name: FLOW_NAME.ERROR,
    flow_status: FLOW_STATUS.ERROR,
  },
  [CUSTOM_AUTH_STATUS.REQUEST_ERROR]: {
    page_content_name: PAGE_VIEWS.REQUEST_ERROR_SCREEN,
    // flow name to be added from tealium analytics utils.Since the screen is used for multiple flows
    flow_name: '',
    flow_status: FLOW_STATUS.ERROR,
  },
  [CUSTOM_AUTH_STATUS.SESSION_ENDED]: {
    page_content_name: PAGE_VIEWS.SESSION_ENDED_SCREEN,
    flow_name: '',
    flow_status: FLOW_STATUS.ERROR,
  },
  [CUSTOM_AUTH_STATUS.PRODUCT_SIGNED_IN]: {
    page_content_name: PAGE_VIEWS.IDSDK_PRODUCT_SIGNED_IN_SCREEN,
    flow_name: FLOW_NAME.IDSDK,
    flow_status: FLOW_STATUS.INITIATE,
  },
  [CUSTOM_AUTH_STATUS.OPEN_PRODUCT]: {
    page_content_name: PAGE_VIEWS.IDSDK_OPEN_PRODUCT_SCREEN,
    flow_name: FLOW_NAME.IDSDK,
    flow_status: FLOW_STATUS.COMPLETE,
  },
  [CUSTOM_AUTH_STATUS.PRODUCT_SIGN_OUT]: {
    page_content_name: PAGE_VIEWS.IDSDK_PRODUCT_SIGN_OUT_SCREEN,
    flow_name: FLOW_NAME.IDSDK,
    flow_status: FLOW_STATUS.COMPLETE,
  },
  [CUSTOM_AUTH_STATUS.EMAIL_OTP_MAX_ATTEMPTS_LIMIT]: {
    page_content_name: PAGE_VIEWS.EMAIL_OTP_MAX_ATTEMPTS_LIMIT_SCREEN,
    // flow name to be added from tealium analytics utils. Since the screen is used for multiple flows
    flow_name: '',
    flow_status: FLOW_STATUS.ERROR,
  },
  [CUSTOM_AUTH_STATUS.EMAIL_RESEND_MAX_ATTEMPTS_LIMIT]: {
    page_content_name: PAGE_VIEWS.EMAIL_RESEND_MAX_ATTEMPTS_LIMIT_SCREEN,
    flow_name: '',
    flow_status: FLOW_STATUS.ERROR,
  },
  [CUSTOM_AUTH_STATUS.CREATE_ACCOUNT_LOGIN_DISABLED]: {
    page_content_name: PAGE_VIEWS.CREATE_ACCOUNT_LOGIN_DISABLED_SCREEN,
    flow_name: FLOW_NAME.CREATE_ACCOUNT,
    flow_status: FLOW_STATUS.ERROR,
  },
  [CUSTOM_AUTH_STATUS.SIGN_IN_REQUEST_EXPIRED]: {
    page_content_name: PAGE_VIEWS.SIGN_IN_REQUEST_EXPIRED_SCREEN,
    flow_name: FLOW_NAME.IDSDK,
    flow_status: FLOW_STATUS.ERROR,
  },
  [AUTH_STATUS.MFA_FAILED]: {
    page_content_name: PAGE_VIEWS.MFA_FAILED_SCREEN,
    flow_name: FLOW_NAME.SIGN_IN,
    flow_status: FLOW_STATUS.IN_PROGRESS,
  },
  [CUSTOM_AUTH_STATUS.LOGGED_OUT]: {
    page_content_name: PAGE_VIEWS.LOGGED_OUT_SCREEN,
    flow_name: FLOW_NAME.LOGOUT,
    flow_status: FLOW_STATUS.COMPLETE,
  },
  [CUSTOM_AUTH_STATUS.SAML_ASSERTION_ERROR]: {
    page_content_name: PAGE_VIEWS.SAML_ASSERTION_ERROR_SCREEN,
    ...ESSO_FLOW,
  },
  [CUSTOM_AUTH_STATUS.DOMAIN_NOT_FOUND]: {
    page_content_name: PAGE_VIEWS.DOMAIN_NOT_FOUND_SCREEN,
    ...ESSO_FLOW,
  },
  [CUSTOM_AUTH_STATUS.CONNECTION_NOT_FOUND]: {
    page_content_name: PAGE_VIEWS.CONNECTION_NOT_FOUND_SCREEN,
    ...ESSO_FLOW,
  },
  [CUSTOM_AUTH_STATUS.DOMAIN_CONNECTION_MISMATCH]: {
    page_content_name: PAGE_VIEWS.DOMAIN_CONNECTION_MISMATCH_SCREEN,
    ...ESSO_FLOW,
  },
  [CUSTOM_AUTH_STATUS.DOMAIN_DISABLED]: {
    page_content_name: PAGE_VIEWS.DOMAIN_DISABLED_SCREEN,
    ...ESSO_FLOW,
  },
  [CUSTOM_AUTH_STATUS.JIT_DISABLED]: {
    page_content_name: PAGE_VIEWS.JIT_DISABLED_SCREEN,
    ...ESSO_FLOW,
  },
  [CUSTOM_AUTH_STATUS.USER_DEACTIVATED]: {
    page_content_name: PAGE_VIEWS.USER_DEACTIVATED_SCREEN,
    ...ESSO_FLOW,
  },
  [CUSTOM_AUTH_STATUS.UNABLE_TO_SIGN_IN_SSO]: {
    page_content_name: PAGE_VIEWS.UNABLE_TO_SIGN_IN_SSO_SCREEN,
    ...ESSO_FLOW,
  },
};

const CHECK_ANALYTICS_SCRIPT_LOAD_INTERVAL_MS = 500;
const CHECK_ANALYTICS_SCRIPT_LOAD_TRIES = 8;

const EVENT_TYPE = {
  PAGE_VIEW: 'page_view',
  LINK_CLICK: 'link_click',
  PAGE_ERROR: 'page_error',
};

const EMAIL_ERROR_TO_ANALYTICS_TEXT_MAP: {
  [key: string]: string;
} = {
  ACCOUNT_QUEUED_FOR_DELETION: EVENT_TEXT.RECOVER_ACCOUNT,
};

export {
  AUTH_STATUS_BASED_PAGE_VIEWS,
  CHECK_ANALYTICS_SCRIPT_LOAD_INTERVAL_MS,
  CHECK_ANALYTICS_SCRIPT_LOAD_TRIES,
  EMAIL_ERROR_TO_ANALYTICS_TEXT_MAP,
  ERROR_CODES,
  ERROR_REASONS,
  ERROR_TYPE,
  EVENT_TEXT,
  EVENT_TYPE,
  FLOW_NAME,
  FLOW_STATUS,
  PAGE_SITE_NAME,
  PAGE_VIEWS,
  STATUS_NOT_TO_BE_TRACKED,
};
