/** @jsxImportSource @emotion/react */
import Typography from '@digital-hig/typography';
import { CUSTOM_AUTH_STATUS, EVENT_TEXT, FLOW_NAME, NAVIGATION_DIRECTIONS, PAGE_VIEWS } from 'appConstants';
import CardSkeleton from 'common/components/CardSkeleton/CardSkeleton';
import CustomLink from 'common/components/CustomLink/CustomLink';
import AuthContext from 'context/authContext';
import authNamespaces from 'nameSpaces/authNameSpaces';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getEventLocation } from 'utilities/commonUtils';

const ProductSignOutScreen = () => {
  const { t } = useTranslation(authNamespaces.idsdk);
  const { updateStatus } = useContext(AuthContext);

  const handleSignOut = (e: React.FormEvent) => {
    e.preventDefault();
    const url = new URL(window.location.href);
    url.searchParams.delete('from_desktop');
    // Convert the URL object back to string format
    window.history.replaceState({}, '', url.toString());
    updateStatus({ status: CUSTOM_AUTH_STATUS.LOGOUT, dir: NAVIGATION_DIRECTIONS.FORWARD });
  };

  return (
    <CardSkeleton
      heading={t('SIGN_OUT')}
      body={
        <>
          <Typography sx={{ mb: 6 }}>{t('SIGNED_OUT_FROM_PRODUCT_SIGNED_IN_FROM_BROWSER')}</Typography>
          <CustomLink
            tracking={{
              linkText: EVENT_TEXT.SIGN_OUT_OF_BROWSER_LINK,
              linkLocation: getEventLocation(FLOW_NAME.IDSDK, PAGE_VIEWS.IDSDK_PRODUCT_SIGN_OUT_SCREEN),
            }}
            href="#"
            onClick={handleSignOut}
          >
            <Typography>{t('SIGN_OUT_OF_BROWSER')}</Typography>
          </CustomLink>
        </>
      }
    />
  );
};

export default ProductSignOutScreen;
