const TRANSLATION_CUSTOM_EVENTS = {
  SEND_TRANSLATION_CONTENT: 'sendTranslationContent',
  TRANSLATIONS_CONTENT: 'translationsContent',
};

const FLOW_STATUS_CUSTOM_EVENTS = {
  FETCH_FLOW_STATUS: 'fetchFlowStatus',
  SEND_FLOW_STATUS: 'sendFlowStatus',
};

export { FLOW_STATUS_CUSTOM_EVENTS, TRANSLATION_CUSTOM_EVENTS };
