/** @jsxImportSource @emotion/react */
import { bgImageStyles, bgPictureStyles } from './BackgroundImageStyles';

type BackgroundImageProps = {
  imageFolderPath: string;
  imageName: string;
  supportedFormats: Array<string>;
  fallbackFormat: string;
};

const BackgroundImage = (props: BackgroundImageProps) => {
  const { imageFolderPath, imageName, supportedFormats, fallbackFormat } = props;
  const bgImagePath = `${imageFolderPath}${imageName}`;
  return (
    <picture css={bgPictureStyles} data-testid={imageName}>
      {supportedFormats.map((format) => (
        <source
          data-testid={`${imageName}-${format}`}
          key={format}
          srcSet={`${bgImagePath}.${format}`}
          type={`image/${format}`}
        />
      ))}
      <img css={bgImageStyles} src={`${bgImagePath}.${fallbackFormat}`} alt="" />
    </picture>
  );
};

export default BackgroundImage;
