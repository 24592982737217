const authNamespaces = {
  email: 'email',
  password: 'password',
  otp: 'otp',
  mfa: 'mfa',
  common: 'common',
  success: 'success',
  termsOfService: 'termsOfService',
  verifyEmail: 'verifyEmail',
  error: 'error',
  logout: 'logout',
  idsdk: 'idsdk',
  createAccount: 'createAccount',
  localeSpecific: 'localeSpecific',
};

export default authNamespaces;
