const RESEND_STATUS = {
  RESEND: 'RESEND',
  SENDING: 'SENDING',
  TIMER: 'TIMER',
  ERROR: 'ERROR',
};

const TIMER_TYPE = {
  SECONDS: 'SECONDS',
  MINUTES: 'MINUTES',
};

const RESEND_COUNTDOWN_TEN = 10;

const RESEND_COUNTDOWN_THIRTY = 30;

const RESEND_COUNTDOWN_SIXTY = 60;

const CONTINUE_COUNTDOWN_SECONDS = 10;

const RESEND_DATA = 'resendData';

export {
  CONTINUE_COUNTDOWN_SECONDS,
  RESEND_COUNTDOWN_SIXTY,
  RESEND_COUNTDOWN_TEN,
  RESEND_COUNTDOWN_THIRTY,
  RESEND_DATA,
  RESEND_STATUS,
  TIMER_TYPE,
};
