import { CUSTOM_AUTH_STATUS, ERROR_CODES, ERROR_REASONS, ERROR_TYPE, PAGE_VIEWS } from 'appConstants';
import GlobalError from 'common/components/GlobalError/GlobalError';
import obs from 'logging/observability/observability';
import UILogger from 'logging/UILogger';
import { Component, ErrorInfo, PropsWithChildren } from 'react';
import { trackError } from 'utilities/tealiumAnalytics';

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<PropsWithChildren, ErrorBoundaryState> {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    return { errorMessage: error.toString() };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // error: the actual error message that tells you what went wrong
    // errorInfo: additional details about the error including the stack trace to help you debug the error.
    this.setState({ hasError: true });

    UILogger.errorLogMethod({
      message: 'App down.',
      error: error.toString(),
      componentStack: info.componentStack,
    });
    try {
      obs.logError(error, info);
    } catch (error) {
      // no op
    }
    trackError({
      error_location: PAGE_VIEWS.INTERNAL_SERVER_ERROR_SCREEN,
      error_type: ERROR_TYPE.SERVER_SIDE_VALIDATION,
      error_code: ERROR_CODES.GLOBAL_ERROR,
      error_reason: ERROR_REASONS.APP_DOWN,
      error_message: ERROR_REASONS.APP_DOWN,
    });
  }

  render() {
    if (this.state.hasError) {
      return <GlobalError statusOverride={CUSTOM_AUTH_STATUS.INTERNAL_SERVER_ERROR} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
