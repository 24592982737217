import { ENDPOINTS, URL_PARAMS } from 'appConstants';
import { APIoptions, AuthPayload } from 'types';

export const getURLParam = (key: string) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
};

export const getActionURL = (action: string | null) => {
  const { BASE_URL, API_BASE_PATH, FLOWS } = ENDPOINTS;
  const actionParam = action ? `?action=${action}` : '';
  return `${BASE_URL}/${API_BASE_PATH}/${FLOWS}/${getURLParam('flowId')}${actionParam}`;
};

export const getLogoutURL = (targetResource: string) => {
  const { BASE_URL, PF_LOGOUT } = ENDPOINTS;
  return `${BASE_URL}/${PF_LOGOUT}?${URL_PARAMS.TARGET_RESOURCE}=${targetResource}`;
};

export const getOptions = (
  payload: AuthPayload | null,
  method = 'GET',
  isContentTypeJson = true,
  hCaptchaToken?: string | null,
): APIoptions => ({
  method: method,
  credentials: 'include' as RequestCredentials,
  mode: 'cors' as RequestMode,
  ...(isContentTypeJson && {
    headers: {
      'Content-Type': 'application/json',
      'X-XSRF-Header': 'Autodesk-Identity',
      ...(hCaptchaToken && { 'h-captcha-token': hCaptchaToken }),
    },
  }),
  ...(payload && { body: JSON.stringify(payload) }),
});

export const getOktaSessionOptions = {
  method: 'DELETE',
  credentials: 'include' as RequestCredentials,
  headers: {
    'Content-type': 'application/json; charset=UTF-8',
  },
};
