import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';
import { css } from '@emotion/react';

const headingTextStyles = (link = false) => {
  // Allow the link to fit in the container in case of long emails
  return css({
    color: lightTheme.tokens.colors['ink-on-background-60'],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: `${lightTheme.primitives.spacings[2]}`,
    maxWidth: link ? '88%' : '100%',
  });
};

const subHeadingWithLinkStyles = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: 'max-content',
  maxWidth: '100%',
});

export { headingTextStyles, subHeadingWithLinkStyles };
