import App from 'App';
import MainWrapper from 'components/Wrappers/MainWrapper/MainWrapper';
import ReactDOM from 'react-dom';

ReactDOM.render(
  <MainWrapper initialFetchingFlowStatus>
    <App />
  </MainWrapper>,
  document.getElementById('root'),
);
