export const REGISTER_USER_FIELDS = [
  {
    name: 'firstName',
    label: 'FIRST_NAME',
    id: 'firstname_str',
  },
  {
    name: 'lastName',
    label: 'LAST_NAME',
    id: 'lastname_str',
  },
];

export const LOCALES_FOR_FIELD_SWAPPING = [
  'ja',
  'ja_JP',
  'ko',
  'ko_KR',
  'zh',
  'zh_CN',
  'zh_TW',
  'zh_HK',
  'hu',
  'hu_HU',
];

export const NAME_ALLOWED_CHARACTER_LENGTH = 75;

export const NAME_ALLOWED_BYTE_LENGTH = 128;

export const MAX_LOCAL_PART_EMAIL_ALLOWED_LENGTH = 64;

export const MAX_DOMAIN_PART_EMAIL_ALLOWED_LENGTH = 255;

export const MAX_PASSWORD_ALLOWED_LENGTH = 50;
