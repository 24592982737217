import { AUTH_STATUS, CUSTOM_AUTH_STATUS, FLOW_TYPE } from 'appConstants/auth';
import { AUTODESK_TEXT } from 'appConstants/common';
import { TITLE_STATUS_MAP } from 'appConstants/pageTitle';

export const getTitleAndNameSpaceFromStatus = (status: string, isCCPA: boolean, flowType: string) => {
  switch (status) {
    case AUTH_STATUS.OTP_REQUIRED:
    case CUSTOM_AUTH_STATUS.EMAIL_OTP_MAX_ATTEMPTS_LIMIT:
    case CUSTOM_AUTH_STATUS.EMAIL_RESEND_MAX_ATTEMPTS_LIMIT:
      return TITLE_STATUS_MAP[
        isCCPA ||
        flowType === FLOW_TYPE.EMAIL_VERIFICATION ||
        flowType === FLOW_TYPE.INVITE ||
        flowType === FLOW_TYPE.CREATE_ACCOUNT
          ? AUTH_STATUS.EMAIL_OTP_REQUIRED
          : AUTH_STATUS.OTP_REQUIRED
      ];
    default:
      return TITLE_STATUS_MAP[status];
  }
};

export const getTitleText = (title: string) => `${title} - ${AUTODESK_TEXT}`;

export const updatePageTitle = (title: string) => {
  document.title = getTitleText(title);
};
