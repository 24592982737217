/** @jsxImportSource @emotion/react */
import Typography from '@digital-hig/typography';
import Box from '@mui/material/Box';
import { AUTH_STATUS_BASED_PAGE_VIEWS } from 'appConstants/analytics';
import { ELEMENT_TYPES } from 'appConstants/elements';
import { GLOBAL_ERROR_ACTIONS, GLOBAL_ERROR_MESSAGES } from 'appConstants/error';
import CustomLink from 'common/components/CustomLink/CustomLink';
import LoadingButton from 'common/components/LoadingButton/LoadingButton';
import { commonLinkStyles, errorIconStyles } from 'common/styles/commonStyles';
import AuthContext from 'context/authContext';
import authNamespaces from 'nameSpaces/authNameSpaces';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getEventLocation } from 'utilities/commonUtils';
import ErrorImage from '../ErrorImage/ErrorImage';

type DynamicMessagePlaceholder = {
  username?: string;
};
type GlobalErrorProps = {
  statusOverride?: string;
  //for dynamic values received from the backend
  dynamicMessagePlaceholder?: DynamicMessagePlaceholder;
};

const GlobalError = ({ statusOverride, dynamicMessagePlaceholder }: GlobalErrorProps) => {
  const {
    authorizeUrl,
    flowStatus: { status },
    flowType,
  } = useContext(AuthContext);
  const { t } = useTranslation([authNamespaces.error, authNamespaces.common]);
  // Constructs an object with a 'username' property if dynamicMessagePlaceholder is defined, otherwise returns an empty object
  const dynamicMessage = dynamicMessagePlaceholder ?? {};

  const errorStatus = statusOverride ?? status;

  const { HEADER, BODY, ACTION_ELEMENT, LINK } = GLOBAL_ERROR_MESSAGES[errorStatus] || {};

  const reinitiateLogin = () => {
    window.location.replace(authorizeUrl);
  };

  const actionMap = {
    [GLOBAL_ERROR_ACTIONS.REAUTHORIZE]: reinitiateLogin,
  };

  const getAction = () => {
    if (!ACTION_ELEMENT) return null;

    let ele;

    switch (ACTION_ELEMENT.TYPE) {
      case ELEMENT_TYPES.LINK:
        ele = (
          <CustomLink href={ACTION_ELEMENT.ACTION} id={ACTION_ELEMENT.ID}>
            {t(ACTION_ELEMENT.TEXT, { ns: authNamespaces.common })}
          </CustomLink>
        );
        break;

      case ELEMENT_TYPES.BUTTON:
        ele = (
          <LoadingButton
            text={t(ACTION_ELEMENT.TEXT, { ns: authNamespaces.common })}
            onClick={actionMap[ACTION_ELEMENT.ACTION]}
            id={ACTION_ELEMENT.ID}
            buttonLocation={getEventLocation(flowType, AUTH_STATUS_BASED_PAGE_VIEWS[status].page_content_name)}
            fullWidth={false}
          />
        );
        break;

      default:
        ele = null;
    }

    return ele ? <Box mt={7}>{ele}</Box> : null;
  };

  return (
    <>
      <ErrorImage errorStyles={errorIconStyles} />
      <Typography sx={{ mb: 2 }} variant="headline-medium" component="h1">
        {t(HEADER, dynamicMessage)}
      </Typography>
      <Typography variant="body-copy-medium">
        {LINK ? (
          <Trans defaults={t(BODY, dynamicMessage) ?? ''}>
            <CustomLink
              tracking={{
                linkText: LINK.EVENT_TEXT,
                linkLocation: getEventLocation(flowType, AUTH_STATUS_BASED_PAGE_VIEWS[status]?.page_content_name),
              }}
              href={LINK.URL}
              outbound={LINK.OUTBOUND}
              cssStyles={commonLinkStyles}
              target="_blank"
            />
          </Trans>
        ) : (
          t(BODY, dynamicMessage)
        )}
      </Typography>
      {getAction()}
    </>
  );
};
export default GlobalError;
