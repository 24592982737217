import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';

import { css } from '@emotion/react';

const lgMaxWidth = `@media (max-width: ${lightTheme.tokens.grid.lg.breakpoint})`;
const mdMaxWidth = `@media (max-width: ${lightTheme.tokens.grid.md.breakpoint})`;

const cardStyles = (isWhiteBackground = false) => css`
  max-width: 35em;
  width: 100%;
  margin-top: 15vh;
  margin-bottom: 15vh;
  min-height: 17.8em;
  box-shadow: ${isWhiteBackground ? `none` : `0px 0px 0px 1px #000 inset, 0px 3px 0px 0px #000`};
  position: relative;
  z-index: 2;
  ${lgMaxWidth} {
    margin-top: 0;
    margin-bottom: 0;
    max-width: 100%;
    box-shadow: none;
    min-height: 100vh;
  }
`;

const logoStyles = css`
  padding: ${lightTheme.primitives.spacings[7]};
  display: flex;
  align-items: center;
  ${lgMaxWidth} {
    padding-left: ${lightTheme.primitives.spacings[6]};
    padding-right: ${lightTheme.primitives.spacings[6]};
  }
  ${mdMaxWidth} {
    padding-left: ${lightTheme.primitives.spacings[4]};
    padding-right: ${lightTheme.primitives.spacings[4]};
  }
`;

const cardContentStyles = css`
  padding: ${lightTheme.primitives.spacings[7]};
  padding-bottom: ${lightTheme.primitives.spacings[7]} !important;
  ${lgMaxWidth} {
    max-width: 35em;
    margin: 0 auto;
  }
  ${mdMaxWidth} {
    max-width: 100%;
    padding-left: ${lightTheme.primitives.spacings[4]};
    padding-right: ${lightTheme.primitives.spacings[4]};
  }
`;

export { cardContentStyles, cardStyles, logoStyles };
