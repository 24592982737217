/** @jsxImportSource @emotion/react */
import Typography from '@digital-hig/typography';
import CustomLink from 'common/components/CustomLink/CustomLink';
import { disabledLinkStyles } from 'common/styles/commonStyles';
import { headingTextStyles, subHeadingWithLinkStyles } from './SubHeadingWithLinkStyles';

interface SubHeadingWithLinkProps {
  headingTextId?: string;
  headingText: string;
  disableForm?: boolean;
  tracking?: {
    linkLocation: string;
    linkText: string;
  };
  outBound?: boolean;
  link?: {
    onClick: (e: React.MouseEvent) => Promise<void>;
    href: string;
    text: string;
    target?: string;
  };
}

const SubHeadingWithLink = ({
  headingTextId,
  headingText,
  disableForm,
  link,
  tracking,
  outBound,
}: SubHeadingWithLinkProps) => {
  return (
    <Typography variant="short-copy-small" css={subHeadingWithLinkStyles}>
      <span css={headingTextStyles(Boolean(link))} id={headingTextId} data-testid="user-email">
        {headingText}{' '}
      </span>

      {link && (
        <CustomLink
          href={link.href}
          outBound={outBound}
          tracking={tracking}
          cssStyles={disableForm ? disabledLinkStyles : ''}
          onClick={link.onClick}
          target={link.target}
        >
          {link.text}
        </CustomLink>
      )}
    </Typography>
  );
};

export default SubHeadingWithLink;
