import { AUTH_ACTIONS } from './auth';

const HCAPTCHA_FLOWS = {
  SIGN_IN: 'sign-in',
  EMAIL_VERIFICATION: 'email-verification',
  MFA: 'mfa',
};

// Hcpatcha error retry limit. Currently set to 0, can be updated based on requirement.
const HCAPTCHA_FAILURE_RETRY_LIMIT = 0;

const HCAPTCHA_REQUIRED_HEADER = 'H-Captcha-Required';

const HCAPTCHA_SITE_TYPE = {
  //  to always execute hCaptcha, where hCaptcha decides whether to show the captcha or return the header value directly - 99% passive
  PASSIVE_IMPLICIT: 'PASSIVE_IMPLICIT',
  // Explicit implies backend explicitly tells frontend to show hcaptcha
  PASSIVE_EXPLICIT: 'PASSIVE_EXPLICIT',
};

const HCAPTCHA_CONFIG = {
  [HCAPTCHA_FLOWS.SIGN_IN]: {
    siteKey: window.idp?.env?.constants?.HCAPTCHA_SITE_KEY_SIGN_IN,
    hcaptchaType: HCAPTCHA_SITE_TYPE.PASSIVE_EXPLICIT,
  },
  [HCAPTCHA_FLOWS.EMAIL_VERIFICATION]: {
    siteKey: window.idp?.env?.constants?.HCAPTCHA_SITE_KEY_EMAIL_VERIFICATION,
    hcaptchaType: HCAPTCHA_SITE_TYPE.PASSIVE_IMPLICIT,
  },
  [HCAPTCHA_FLOWS.MFA]: {
    siteKey: window.idp?.env?.constants?.HCAPTCHA_SITE_KEY_MFA,
    hcaptchaType: HCAPTCHA_SITE_TYPE.PASSIVE_IMPLICIT,
  },
};

const ACTIONS_FOR_HCAPTCHA = [
  AUTH_ACTIONS.AUTHENTICATE,
  AUTH_ACTIONS.SELECT_DEVICE,
  AUTH_ACTIONS.RESEND_OTP,
  AUTH_ACTIONS.SEND_OTP,
  AUTH_ACTIONS.CHECK_PASSWORD,
];

const HCAPTCHA_LOG_TEXTS = {
  OPENED: 'hcaptcha challenge opened',
  CLOSED: 'hcaptcha challenge closed',
};

const HCAPTCHA_ERRORS = {
  INVALID_HCAPTCHA: 'invalid_hcaptcha',
  HCAPTCHA_REQUIRED: 'hcaptcha_required',
};

export {
  ACTIONS_FOR_HCAPTCHA,
  HCAPTCHA_CONFIG,
  HCAPTCHA_ERRORS,
  HCAPTCHA_FAILURE_RETRY_LIMIT,
  HCAPTCHA_FLOWS,
  HCAPTCHA_LOG_TEXTS,
  HCAPTCHA_REQUIRED_HEADER,
  HCAPTCHA_SITE_TYPE,
};
