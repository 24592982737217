import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';
import { css } from '@emotion/react';

const mainContainerStyles = (whiteBackground = false) => css`
  ${!whiteBackground && 'background-color: #000;'}
  min-height: 100vh;
  width: 100%;
  display: block;
  position: relative;
  @media (max-width: ${lightTheme.tokens.grid.lg.breakpoint}) {
    background-color: #fff;
  }
`;

const cardWrapperStyles = css`
  display: flex;
  justify-content: center;
`;

export { cardWrapperStyles, mainContainerStyles };
