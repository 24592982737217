import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';
import { css } from '@emotion/react';

const bgImageStyles = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  @media (max-width: ${lightTheme.tokens.grid.lg.breakpoint}) {
    display: none;
  }
`;

const bgPictureStyles = css`
  @media (max-width: ${lightTheme.tokens.grid.lg.breakpoint}) {
    display: none;
  }
`;

export { bgImageStyles, bgPictureStyles };
