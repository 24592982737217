import { css } from '@emotion/react';

const headingStyles = css({
  width: '5em',
  display: 'block',
});

const labelStyles = css({
  width: '5em',
});

const footerStyles = css({
  width: '20em',
});

export { footerStyles, headingStyles, labelStyles };
