import { CUSTOM_AUTH_STATUS } from 'appConstants/auth';
import {
  AUTHORIZE_URL_PATH,
  DEVELOPER_API_BASE_URL,
  OAUTH_CODE_CHALLENGE_METHODS,
  OAUTH_PARAMS,
  OAUTH_PROMPTS,
  OAUTH_RESPONSE_TYPES,
  WELL_KNOWN_CONFIG_PATH,
} from 'appConstants/common';
import {
  IDSDK_ALTERNATE_PROTOCOL_INDICATOR,
  IDSDK_AUTH_CODE_PREFIX,
  IDSDK_FRAGMENT_PARAMS,
  IDSDK_PROTOCOL,
  PROFILE_IMAGE_BUCKET,
  PROFILE_IMAGE_PATH,
} from 'appConstants/idsdkConstants';

import { createRemoteJWKSet, jwtVerify } from 'jose';
import { CustomErrorType } from 'types';
import { IdsdkParams, ParsedIdTokenValues, ProductSignInPageInfo, WellKnownConfig } from 'types/Idsdk';
import { decodeBase64, encodeBase64, getFragmentParam, setFragmentParamInUrl } from './commonUtils';

export const getIdsdkFragmentParams = (url: string): IdsdkParams => {
  const params = { idToken: null, state: null, code: null } as IdsdkParams;
  try {
    const urlObj = new URL(url);
    const fragment = urlObj.hash;
    const fragmentParams = new URLSearchParams(fragment.slice(1));
    const idToken = fragmentParams.get(IDSDK_FRAGMENT_PARAMS.ID_TOKEN);
    const state = fragmentParams.get(IDSDK_FRAGMENT_PARAMS.STATE);
    const code = fragmentParams.get(IDSDK_FRAGMENT_PARAMS.CODE);
    return { idToken, state, code };
  } catch (error) {
    return params;
  }
};

export const isLocalePresentInIdsdkState = (stateParams: Array<string>): boolean => {
  /**
   * locale is the last param in the state.
   * For old protocol, state will have 3 params: clientId.codeChallenge.scope
   * For new protocol, state will have 4 params: clientId.codeChallenge.scope.use_alternative_product_path
   * If locale is present, state will have 4 params for old protocol: clientId.codeChallenge.scope.locale
   * If locale is present, state will have 5 params for new protocol: clientId.codeChallenge.scope.locale.use_alternative_product_path
   **/
  const isStateOldIdsdkProtocolWithoutLocale = stateParams.length === 3;
  const isStateNewIdsdkProtocolWithoutLocale =
    stateParams.length === 4 && stateParams[3].toLowerCase() === IDSDK_ALTERNATE_PROTOCOL_INDICATOR;
  return !isStateOldIdsdkProtocolWithoutLocale && !isStateNewIdsdkProtocolWithoutLocale;
};

export const getAndRemoveUiLocaleFromIdsdkState = (): string => {
  const state = getFragmentParam(IDSDK_FRAGMENT_PARAMS.STATE);
  if (state) {
    try {
      const decodedState = decodeBase64(decodeURIComponent(state));
      const stateParams = decodedState.split('.');
      if (isLocalePresentInIdsdkState(stateParams)) {
        /**
         * locale is the last param in the state
         * NOTE: in case locale is absent there will be a '.' in the end followed by no value
         * (example state value: clientId.codeChallenge.scope.)
         * In this case split('.') will return an array with last element as empty string
         * which will be removed by slice(0, -1)
         */
        const newStateWithoutLocale = stateParams.slice(0, -1).join('.');
        const newEncodedState = encodeBase64(newStateWithoutLocale);
        window.location.href = setFragmentParamInUrl(IDSDK_FRAGMENT_PARAMS.STATE, newEncodedState);
        return stateParams[stateParams.length - 1];
      }
    } catch (error) {
      return '';
    }
  }
  return '';
};

export const getAvatarUrlFromUserId = (userId: string): string => {
  const currentDate = new Date();
  /**
   * Set hours, minutes, seconds, and milliseconds to zero
   */
  currentDate.setHours(0, 0, 0, 0);
  const epochTime = currentDate.getTime();

  /**
   * e.g. https://images.profile.autodesk.com/ABCD234AWRE/profilepictures/x50.jpg?r=638535924902165800
   */
  const userProfileImageUrl = `${PROFILE_IMAGE_BUCKET}/${userId}${PROFILE_IMAGE_PATH}${epochTime}`;

  return userProfileImageUrl;
};

export const getGoToProductUrl = (state: string, code: string): string => {
  try {
    const decodedState = decodeBase64(decodeURIComponent(state));
    if (decodedState === '') {
      return '';
    }
    const stateParams = decodedState.split('.');
    const isNewIdsdkProtocol =
      stateParams.length === 4 && stateParams[3].toLowerCase() === IDSDK_ALTERNATE_PROTOCOL_INDICATOR;
    return `${
      isNewIdsdkProtocol ? IDSDK_PROTOCOL.NEW : IDSDK_PROTOCOL.OLD
    }?code=${IDSDK_AUTH_CODE_PREFIX}${code}&state=${state}`;
  } catch (error) {
    return '';
  }
};

export const getSwitchAccountUrl = (state: string, uiLocale: string): string => {
  let decodedState = '';
  try {
    decodedState = decodeBase64(decodeURIComponent(state));
  } catch (error) {
    return '';
  }
  const stateParams = decodedState.split('.');
  const switchUserUrl = new URL(`${DEVELOPER_API_BASE_URL}${AUTHORIZE_URL_PATH}`);
  switchUserUrl.searchParams.append(OAUTH_PARAMS.REDIRECT_URI, window.location.origin + window.location.pathname);
  switchUserUrl.searchParams.append(OAUTH_PARAMS.CLIENT_ID, stateParams[0]);
  switchUserUrl.searchParams.append(OAUTH_PARAMS.SCOPE, decodeURIComponent(stateParams[2]));
  switchUserUrl.searchParams.append(OAUTH_PARAMS.STATE, state);
  switchUserUrl.searchParams.append(OAUTH_PARAMS.CODE_CHALLENGE, stateParams[1]);
  switchUserUrl.searchParams.append(OAUTH_PARAMS.CODE_CHALLENGE_METHOD, OAUTH_CODE_CHALLENGE_METHODS.S256);
  switchUserUrl.searchParams.append(OAUTH_PARAMS.RESPONSE_TYPE, OAUTH_RESPONSE_TYPES.CODE);
  switchUserUrl.searchParams.append(OAUTH_PARAMS.PROMPT, OAUTH_PROMPTS.LOGIN);
  switchUserUrl.searchParams.append(OAUTH_PARAMS.UI_LOCALES, uiLocale);
  return switchUserUrl.href;
};

export const getProductSignInPageInfo = (
  idTokenPayload: ParsedIdTokenValues,
  state: string,
  code: string,
  uiLocale: string,
): ProductSignInPageInfo => {
  const userInfo = {
    email: idTokenPayload.user_email,
    name: idTokenPayload.user_name,
    avatar: '',
    goToProductUrl: '',
    switchAccountUrl: '',
  };
  userInfo.avatar = getAvatarUrlFromUserId(idTokenPayload.userid);
  userInfo.goToProductUrl = getGoToProductUrl(state, code);
  userInfo.switchAccountUrl = getSwitchAccountUrl(state, uiLocale);
  return userInfo;
};

export const getOpenIdWellKnownConfig = async (): Promise<WellKnownConfig> => {
  try {
    const response = await fetch(`${DEVELOPER_API_BASE_URL}${WELL_KNOWN_CONFIG_PATH}`);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else if (response.status >= 400 && response.status < 500) {
      return Promise.reject({ globalError: true, status: CUSTOM_AUTH_STATUS.REQUEST_ERROR });
    } else {
      throw new Error('Failed to fetch OpenID well known config');
    }
  } catch (error) {
    return Promise.reject({ globalError: true, status: CUSTOM_AUTH_STATUS.INTERNAL_SERVER_ERROR });
  }
};

export const verifyIdTokenAndGetPayload = async (idToken: string | null): Promise<ParsedIdTokenValues | null> => {
  if (idToken) {
    try {
      const wellKnownConfig = await getOpenIdWellKnownConfig();
      const JWKS = createRemoteJWKSet(new URL(wellKnownConfig.jwks_uri), {
        cacheMaxAge: 0,
      });
      /**
       * HTTPS protocol is required for jwt verify.
       * SubtleCrypto is used internally by jwtVerify.
       * SubtleCrypto is available only in secure contexts.
       * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto
       */

      const { payload } = await jwtVerify<ParsedIdTokenValues>(idToken, JWKS, {
        issuer: wellKnownConfig.issuer,
      });
      return payload;
    } catch (error) {
      /**
       * Show Request error if idToken is not valid
       */
      let errorStatus = CUSTOM_AUTH_STATUS.REQUEST_ERROR;
      if (error && typeof error === 'object' && 'status' in error) {
        const errorObj = error as CustomErrorType;
        errorStatus = errorObj.status;
      }
      return Promise.reject({ globalError: true, status: errorStatus });
    }
  }
  return null;
};
