/** @jsxImportSource @emotion/react */
import Typography from '@digital-hig/typography';
import CardSkeleton from 'common/components/CardSkeleton/CardSkeleton';
import authNamespaces from 'nameSpaces/authNameSpaces';
import { useTranslation } from 'react-i18next';

const LoggedOutScreen = () => {
  const { t } = useTranslation(authNamespaces.logout);
  return (
    <CardSkeleton
      heading={t('SIGNED_OUT_TITLE')}
      body={<Typography variant="body-copy-medium">{t('SIGNED_OUT_DESCRIPTION')}</Typography>}
    />
  );
};

export default LoggedOutScreen;
