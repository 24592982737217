import { LOCALE_MAPPING } from 'appConstants/common';
import { TRANSLATION_CUSTOM_EVENTS } from 'appConstants/customEvents';
import BrowserLanguageCodeMappings from 'configs/browserLanguageMappings';
import LanguageCodeMappings from 'configs/languageMappings';
import { i18n } from 'i18next';
import { useEffect, useState } from 'react';
import { Translations } from 'types';
import { getFallbackLocale } from 'utilities/uiLocalesUtils';

function useTranslationsByAppLanguage(i18Instance: i18n, locale: string, namespaces: Array<string>) {
  const [translations, setTranslations] = useState<Translations>();
  const localeCode = locale.replace('-', '_');
  const language =
    LanguageCodeMappings[localeCode.toLowerCase()] ||
    getFallbackLocale(locale, LOCALE_MAPPING.LANGUAGE) ||
    LanguageCodeMappings.en;
  document.documentElement.lang =
    BrowserLanguageCodeMappings[localeCode.toLowerCase()] ||
    getFallbackLocale(locale, LOCALE_MAPPING.BROWSER) ||
    BrowserLanguageCodeMappings.en;

  useEffect(() => {
    document.addEventListener(TRANSLATION_CUSTOM_EVENTS.TRANSLATIONS_CONTENT, (e: Event) => {
      /**
       * If no translations present then this means recieving translations for the first time.
       * Hence we can update the translations.
       */
      if (!translations) {
        setTranslations((e as CustomEvent).detail);
      }
    });
    /**
     * Dispatch SEND_TRANSLATION_CONTENT in case we missed TRANSLATIONS_CONTENT event from
     * translations bundle. This will cause the Translation bundle to re-trigger TRANSLATIONS_CONTENT
     */
    const event = new CustomEvent(TRANSLATION_CUSTOM_EVENTS.SEND_TRANSLATION_CONTENT);
    document.dispatchEvent(event);
  }, []);

  useEffect(() => {
    if (translations) {
      try {
        namespaces.forEach((namespace: string) => {
          if (!i18Instance.hasResourceBundle(language, namespace)) {
            i18Instance.addResourceBundle(language, namespace, translations[language][namespace]);
          }
          if (language !== 'root') {
            i18Instance.addResourceBundle('en', namespace, translations['root'][namespace]);
          }
        });
        i18Instance.changeLanguage(language);
      } catch (error) {
        throw new Error(`An error occured while loading resource for language: ${language}
            ${error}`);
      }
    }
  }, [translations, locale]);
}

export { useTranslationsByAppLanguage };
