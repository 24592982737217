import {
  CUSTOM_AUTH_STATUS,
  EMAIL_ERRORS,
  GLOBAL_ERRORS,
  NAME_ERRORS,
  NAME_SERVER_ERRORS,
  OTP_ERRORS,
  PASSWORD_ERRORS,
} from 'appConstants';
import { MAX_PASSWORD_ALLOWED_LENGTH } from 'appConstants/createAccount';
import { HCAPTCHA_ERRORS } from 'appConstants/hCaptcha';
import authNamespaces from 'nameSpaces/authNameSpaces';
import { ApiError, AuthError, AuthErrorDetail, ValidationResult } from 'types';
import { isValidEmail } from './validation';

type ErrorMap = { [x: string]: string };

const getMessagesFromErrorDetails = (errDetails: AuthErrorDetail[], errorMap: ErrorMap): Array<string> => {
  return errDetails.map((errDetail) => errorMap[errDetail.code]);
};

const EmailErrorMap: ErrorMap = {
  [EMAIL_ERRORS.ACCOUNT_NOT_FOUND]: 'USER_NOT_FOUND',
  [EMAIL_ERRORS.INVALID_REQUEST]: 'INVALID_EMAIL',
  [EMAIL_ERRORS.INVALID_EMAIL]: 'INVALID_EMAIL',
  [EMAIL_ERRORS.EMAIL_MISSING]: 'EMAIL_MISSING',
  [EMAIL_ERRORS.USER_NOT_FOUND]: 'USER_NOT_FOUND',
  [EMAIL_ERRORS.OTP_SENDING_FAILURE]: 'OTP_SENDING_FAILURE',
  [EMAIL_ERRORS.EXCEEDED_SEND_ATTEMPTS]: 'EXCEEDED_SEND_ATTEMPTS',
  [EMAIL_ERRORS.ACCOUNT_INACTIVE]: 'ACCOUNT_INACTIVE',
  [EMAIL_ERRORS.RESTRICTED_EMAIL]: 'EMAIL_DOMAIN_RESTRICTED_SIGN_IN',
  [EMAIL_ERRORS.ACCOUNT_IS_BEING_DELETED]: 'ACCOUNT_IS_BEING_DELETED',
  [EMAIL_ERRORS.ACCOUNT_QUEUED_FOR_DELETION]: 'ACCOUNT_QUEUED_FOR_DELETION',
  [EMAIL_ERRORS.DUPLICATE_EMAIL]: 'DUPLICATE_EMAIL',
};

const CreateAccountEmailErrorMap: ErrorMap = {
  ...EmailErrorMap,
  [EMAIL_ERRORS.RESTRICTED_EMAIL]: 'EMAIL_DOMAIN_RESTRICTED',
};

const PasswordErrorMap: ErrorMap = {
  // will return translation key from here once localisation implemented
  [PASSWORD_ERRORS.INVALID_ACCOUNT]: 'INVALID_ACCOUNT',
  [PASSWORD_ERRORS.PASSWORD_MISSING]: 'PASSWORD_MISSING',
  [PASSWORD_ERRORS.INVALID_USER_CREDENTIALS]: 'INVALID_USER_CREDENTIALS',
  [PASSWORD_ERRORS.PASSWORDS_MUST_MATCH]: 'PASSWORDS_MUST_MATCH',
  [PASSWORD_ERRORS.CONFIRM_THE_NEW_PASSWORD]: 'CONFIRM_THE_NEW_PASSWORD',
  [PASSWORD_ERRORS.PASSWORD_DISALLOWED_BY_POLICY]: 'PASSWORD_DISALLOWED_BY_POLICY',
  [PASSWORD_ERRORS.PASSWORD_CONTAINS_EMAIL]: 'PASSWORD_DISALLOWED_BY_POLICY',
  [PASSWORD_ERRORS.PASSWORD_PREVIOUSLY_USED]: 'PASSWORD_PREVIOUSLY_USED',
};

const OtpErrorMap: ErrorMap = {
  // will return translation key from here once localisation implemented
  [OTP_ERRORS.INVALID_OTP_FORMAT]: 'FIELD_REQUIRED',
  [OTP_ERRORS.INVALID_OTP]: 'WRONG_OTP', // MFA endpoint returns INVALID_OTP for wrong or expired otp
  [OTP_ERRORS.EXPIRED_OTP]: 'EXPIRED_OTP',
  [OTP_ERRORS.EXCEEDED_SEND_ATTEMPTS]: 'EXCEEDED_SEND_ATTEMPTS',
  [OTP_ERRORS.EXCEEDED_OTP_ATTEMPTS]: 'EXCEEDED_OTP_ATTEMPTS',
  [OTP_ERRORS.FIELD_REQUIRED]: 'FIELD_REQUIRED',
  [OTP_ERRORS.OTP_SENDING_FAILURE]: 'OTP_SENDING_FAILURE',
  [OTP_ERRORS.OTP_CHECKING_FAILURE]: 'OTP_CHECKING_FAILURE',
  [OTP_ERRORS.WRONG_OTP]: 'WRONG_OTP',
  [OTP_ERRORS.OTP_MISSING]: 'FIELD_REQUIRED',
};

const ComponentMap: { [x: string]: ErrorMap } = {
  [authNamespaces.email]: EmailErrorMap,
  [authNamespaces.password]: PasswordErrorMap,
  [authNamespaces.otp]: OtpErrorMap,
  [authNamespaces.createAccount]: CreateAccountEmailErrorMap,
};

export const validateEmail = (email: string) => {
  let errorMessage = '';
  if (email.trim() === '') {
    errorMessage = EmailErrorMap[EMAIL_ERRORS.EMAIL_MISSING];
  } else if (!isValidEmail(email)) {
    errorMessage = EmailErrorMap[EMAIL_ERRORS.INVALID_EMAIL];
  }
  return errorMessage;
};

export const validatePassword = (password: string) => {
  const validationResult = {
    isValid: true,
    errorMessage: '',
  } as ValidationResult;
  if (password.trim() === '') {
    validationResult.isValid = false;
    validationResult.errorMessage = PasswordErrorMap[PASSWORD_ERRORS.PASSWORD_MISSING];
  } else if (password.length > MAX_PASSWORD_ALLOWED_LENGTH) {
    validationResult.isValid = false;
    validationResult.errorMessage = PasswordErrorMap[PASSWORD_ERRORS.PASSWORD_DISALLOWED_BY_POLICY];
  }
  return validationResult;
};

export const validateTotp = (totp: string) => {
  const validationTotpResult = {
    isValid: true,
    errorMessage: '',
  } as ValidationResult;
  if (totp.length !== 6 || totp.includes(' ')) {
    validationTotpResult.isValid = false;
    validationTotpResult.errorMessage = OtpErrorMap[OTP_ERRORS.FIELD_REQUIRED];
  }
  return validationTotpResult;
};

export const getErrorMessages = (errorObj: AuthError, componentType: string): Array<string> => {
  let errorMessages = [] as Array<string>;
  if (!errorObj.details) {
    errorMessages[0] = ComponentMap[componentType][errorObj.code];
  } else {
    errorMessages = getMessagesFromErrorDetails(errorObj.details, ComponentMap[componentType]);
  }
  return errorMessages;
};

export const getSubmitUserInfoErrorMessages = (errorDetails: AuthErrorDetail[]) => {
  let firstNameError = '';
  let lastNameError = '';
  let passwordError = '';
  errorDetails.forEach(({ code }) => {
    if (code === NAME_SERVER_ERRORS.FIRSTNAME_RESTRICTED_WORD) {
      firstNameError = NAME_ERRORS.firstName.CONTAINS_BLOCKED_WORDS;
    }
    if (code === NAME_SERVER_ERRORS.LASTNAME_RESTRICTED_WORD) {
      lastNameError = NAME_ERRORS.lastName.CONTAINS_BLOCKED_WORDS;
    }
    if (code === PASSWORD_ERRORS.PASSWORD_DISALLOWED_BY_POLICY) {
      passwordError = PASSWORD_ERRORS.PASSWORD_DISALLOWED_BY_POLICY;
    }
  });
  return { firstNameError, lastNameError, passwordError };
};

export const getErrorStatusForGlobalError = (error: ApiError, authorizeUrl?: string) => {
  if (error.code === GLOBAL_ERRORS.RESOURCE_NOT_FOUND) {
    return authorizeUrl ? CUSTOM_AUTH_STATUS.SESSION_ENDED : CUSTOM_AUTH_STATUS.FORBID_ENTRY;
  } else if (error.code === GLOBAL_ERRORS.SSO_LOGIN_ERROR) {
    const ssoErrorStatus = error.details?.[0]?.code ?? CUSTOM_AUTH_STATUS.UNABLE_TO_SIGN_IN_SSO;
    return ssoErrorStatus;
  } else if (error.error === HCAPTCHA_ERRORS.INVALID_HCAPTCHA || error.error === HCAPTCHA_ERRORS.HCAPTCHA_REQUIRED) {
    return CUSTOM_AUTH_STATUS.REQUEST_ERROR;
  }
  return CUSTOM_AUTH_STATUS.INTERNAL_SERVER_ERROR;
};

export const validateConfirmPassword = (newPassword: string, confirmPassword: string) => {
  if (!confirmPassword) {
    return PasswordErrorMap[PASSWORD_ERRORS.CONFIRM_THE_NEW_PASSWORD];
  }
  if (newPassword !== confirmPassword) {
    return PasswordErrorMap[PASSWORD_ERRORS.PASSWORDS_MUST_MATCH];
  }
  return '';
};
