import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';
import { css } from '@emotion/react';

const OptOutBannerStyles = css`
  width: 100%;
  z-index: 2;
  position: relative;
  background: ${lightTheme.primitives.colors.accent.iris};
  border-radius: 0;
  p {
    color: ${lightTheme.tokens.colors.background} !important;
  }
  a {
    color: ${lightTheme.tokens.colors.background} !important;
    text-decoration-color: ${lightTheme.tokens.colors.background} !important;
  }
  svg {
    margin: auto;
  }
`;

export { OptOutBannerStyles };
