const AUTH_DEVICES = {
  TOTP: {
    type: 'TOTP',
    heading: '2_STEP_VERIFICATION',
    bodyText: '2_STEP_VERIFICATION_AUTHENTICATOR_DESCRIPTION',
  },
  Email: {
    type: 'Email',
    heading: 'ENTER_CODE',
    bodyText: 'ENTER_EMAIL_OTP_DESCRIPTION',
  },
  SMS: {
    type: 'SMS',
    heading: '2_STEP_VERIFICATION',
    bodyText: '2_STEP_VERIFICATION_SMS_DESCRIPTION',
  },
};

const DEVICE_TYPES_WITH_RESEND = [AUTH_DEVICES.SMS.type, AUTH_DEVICES.Email.type];

export { AUTH_DEVICES, DEVICE_TYPES_WITH_RESEND };
