import {
  MAX_DOMAIN_PART_EMAIL_ALLOWED_LENGTH,
  MAX_LOCAL_PART_EMAIL_ALLOWED_LENGTH,
  NAME_ALLOWED_BYTE_LENGTH,
  NAME_ALLOWED_CHARACTER_LENGTH,
} from 'appConstants/createAccount';
import { NAME_ERRORS } from 'appConstants/error';
import { PASSWORD_HINTS } from 'appConstants/passwordHints';

export const isValidEmail = (email: string) => {
  const validEmailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [localPart, domainPart] = email.split('@');
  if (
    !validEmailRegex.test(email.trim()) ||
    localPart.length > MAX_LOCAL_PART_EMAIL_ALLOWED_LENGTH ||
    domainPart.length > MAX_DOMAIN_PART_EMAIL_ALLOWED_LENGTH
  ) {
    return false;
  }

  return true;
};

export const validateUniqueCharacters = (value: string, minUniqueCharactersRequired: number) => {
  const uniqueCharacters = new Set(value);
  return uniqueCharacters.size >= minUniqueCharactersRequired;
};

export const isDigit = (char: string) => /^\d+$/.test(char);

export const hasWhiteSpace = (value: string) => /\s/.test(value);

export const validatePassword = (password: string) => {
  let isPasswordValid = true;
  let isHintError = false;
  const updatedFields = PASSWORD_HINTS.map((hint) => {
    const isValid = hint.validator(password);
    if (!isValid) {
      isPasswordValid = false;
      if (!isHintError) {
        isHintError = hint.showHint;
      }
    }
    return { ...hint, isValid };
  });
  return { isPasswordValid, updatedFields, isHintError };
};

export const getByteLength = (value: string) => new Blob([value]).size;

export const validateNames = (name: string, type: string) => {
  // The below regex checks for following unsupported characters
  // Characters in unicode index range (65529-65535 and 55296-57343)
  // Characters from 0-9, <, >, /, %, &#
  const unSupportedCharacters = /[\ufff9-\uffff\ud800-\udfff\u0030-\u0039]|<|>|\/|%|(&#)+/;

  // The below regex checks sql injection
  // Pattern to detect dangerous SQL keywords and patterns
  const sqlKeywordsPattern =
    /(exec|union|select|insert|drop|update|delete|alter|create|shutdown|truncate|grant|revoke)\s+\S+/i;
  const sqlCommentPattern = /(--|\/\*|\*\/|;|'|xp_)/i;

  // The below regex works by checking that the string should contain at least one character that is not in the specified ranges
  // Can't directly check for it to have atleast one char from range A-Za-z because it should allow caharcters from other languages also
  const atLeastOneLetter = /[^☺☻♥♦♣♠•◘○◙♂♀♪♫☼►◄↕‼¶§▬↨↑↓→←∟↔▲▼\x20-\x40\x5b-\x60\x7b-\x7f]/;
  if (!name) {
    return NAME_ERRORS[type].FIELD_REQUIRED;
  } else if (name.length > NAME_ALLOWED_CHARACTER_LENGTH || getByteLength(name) > NAME_ALLOWED_BYTE_LENGTH) {
    return NAME_ERRORS[type].EXCEEDED_MAX_LENGTH;
  } else if (unSupportedCharacters.test(name) || sqlKeywordsPattern.test(name) || sqlCommentPattern.test(name)) {
    return NAME_ERRORS[type].CONTAINS_UNSUPPORTED_CHARACTERS;
  } else if (!atLeastOneLetter.test(name)) {
    return NAME_ERRORS[type].MUST_CONTAIN_ONE_LETTER;
  }
  return '';
};
