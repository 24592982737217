import { css } from '@emotion/react';
import normalize from 'normalize.css';

/* Fade out and flow wrapper classes have initial opacity set to 0.01 instead of 0 to avoid 
   Lighthouse breaking on capturing LCP, resulting in broken performance report.
   Refer - https://github.com/GoogleChrome/lighthouse/issues/10869#issuecomment-1194357955
*/

const globalStyles = css`
  ${normalize}

  body,
  body * {
    box-sizing: border-box;
    font-family: ArtifaktElement, Arial, sans-serif;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  /* Make anchors inline-block always to avoid anchor text wrapping */
  a {
    display: inline-block !important;
  }

  .flow-wrapper {
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0.01;
  }

  .flow-wrapper.translate-left,
  .flow-wrapper.translate-right {
    transition: none;
  }

  .flow-wrapper.fade-in-left,
  .flow-wrapper.fade-in-right,
  .flow-wrapper.fade-out-left,
  .flow-wrapper.fade-out-right {
    transition: transform 0.3s, opacity 0.3s;
  }

  .flow-wrapper.translate-left {
    transform: translateX(-20%);
  }

  .flow-wrapper.translate-right {
    transform: translateX(20%);
  }

  .flow-wrapper.fade-in-left {
    opacity: 1;
    transform: translateX(20%);
  }

  .flow-wrapper.fade-in-right {
    opacity: 1;
    transform: translateX(0);
  }

  .flow-wrapper.fade-out-left {
    opacity: 0.01;
    transform: translateX(-20%);
  }

  .flow-wrapper.fade-out-right {
    opacity: 0.01;
    transform: translateX(20%);
  }

  .form-container button[type='submit'] {
    margin-top: 2em;
  }

  @keyframes MuiCircularProgress-keyframes-circular-rotate {
    0% {
      transform-origin: 50% 50% 0px;
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default globalStyles;
