/** @jsxImportSource @emotion/react */
import { Hidden as SvgHidden, Visible as SvgVisible } from '@digital-hig/icon/lib/esm/icons';
import Collapse from '@mui/material/Collapse';
import { EVENT_TEXT } from 'appConstants/analytics';
import { COLLAPSE_TRANSITION_TIMEOUT } from 'appConstants/common';
import { PWD_POLICY_URL } from 'appConstants/links';
import { PASSWORD_HINTS } from 'appConstants/passwordHints';
import CustomInput from 'common/components/CustomInput/CustomInput';
import PasswordHint from 'common/components/PasswordHint/PasswordHint';
import authNamespaces from 'nameSpaces/authNameSpaces';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PasswordInputProps } from 'types/PasswordInput';
import { trackClick } from 'utilities/tealiumAnalytics';
import { validatePassword } from 'utilities/validation';
import { passwordInputStyle } from './PasswordInputStyles';

const PasswordInput = ({
  value,
  onChange,
  id,
  disabled,
  error,
  label,
  formControlProps,
  autoComplete,
  showHidePasswordLinkLocation,
  showHidePasswordLinkText,
  autoFocus = false,
  showHints = false, // Controls whether to show password hints component or not
  showInlineError = true, // Controls whether to show error message below input field or show it in password hint as validations
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordHints, setShowPasswordHints] = useState(false);
  const [showPasswordValidations, setShowPasswordValidations] = useState(false);
  const [passwordHints, setPasswordHints] = useState(PASSWORD_HINTS);
  const isFirstRenderRef = useRef(true);
  const { t } = useTranslation([authNamespaces.password]);

  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }
    // Execute validation logic only when showHints is true which means the field has password hints
    if (showHints) {
      // If showInlineError is true then no need show password hints and do all validations
      if (showInlineError) {
        return;
      }
      const { isPasswordValid, updatedFields, isHintError } = validatePassword(value);
      const shouldShowPasswordHints = !isPasswordValid && isHintError;
      setPasswordHints(updatedFields);
      setShowPasswordHints(shouldShowPasswordHints);
      // Update showPasswordValidations only when password is not valid and it is hint error, once password is valid then it is not needed.
      // This is also done to prevent intermediate circles coming when password becomes valid from invalid
      if (shouldShowPasswordHints) {
        setShowPasswordValidations(true);
      }
    }
  }, [value, error, showHints, showInlineError]);

  const handleInputFocus = (_e: React.FocusEvent<HTMLInputElement>) => {
    // Since password doesn't exist, show password hint without validation i.e with plain circle icons
    if (!value) {
      setShowPasswordHints(true);
    }
  };

  const handleInputBlur = (_e: React.FocusEvent<HTMLInputElement>) => {
    // When showPasswordValidations is false, it means user has not entered any password yet
    // In this case hide the password hints on blur event, for all other cases show password hints
    if (!showPasswordValidations) {
      setShowPasswordHints(false);
    }
  };

  const handleShowHidePassword = () => {
    setShowPassword((show: boolean) => !show);
    trackClick({
      link_location: showHidePasswordLinkLocation,
      link_text: `${showPassword ? EVENT_TEXT.HIDE : EVENT_TEXT.SHOW} ${showHidePasswordLinkText}`,
    });
  };

  return (
    <>
      <CustomInput
        id={id}
        key={id}
        name={id}
        value={value}
        label={label}
        type={showPassword ? 'text' : 'password'}
        errorMessage={error}
        showErrorMessage={showInlineError}
        errorLinkTarget={PWD_POLICY_URL}
        disabled={disabled}
        onChange={onChange}
        autoFocus={autoFocus}
        fullWidth
        showEndAdornment
        required
        formControlProps={formControlProps}
        css={passwordInputStyle}
        endAdornmentProps={{
          id: `${id}-show-hide-btn`,
          ariaLabel: t(showPassword ? 'HIDE_PASSWORD' : 'SHOW_PASSWORD'),
          icon: showPassword ? <SvgHidden /> : <SvgVisible />,
          onClick: handleShowHidePassword,
        }}
        {...(showHints && {
          onFocus: handleInputFocus,
          onBlur: handleInputBlur,
          'aria-describedby': `${id}-hints`,
        })}
        {...(autoComplete && {
          autoComplete,
        })}
      />
      {showHints && (
        <Collapse in={showPasswordHints} timeout={COLLAPSE_TRANSITION_TIMEOUT}>
          <PasswordHint
            id={`${id}-hints`}
            showPasswordValidations={showPasswordValidations}
            passwordHints={passwordHints}
            showPasswordHints={showPasswordHints}
          />
        </Collapse>
      )}
    </>
  );
};

export default PasswordInput;
