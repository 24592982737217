/** @jsxImportSource @emotion/react */
import { AUTH_STATUS, CUSTOM_AUTH_STATUS } from 'appConstants/auth';
import { FADE_TIMEOUT, TRANSITION_CONFIG } from 'appConstants/common';
import { NAVIGATION_DIRECTIONS, ROUTES } from 'appConstants/routing';
import LoadingSkeleton from 'common/components/LoadingSkeleton/LoadingSkeleton';
import CreateAccountEmailScreen from 'components/CreateAccountScreens/CreateAccountEmailScreen/CreateAccountEmailScreen';
import ProductSignOutScreen from 'components/IDSDKscreens/ProductSignOutScreen/ProductSignOutScreen';
import LoggedOutScreen from 'components/LogoutScreens/LoggedOutScreen/LoggedOutScreen';
import LogoutScreen from 'components/LogoutScreens/LogOutScreen/LogoutScreen';
import PasswordScreen from 'components/PasswordScreen/PasswordScreen';
import SignInEmailScreen from 'components/SignInEmailScreen/SignInEmailScreen';
import AppContext from 'context/appContext';
import AuthContext from 'context/authContext';
import authNamespaces from 'nameSpaces/authNameSpaces';
import { memo, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyticsStatusData } from 'types';
import { LazyLoadedComponentsObject } from 'types/LazyLoad';
import { getSelectedDeviceType, withRetry } from 'utilities/commonUtils';
import { loadComponentLazily } from 'utilities/lazyLoad';
import { getTitleAndNameSpaceFromStatus, updatePageTitle } from 'utilities/pageTitleUtils';
import { trackPageViewBasedOnStatus } from 'utilities/tealiumAnalytics';

const ComponentMap = () => {
  const auth = useContext(AuthContext);
  const { uiLocale } = useContext(AppContext);
  const firstLoad = useRef(true);
  const [currentStatus, setCurrentStatus] = useState(auth.flowStatus);
  const [cssClassName, setCssClassName] = useState('');
  const lazyLoadedComponents = useRef<LazyLoadedComponentsObject>({});
  const { authCallbackResponse } = useContext(AuthContext);

  const { t } = useTranslation([
    authNamespaces.common,
    authNamespaces.mfa,
    authNamespaces.termsOfService,
    authNamespaces.createAccount,
    authNamespaces.otp,
    authNamespaces.success,
    authNamespaces.logout,
    authNamespaces.error,
    authNamespaces.password,
  ]);

  const getOtpScreen = (isEmailVerification: boolean) => {
    if (isEmailVerification) {
      return lazyLoadedComponents.current.VerifyEmailOtpScreen && <lazyLoadedComponents.current.VerifyEmailOtpScreen />;
    } else if (auth.isCCPA) {
      return lazyLoadedComponents.current.CcpaOtpScreen && <lazyLoadedComponents.current.CcpaOtpScreen />;
    }
    return lazyLoadedComponents.current.MFALandingScreen && <lazyLoadedComponents.current.MFALandingScreen />;
  };

  const getComponent = () => {
    const className = `flow-wrapper ${currentStatus.status} ${cssClassName}`;

    switch (currentStatus.status) {
      case AUTH_STATUS.EMAIL_REQUIRED:
        return (
          <div className={className}>
            <SignInEmailScreen />
          </div>
        );
      case AUTH_STATUS.EMAIL_VERIFICATION_REQUIRED:
        return (
          <div className={className}>
            <CreateAccountEmailScreen />
          </div>
        );
      case AUTH_STATUS.USER_INFO_REQUIRED:
        return (
          <>
            {lazyLoadedComponents.current.CreateAccountRegisterUserScreen && (
              <div className={className}>
                <lazyLoadedComponents.current.CreateAccountRegisterUserScreen />
              </div>
            )}
          </>
        );
      case AUTH_STATUS.OTP_REQUIRED:
        return <div className={className}>{getOtpScreen(false)}</div>;
      case AUTH_STATUS.EMAIL_OTP_REQUIRED:
      case AUTH_STATUS.RESET_PASSWORD_OTP_REQUIRED:
        return <div className={className}>{getOtpScreen(true)}</div>;
      case AUTH_STATUS.PASSWORD_REQUIRED:
        return (
          <div className={className}>
            <PasswordScreen />
          </div>
        );
      case AUTH_STATUS.PASSWORD_UPDATE_REQUIRED:
        return (
          <>
            {lazyLoadedComponents.current.ResetPasswordScreen && (
              <div className={className}>
                <lazyLoadedComponents.current.ResetPasswordScreen />
              </div>
            )}
          </>
        );
      case AUTH_STATUS.VERIFICATION_REQUIRED:
        return (
          <>
            {lazyLoadedComponents.current.MFALandingScreen && (
              <div className={className}>
                <lazyLoadedComponents.current.MFALandingScreen />
              </div>
            )}
          </>
        );
      case AUTH_STATUS.TOS_REQUIRED:
        return (
          <>
            {lazyLoadedComponents.current.TermsOfServiceScreen && (
              <div className={className}>
                <lazyLoadedComponents.current.TermsOfServiceScreen />
              </div>
            )}
          </>
        );
      case CUSTOM_AUTH_STATUS.TOTP_AND_SMS_MAX_ATTEMPTS_LIMIT:
        return (
          <>
            {lazyLoadedComponents.current.MaxRetriesReachedScreen && (
              <div className={className}>
                <lazyLoadedComponents.current.MaxRetriesReachedScreen />
              </div>
            )}
          </>
        );
      case CUSTOM_AUTH_STATUS.EMAIL_VERIFICATION_SUCCESSFUL:
      case CUSTOM_AUTH_STATUS.CREATE_ACCOUNT_SUCCESS:
      case CUSTOM_AUTH_STATUS.PASSWORD_RESET_SUCCESS:
        return (
          <>
            {lazyLoadedComponents.current.SuccessScreen && (
              <div className={className}>
                <lazyLoadedComponents.current.SuccessScreen status={currentStatus.status} />
              </div>
            )}
          </>
        );
      case CUSTOM_AUTH_STATUS.EMAIL_OTP_MAX_ATTEMPTS_LIMIT:
      case CUSTOM_AUTH_STATUS.EMAIL_RESEND_MAX_ATTEMPTS_LIMIT:
      case CUSTOM_AUTH_STATUS.CREATE_ACCOUNT_LOGIN_DISABLED:
      case AUTH_STATUS.MFA_FAILED:
      case CUSTOM_AUTH_STATUS.SIGN_IN_REQUEST_EXPIRED:
        return (
          <>
            {lazyLoadedComponents.current.ErrorWithAlert && (
              <div className={className}>
                <lazyLoadedComponents.current.ErrorWithAlert />
              </div>
            )}
          </>
        );

      case CUSTOM_AUTH_STATUS.LOGOUT:
        return (
          <div className={className}>
            <LogoutScreen />
          </div>
        );

      case CUSTOM_AUTH_STATUS.PRODUCT_SIGNED_IN:
        return (
          <>
            {lazyLoadedComponents.current.ProductSignedInScreen && (
              <div className={className}>
                <lazyLoadedComponents.current.ProductSignedInScreen status={currentStatus.status} />
              </div>
            )}
          </>
        );

      case CUSTOM_AUTH_STATUS.OPEN_PRODUCT:
        return (
          <>
            {lazyLoadedComponents.current.OpenProductScreen && (
              <div className={className}>
                <lazyLoadedComponents.current.OpenProductScreen productUrl={currentStatus.payload?.productUrl ?? ''} />
              </div>
            )}
          </>
        );

      case CUSTOM_AUTH_STATUS.PRODUCT_SIGN_OUT:
        return (
          <div className={className}>
            <ProductSignOutScreen />
          </div>
        );

      case CUSTOM_AUTH_STATUS.LOGGED_OUT:
        return (
          <div className={className}>
            <LoggedOutScreen />
          </div>
        );

      case CUSTOM_AUTH_STATUS.FORBID_ENTRY:
      case CUSTOM_AUTH_STATUS.INTERNAL_SERVER_ERROR:
      case CUSTOM_AUTH_STATUS.PAGE_NOT_FOUND:
      case CUSTOM_AUTH_STATUS.REQUEST_ERROR:
      case CUSTOM_AUTH_STATUS.SESSION_ENDED:
      case CUSTOM_AUTH_STATUS.SAML_ASSERTION_ERROR:
      case CUSTOM_AUTH_STATUS.CONNECTION_NOT_FOUND:
      case CUSTOM_AUTH_STATUS.USER_DEACTIVATED:
      case CUSTOM_AUTH_STATUS.UNABLE_TO_SIGN_IN_SSO:
        return (
          <>
            {lazyLoadedComponents.current.GlobalError && (
              <div className={className}>
                <lazyLoadedComponents.current.GlobalError />
              </div>
            )}
          </>
        );

      case CUSTOM_AUTH_STATUS.DOMAIN_NOT_FOUND:
      case CUSTOM_AUTH_STATUS.JIT_DISABLED:
      case CUSTOM_AUTH_STATUS.DOMAIN_CONNECTION_MISMATCH:
      case CUSTOM_AUTH_STATUS.DOMAIN_DISABLED:
        return (
          <>
            {lazyLoadedComponents.current.GlobalError && (
              <div className={className}>
                <lazyLoadedComponents.current.GlobalError
                  dynamicMessagePlaceholder={{ username: authCallbackResponse?.error?.details?.[0]?.message ?? '' }}
                />
              </div>
            )}
          </>
        );

      case CUSTOM_AUTH_STATUS.SHOW_SKELETON:
      default:
        return (
          <div className={className}>
            <LoadingSkeleton />
          </div>
        );
    }
  };

  const trackPageViewData = () => {
    const selectedDeviceType = getSelectedDeviceType(auth.authDevices);
    const analyticsStatusData: AnalyticsStatusData = {
      flowStatus: auth.flowStatus.status,
      isCCPA: auth.isCCPA,
      flowType: auth.flowType,
      selectedDeviceType,
      uiLocale,
      uiPrompts: auth.uiPrompts,
      clientID: auth.clientID,
    };
    trackPageViewBasedOnStatus(analyticsStatusData);
  };

  useEffect(() => {
    (async () => {
      const flowStatus = auth.flowStatus;
      if (flowStatus.status !== currentStatus.status) {
        trackPageViewData();
        const {
          CLASSES: { translateLeft, translateRight, fadeOutLeft, fadeOutRight },
          DURATION,
        } = TRANSITION_CONFIG;
        // Lazily load the components with one retry in case of failure
        const lazilyLoadedComponentsWithRetry = withRetry(loadComponentLazily);
        try {
          lazyLoadedComponents.current = await lazilyLoadedComponentsWithRetry(
            lazyLoadedComponents.current,
            flowStatus.status,
            {
              isCCPA: auth.isCCPA,
              authDevices: auth.authDevices,
            },
            auth.flowType,
          );
        } catch (error) {
          // If scripts are unable to load, redirect the user to Request Error page as Global error page would also have to lazy load in order to render
          window.location.replace(ROUTES.REQUEST_ERROR);
        }

        // If it is first page load, then do fade in transition to left
        if (firstLoad.current) {
          setCurrentStatus(flowStatus);
          setCssClassName(translateLeft);
          firstLoad.current = false;
        } else {
          const isForward = flowStatus.dir === NAVIGATION_DIRECTIONS.FORWARD;
          setCssClassName(isForward ? fadeOutLeft : fadeOutRight);
          setTimeout(() => {
            setCurrentStatus(flowStatus);
            setCssClassName(isForward ? translateRight : translateLeft);
          }, DURATION);
        }
        const titleNameSpace = getTitleAndNameSpaceFromStatus(auth.flowStatus.status, auth.isCCPA, auth.flowType);
        updatePageTitle(t(titleNameSpace?.title, { ns: titleNameSpace?.nameSpace }));
      }
    })();
  }, [auth.flowStatus]);

  useEffect(() => {
    currentStatus.status &&
      // Adding timeout so as to add the fade in class after DOM is rendered (to show transition)
      setTimeout(() => {
        document.querySelector(`.${currentStatus.status}`)?.classList.add(TRANSITION_CONFIG.CLASSES.fadeInRight);
      }, FADE_TIMEOUT);
  }, [currentStatus.status]);

  return <>{getComponent()}</>;
};

export default memo(ComponentMap);
