import authNamespaces from '../nameSpaces/authNameSpaces';
import defaults from './defaults';

const I18nConfigs = {
  lng: defaults.LANG,
  fallbackLng: defaults.LANG,
  ns: Object.values(authNamespaces),
  defaultNS: '',
  debug: false,
  resources: {},
  returnNull: false,
  interpolation: {
    escapeValue: false,
  },
};

export default I18nConfigs;
