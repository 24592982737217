import authNamespaces from 'nameSpaces/authNameSpaces';
import { AUTH_STATUS, CUSTOM_AUTH_STATUS } from './auth';

const PAGE_TITLES = {
  TWO_FA: { title: '2_STEP_VERIFICATION', nameSpace: authNamespaces.mfa },
  SIGN_IN: { title: 'SIGN_IN', nameSpace: authNamespaces.common },
  CREATE_ACCOUNT: { title: 'CREATE_YOUR_ACCOUNT', nameSpace: authNamespaces.createAccount },
  LOGOUT: { title: 'SIGNING_OUT', nameSpace: authNamespaces.logout },
  LOGGED_OUT: { title: 'SIGNED_OUT', nameSpace: authNamespaces.logout },
  VERIFY_EMAIL: { title: 'VERIFY_YOUR_EMAIL', nameSpace: authNamespaces.otp },
  TERMS_OF_SERVICE: { title: 'TERMS_OF_USE_TEXT', nameSpace: authNamespaces.termsOfService },
  EMAIL_VERIFIED: { title: 'EMAIL_VERIFIED', nameSpace: authNamespaces.success },
  ACCOUNT_CREATED: { title: 'ACCOUNT_CREATED', nameSpace: authNamespaces.success },
  YOU_ARE_SIGNED_IN: { title: 'YOU_ARE_SIGNED_IN', nameSpace: authNamespaces.idsdk },
  REQUEST_ERROR: { title: 'REQUEST_ERROR', nameSpace: authNamespaces.error },
  PAGE_NOT_FOUND: { title: 'PAGE_NOT_FOUND', nameSpace: authNamespaces.error },
  INTERNAL_SERVER_ERROR: { title: 'INTERNAL_SERVER_ERROR', nameSpace: authNamespaces.error },
  SESSION_ENDED: { title: 'SESSION_ENDED', nameSpace: authNamespaces.error },
  UNABLE_TO_ACCESS_LINK: { title: 'UNABLE_TO_ACCESS_LINK', nameSpace: authNamespaces.error },
  RESET_PASSWORD: { title: 'RESET_PASSWORD', nameSpace: authNamespaces.password },
};

const TITLE_STATUS_MAP = {
  [AUTH_STATUS.EMAIL_REQUIRED]: PAGE_TITLES.SIGN_IN,
  [AUTH_STATUS.EMAIL_VERIFICATION_REQUIRED]: PAGE_TITLES.CREATE_ACCOUNT,
  [AUTH_STATUS.PASSWORD_REQUIRED]: PAGE_TITLES.SIGN_IN,
  [AUTH_STATUS.OTP_REQUIRED]: PAGE_TITLES.TWO_FA,
  [AUTH_STATUS.EMAIL_OTP_REQUIRED]: PAGE_TITLES.VERIFY_EMAIL,
  [AUTH_STATUS.AUTHENTICATION_REQUIRED]: PAGE_TITLES.TWO_FA,
  [AUTH_STATUS.VERIFICATION_REQUIRED]: PAGE_TITLES.TWO_FA,
  [AUTH_STATUS.TOS_REQUIRED]: PAGE_TITLES.TERMS_OF_SERVICE,
  [AUTH_STATUS.MFA_COMPLETED]: PAGE_TITLES.TWO_FA,
  [AUTH_STATUS.MFA_FAILED]: PAGE_TITLES.TWO_FA,
  [AUTH_STATUS.DEVICE_SELECTION_REQUIRED]: PAGE_TITLES.TWO_FA,
  [AUTH_STATUS.USER_INFO_REQUIRED]: PAGE_TITLES.CREATE_ACCOUNT,
  [AUTH_STATUS.RESET_PASSWORD_OTP_REQUIRED]: PAGE_TITLES.VERIFY_EMAIL,
  [AUTH_STATUS.PASSWORD_UPDATE_REQUIRED]: PAGE_TITLES.RESET_PASSWORD,
  [CUSTOM_AUTH_STATUS.TOTP_AND_SMS_MAX_ATTEMPTS_LIMIT]: PAGE_TITLES.TWO_FA,
  [CUSTOM_AUTH_STATUS.EMAIL_VERIFICATION_SUCCESSFUL]: PAGE_TITLES.EMAIL_VERIFIED,
  [CUSTOM_AUTH_STATUS.CREATE_ACCOUNT_SUCCESS]: PAGE_TITLES.ACCOUNT_CREATED,
  [CUSTOM_AUTH_STATUS.PASSWORD_RESET_SUCCESS]: PAGE_TITLES.RESET_PASSWORD,
  [CUSTOM_AUTH_STATUS.CREATE_ACCOUNT_LOGIN_DISABLED]: PAGE_TITLES.CREATE_ACCOUNT,
  [CUSTOM_AUTH_STATUS.SIGN_IN_REQUEST_EXPIRED]: PAGE_TITLES.SIGN_IN,
  [CUSTOM_AUTH_STATUS.OPEN_PRODUCT]: PAGE_TITLES.YOU_ARE_SIGNED_IN,
  [CUSTOM_AUTH_STATUS.PRODUCT_SIGNED_IN]: PAGE_TITLES.YOU_ARE_SIGNED_IN,
  [CUSTOM_AUTH_STATUS.PRODUCT_SIGN_OUT]: PAGE_TITLES.LOGOUT,
  [CUSTOM_AUTH_STATUS.REQUEST_ERROR]: PAGE_TITLES.REQUEST_ERROR,
  [CUSTOM_AUTH_STATUS.LOGOUT]: PAGE_TITLES.LOGOUT,
  [CUSTOM_AUTH_STATUS.LOGGED_OUT]: PAGE_TITLES.LOGGED_OUT,
  [CUSTOM_AUTH_STATUS.FORBID_ENTRY]: PAGE_TITLES.UNABLE_TO_ACCESS_LINK,
  [CUSTOM_AUTH_STATUS.SHOW_SKELETON]: PAGE_TITLES.SIGN_IN,
  [CUSTOM_AUTH_STATUS.PAGE_NOT_FOUND]: PAGE_TITLES.PAGE_NOT_FOUND,
  [CUSTOM_AUTH_STATUS.INTERNAL_SERVER_ERROR]: PAGE_TITLES.INTERNAL_SERVER_ERROR,
  [CUSTOM_AUTH_STATUS.SESSION_ENDED]: PAGE_TITLES.SESSION_ENDED,
  [CUSTOM_AUTH_STATUS.SAML_ASSERTION_ERROR]: PAGE_TITLES.SIGN_IN,
  [CUSTOM_AUTH_STATUS.DOMAIN_NOT_FOUND]: PAGE_TITLES.SIGN_IN,
  [CUSTOM_AUTH_STATUS.CONNECTION_NOT_FOUND]: PAGE_TITLES.SIGN_IN,
  [CUSTOM_AUTH_STATUS.DOMAIN_CONNECTION_MISMATCH]: PAGE_TITLES.SIGN_IN,
  [CUSTOM_AUTH_STATUS.DOMAIN_DISABLED]: PAGE_TITLES.SIGN_IN,
  [CUSTOM_AUTH_STATUS.JIT_DISABLED]: PAGE_TITLES.SIGN_IN,
  [CUSTOM_AUTH_STATUS.USER_DEACTIVATED]: PAGE_TITLES.SIGN_IN,
  [CUSTOM_AUTH_STATUS.UNABLE_TO_SIGN_IN_SSO]: PAGE_TITLES.SIGN_IN,
};

export { PAGE_TITLES, TITLE_STATUS_MAP };
