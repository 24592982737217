import VanilaHcaptcha from '@hcaptcha/react-hcaptcha';
import {
  AUTH_ACTIONS,
  AUTH_STATUS,
  CUSTOM_AUTH_STATUS,
  FLOW_STATUS_CUSTOM_EVENTS,
  FLOW_TYPE,
  GLOBAL_ERRORS,
  HISTORY_ACTIONS,
  NAVIGATION_DIRECTIONS,
  PRODUCT_SIGN_IN_EXPIRED_TIMEOUT_MS,
  ROUTES,
} from 'appConstants';
import { ERROR_CODES, ERROR_REASONS, ERROR_TYPE, PAGE_VIEWS } from 'appConstants/analytics';
import { ACTIONS_FOR_HCAPTCHA, HCAPTCHA_FLOWS } from 'appConstants/hCaptcha';
import HCaptcha, { executeHcaptcha } from 'common/components/HCaptcha/HCaptcha';
import { KeysOfHcaptchaFlows } from 'common/components/HCaptcha/HCaptcha.d';
import ComponentMap from 'components/ComponentMap/ComponentMap';
import AppContext from 'context/appContext';
import AuthContext from 'context/authContext';
import obs from 'logging/observability/observability';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  ApiError,
  AuthContextData,
  AuthDevices,
  AuthPayload,
  CallbackResponse,
  Flow,
  FlowResponse,
  StateUpdatingPromiseRef,
} from 'types';
import { getActionURL, getOptions } from 'utilities/apiUtils';
import {
  checkInviteUser,
  isESSOErrorFlow,
  shouldHandleBrowserBack,
  updateRoute,
  updateStateAndStateUpdatingPromiseRef,
} from 'utilities/commonUtils';
import { getErrorStatusForGlobalError } from 'utilities/errorUtils';
import {
  hCaptchaRetryHandler,
  isHcaptchaInvalidGlobalError,
  isHcaptchaRequired,
  isPassiveExplicitHcaptcha,
  isPassiveImplicitHcaptcha,
} from 'utilities/hCaptchaUtils';
import { deleteResendDataFromLocalStorage } from 'utilities/otpScreenUtils';
import { trackError } from 'utilities/tealiumAnalytics';
import { getUiLocale, getUiLocaleFromCookie } from 'utilities/uiLocalesUtils';

const Auth = () => {
  const appContext = useContext(AppContext);
  const [flowStatus, setFlowStatus] = useState<Flow>({
    status: '',
    dir: '',
  });

  const [userEmail, setUserEmail] = useState('');

  const [authCallbackResponse, setAuthCallbackResponse] = useState<CallbackResponse>({
    isSuccess: false,
  });

  const [profilePicture, setProfilePicture] = useState('');

  const [authorizeUrl, setAuthorizeUrl] = useState('');

  // This is temporary solution to unblock UI for create user flow
  // Remove this once backend sends the flow type in response.
  const flowTypeRef = useRef('');

  const [uiPrompts, setUiPrompts] = useState<Array<string>>([]);
  const [hCaptchaFlow, setHCaptchaFlow] = useState('');
  const hcaptchaRequired = useRef(false);

  const appContextRef = useRef(appContext);

  const isCCPARef = useRef(false);
  const hCaptchaRef = useRef<VanilaHcaptcha>(null);
  const hCaptchaPromiseRef = useRef<any>(null);
  const hCaptchaFlowUpdatingRef = useRef<StateUpdatingPromiseRef>({
    promise: null,
    resolve: null,
    latestUpdatedValue: '',
    stateUpdating: false,
  });
  const clientIDRef = useRef('');

  useEffect(() => {
    appContextRef.current = appContext;
  }, [appContext]);

  useEffect(() => {
    if (hCaptchaFlowUpdatingRef.current.resolve) {
      hCaptchaFlowUpdatingRef.current.resolve();
    }
    hCaptchaFlowUpdatingRef.current.stateUpdating = false;
    hCaptchaFlowUpdatingRef.current.latestUpdatedValue = hCaptchaFlow;
    hCaptchaFlowUpdatingRef.current.promise = null;
    hCaptchaFlowUpdatingRef.current.resolve = null;
  }, [hCaptchaFlow]);

  const authDevices = useRef<AuthDevices>({
    devices: [],
    selectedDeviceId: '',
  });

  const resumeAuthSuccessCallback = () => {
    if (authCallbackResponse?.response) {
      handleSuccess(authCallbackResponse.response);
    }
  };

  const handleStatus = (status: string, response: FlowResponse) => {
    const resumeUrl = response.resumeUrl;
    appContext.setShowOptOutBanner(false);

    // Set UI Prompts if available in the response otherwise set it to empty array
    // need to set empty array as default value for uiPrompts, otherwise incorrect value is set in the context
    // which will be used to render incorrect UI
    setUiPrompts(response.uiPrompt ? response.uiPrompt : []);

    // check if the user is invited user
    const isInviteUser = checkInviteUser(response.uiPrompt);

    switch (status) {
      // Set Profile Picture if available in the response
      case AUTH_STATUS.PASSWORD_REQUIRED:
        setProfilePicture(response.profilePicture ? response.profilePicture : '');
        /*pushstate addes an entry to the browser's session history stack so that user can go back to previous screen 
        as we want to enable user to go back to previous screen, we are using pushstate from password required screen
        */
        updateRoute(ROUTES.BASE, HISTORY_ACTIONS.PUSH);
        updateStateAndStateUpdatingPromiseRef(HCAPTCHA_FLOWS.SIGN_IN, setHCaptchaFlow, hCaptchaFlowUpdatingRef);
        break;

      // If user goes back to email screen, they might change their email address. So clear the profile picture.
      case AUTH_STATUS.EMAIL_REQUIRED:
        setProfilePicture('');
        appContext.setShowOptOutBanner(true);
        /* update the state object or URL of the current history entry, this is to replace the current history 
       entry with the new one and user cannot go forward */
        flowTypeRef.current = FLOW_TYPE.SIGN_IN;
        updateRoute(isCCPARef.current ? ROUTES.DOWNLOAD : ROUTES.BASE);
        break;

      case AUTH_STATUS.EMAIL_VERIFICATION_REQUIRED:
        flowTypeRef.current = isInviteUser ? FLOW_TYPE.INVITE : FLOW_TYPE.CREATE_ACCOUNT;
        updateStateAndStateUpdatingPromiseRef(
          HCAPTCHA_FLOWS.EMAIL_VERIFICATION,
          setHCaptchaFlow,
          hCaptchaFlowUpdatingRef,
        );
        if (!isInviteUser) appContext.setShowOptOutBanner(true);
        updateRoute(ROUTES.BASE);
        return;

      case AUTH_STATUS.DEVICE_SELECTION_REQUIRED:
        getFlow(null, AUTH_ACTIONS.CANCEL_AUTHENTICATION);
        return true;

      // This case handles response of checkPassword (when MFA is required)
      // and cancelAuthentication (invoked to change device from TOTP to Email)
      case AUTH_STATUS.AUTHENTICATION_REQUIRED:
        updateStateAndStateUpdatingPromiseRef(HCAPTCHA_FLOWS.MFA, setHCaptchaFlow, hCaptchaFlowUpdatingRef);
        getFlow(null, AUTH_ACTIONS.AUTHENTICATE, '', NAVIGATION_DIRECTIONS.FORWARD, false, HCAPTCHA_FLOWS.MFA);
        return true;

      // Get the current selected Device and store it in the ref for use in MFA flow
      case AUTH_STATUS.OTP_REQUIRED:
        // Presence of devices in response indicates that it is MFA flow
        if (response.devices && response.selectedDeviceRef) {
          authDevices.current = {
            devices: response.devices,
            selectedDeviceId: response.selectedDeviceRef?.id,
          };
          // This is to handle the case when user refreshes on OTP screen in MFA flow.
          // In this case we need to set HCaptcha flow before hand so that HCaptcha script is loaded before hand and is available for resend functionality
          updateStateAndStateUpdatingPromiseRef(HCAPTCHA_FLOWS.MFA, setHCaptchaFlow, hCaptchaFlowUpdatingRef);
        } else {
          // Action pushstate adds an entry to the browser's session history stack so that user can go back to previous screen
          // Browser's back button functionality on OTP screen for MFA flow is not required to take user to previous screen
          // OTP_REQUIRED and no devices implies it is OTP flow for CCPA
          updateRoute(ROUTES.DOWNLOAD, HISTORY_ACTIONS.PUSH);
        }
        break;
      case AUTH_STATUS.EMAIL_OTP_REQUIRED:
        // pushstate adds an entry to browser's session history stack to allow user to go back to previous screen
        updateRoute(ROUTES.BASE, HISTORY_ACTIONS.PUSH);
        // This is temporary, should go away once backend sends the flow type in response
        // Setting flow type on basis of previous status because EMAIL_OTP_REQUIRED status is part of Create user and Email verification flow
        if (flowStatus.status === AUTH_STATUS.EMAIL_REQUIRED) {
          flowTypeRef.current = FLOW_TYPE.EMAIL_VERIFICATION;
        }
        updateStateAndStateUpdatingPromiseRef(
          HCAPTCHA_FLOWS.EMAIL_VERIFICATION,
          setHCaptchaFlow,
          hCaptchaFlowUpdatingRef,
        );
        break;
      // Server sends this status once MFA is completed. Need to call continueAuthentication to complete the flow
      case AUTH_STATUS.MFA_COMPLETED:
        getFlow(null, AUTH_ACTIONS.CONTINUE_AUTHENTICATION);
        return true;

      case AUTH_STATUS.RESET_PASSWORD_OTP_REQUIRED:
        flowTypeRef.current = FLOW_TYPE.RESET_PASSWORD;
        updateStateAndStateUpdatingPromiseRef(
          HCAPTCHA_FLOWS.EMAIL_VERIFICATION,
          setHCaptchaFlow,
          hCaptchaFlowUpdatingRef,
        );
        break;

      /**
       * Set the HCaptcha flow to MFA beforehand.
       * This is necessary because in this flow, two different HCaptcha site configurations are used.
       * One for Email Verification & one for MFA
       * This is needed as after password update when API call is resumed on success screen setHcaptchaFlow and getflow are called parallely.
       * Because of this Hcaptcha doesn't get updated with new site configs.
       * As a result, we won't get the token, and the authenticate request will never be made, causing it to wait on the success screen.
       */
      case AUTH_STATUS.PASSWORD_UPDATE_REQUIRED:
        updateStateAndStateUpdatingPromiseRef(HCAPTCHA_FLOWS.MFA, setHCaptchaFlow, hCaptchaFlowUpdatingRef);
        break;

      case AUTH_STATUS.USER_INFO_REQUIRED:
        flowTypeRef.current = isInviteUser ? FLOW_TYPE.INVITE : FLOW_TYPE.CREATE_ACCOUNT;
        return;
      /* This is the final status we receive in the authentication flow.
      This status means the authentication is complete, take the user to their intended product/redirect URI */
      case AUTH_STATUS.RESUME:
        if (resumeUrl) {
          window.location.replace(resumeUrl);
        }
        return true;

      case CUSTOM_AUTH_STATUS.PRODUCT_SIGNED_IN:
        flowTypeRef.current = FLOW_TYPE.IDSDK;
        setFlowStatus({ status, dir: NAVIGATION_DIRECTIONS.FORWARD });
        setTimeout(() => {
          /**
           * In a setTimeout, a state property/ context does not use its current value and
           * will use the value it was initially called.
           * Therefore, we will use the useRef hook to retrieve the most recent value.
           */
          if (!appContextRef.current.globalError) {
            setFlowStatus({ status: CUSTOM_AUTH_STATUS.SIGN_IN_REQUEST_EXPIRED, dir: NAVIGATION_DIRECTIONS.FORWARD });
          }
        }, PRODUCT_SIGN_IN_EXPIRED_TIMEOUT_MS);
        return true;
    }

    return false;
  };

  const handleSuccess = (
    response: FlowResponse,
    dir = NAVIGATION_DIRECTIONS.FORWARD,
    showIntermediateScreen = false,
  ) => {
    const { status, email } = response;

    // Store the Authorize URL if available in response so as to reinitiate the login flow in case of session expiry
    if (response.authorizeUrl) {
      setAuthorizeUrl(response.authorizeUrl);
    }

    /* Set UI Locale if available in the response.
       Response will contain oidcUiLocales if authorize call was initiated with ui_locale parameter */
    if (response.requestContext?.oidcUiLocales) {
      appContext.setUiLocale(getUiLocale(response.requestContext.oidcUiLocales));
    }

    /**
     * This is to handle the case when the user is redirected to
     * an intermediate screen like email verification success screen.
     * In this case, we want to update the status and render the next screen
     * when the user clicks on continue.
     */
    if (showIntermediateScreen) {
      return;
    }

    const skipNextProcessing = handleStatus(status, response);

    if (skipNextProcessing) return;

    // If response contains email field, store it in context
    if (email) {
      setUserEmail(email);
    }

    setFlowStatus({
      status,
      dir,
    });
  };

  const handleError = (error: ApiError, retriesLeft?: number): boolean => {
    if ((error.code && error.code in GLOBAL_ERRORS) || isHcaptchaInvalidGlobalError(error, retriesLeft)) {
      // For all Global errors, show the internal server error screen
      // For Resource Not Found, show session ended screen when authorizeUrl exist else show forbid direct entry screen
      const errorStatus = getErrorStatusForGlobalError(error, authorizeUrl);
      setFlowStatus({
        status: errorStatus,
        dir: NAVIGATION_DIRECTIONS.FORWARD,
      });
      appContext.setGlobalError(true);
      if (isESSOErrorFlow(errorStatus)) {
        appContext.setUiLocale(getUiLocale(getUiLocaleFromCookie()));
      }

      // Implies we are showing server error screen
      return true;
    }
    // Implies we are not showing server error screen
    return false;
  };

  const parseError = (error: any) => {
    return {
      code: error.code ? error.code : GLOBAL_ERRORS.INTERNAL_SERVER_ERROR,
      message: error.message ? error.message : '',
      error: error.error ? error.error : '',
      error_description: error.error_description ? error.error_description : '',
      userMessage: error.userMessage ? error.userMessage : '',
      details: error.details,
    };
  };

  const handleGetFlowResponse = async (
    resp: Response,
    dir = NAVIGATION_DIRECTIONS.FORWARD,
    showIntermediateScreen = false,
    action?: string | null,
    retriesLeft?: number,
  ): Promise<CallbackResponse> => {
    // Use this in next getFlow call to determine if we need HCaptcha
    hcaptchaRequired.current = isHcaptchaRequired(resp);

    if (appContext.fetchingFlowStatus) {
      appContext.setFetchingFlowStatus(false);
    }
    const canResponseBeCloned = typeof resp.clone === 'function';
    if (resp.ok) {
      let response;
      try {
        response = canResponseBeCloned ? await resp.clone()?.json() : await resp.json();
      } catch (error) {
        response = {};
      }

      const requestContext = response?.requestContext;
      // Log Client Name and Client ID in New Relic from initial GET call
      if (!action && requestContext) {
        requestContext.clientId && obs.setCustomAttribute('ClientId', requestContext.clientId);
        requestContext.applicationName && obs.setCustomAttribute('ClientName', requestContext.applicationName);
        clientIDRef.current = requestContext.clientId;
        appContext.setClientDetails({
          clientID: requestContext?.clientId,
          status: response?.status,
          applicationName: requestContext?.applicationName,
        });
      }
      handleSuccess(response, dir, showIntermediateScreen);
      const successResponse = { isSuccess: true, response };
      setAuthCallbackResponse(successResponse);
      return successResponse;
    } else {
      const responseStatus = resp.status.toString();
      let err;
      try {
        err = canResponseBeCloned ? await resp.clone()?.json() : await resp.json();
      } catch (error) {
        err = {};
      }
      /**
       * We need to show error in the current screen only if
       * we have not scheduled forbid entry screen to be displayed.
       * Otherwise there will be a flash of error message being shown in the current screen
       * before the forbid entry screen is displayed.
       */
      const isForbidEntryScheduledToBeDisplayed = handleError(err, retriesLeft);
      const parsedErr = parseError(err);
      const errorResponse = { isSuccess: false, error: parsedErr, isForbidEntryScheduledToBeDisplayed, responseStatus };
      setAuthCallbackResponse(errorResponse);
      return errorResponse;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFlow = async (
    payload: AuthPayload | null,
    action: string | null,
    reauthUrl?: string,
    dir = NAVIGATION_DIRECTIONS.FORWARD,
    showIntermediateScreen = false,
    hCaptchaFlowType?: string,
    retriesLeft?: number,
  ): Promise<CallbackResponse> => {
    let options = null,
      url = '';
    // Making hCaptcha token call
    let hCaptchaToken: string | undefined = '';
    /**
     * Check if 'HCaptcha' verification is required based on the user action.
     * If the action is included in 'ACTIONS_FOR_HCAPTCHA', it verifies whether the site type is 'PASSIVE'.
     * If it's a 'PASSIVE' type or 'isHCaptchaRequired' is true, 'HCaptcha' is executed using the 'executeHcaptcha' function.
     */
    const hCaptchaBypassCookie = document.cookie.includes('x-tt=');
    if (action && ACTIONS_FOR_HCAPTCHA.includes(action) && !hCaptchaBypassCookie) {
      const isPassiveImplicit = isPassiveImplicitHcaptcha(hCaptchaFlowType);
      const isPassiveExplicit = isPassiveExplicitHcaptcha(hCaptchaFlowType, hcaptchaRequired.current);
      if (isPassiveImplicit || isPassiveExplicit) {
        // If hCaptcha flow is being updated, wait for it to finish updating before executing hCaptcha
        if (hCaptchaFlowUpdatingRef.current?.stateUpdating && hCaptchaFlowUpdatingRef.current?.promise) {
          await hCaptchaFlowUpdatingRef.current.promise;
        }
        // If HCaptcha script is not loaded, then wait for it to load
        if (!hCaptchaRef?.current?.state?.isApiReady) {
          try {
            await waitForHCaptchaScriptToLoad();
            hCaptchaToken = await executeHcaptcha(hCaptchaRef);
          } catch (error) {
            trackError({
              error_code: ERROR_CODES.SERVER_ERROR,
              error_reason: ERROR_REASONS.HCAPTCHA_FAILED_TO_LOAD,
              error_message: ERROR_REASONS.HCAPTCHA_FAILED_TO_LOAD,
              error_type: ERROR_TYPE.SERVER_SIDE_VALIDATION,
              error_location: PAGE_VIEWS.OTP_SCREEN,
            });
          }
        } else {
          hCaptchaToken = await executeHcaptcha(hCaptchaRef);
        }
      }
    }
    // If response contains authorize URL, store it so as to re-initiate the flow on idle timeout / session expiry
    if (reauthUrl) {
      options = getOptions(null, 'GET', false, hCaptchaToken);
      url = reauthUrl;
    } else {
      options = getOptions(payload, action ? 'POST' : 'GET', true, hCaptchaToken);
      url = getActionURL(action);
    }
    obs.setAttributeToCurrentInteraction('action', action);
    return fetch(url, options)
      .then(async (resp) => {
        const getFlowResponse = await handleGetFlowResponse(resp, dir, showIntermediateScreen, action, retriesLeft);
        return hCaptchaRetryHandler(
          getFlowResponse,
          getFlow,
          [payload, action, reauthUrl, dir, showIntermediateScreen, hCaptchaFlowType, retriesLeft],
          retriesLeft,
        );
      })
      .catch((err) => {
        if (appContext.fetchingFlowStatus) {
          appContext.setFetchingFlowStatus(false);
        }
        setFlowStatus({
          status: CUSTOM_AUTH_STATUS.INTERNAL_SERVER_ERROR,
          dir: NAVIGATION_DIRECTIONS.REVERSE,
        });
        appContext.setGlobalError(true);
        appContext.setShowOptOutBanner(false);
        const parsedErr = parseError(err);
        return { isSuccess: false, error: parsedErr };
      })
      .finally(() => {
        if (action === AUTH_ACTIONS.SEND_OTP) {
          window.sendingOtp = false;
          document.dispatchEvent(new CustomEvent('UPDATE_SENDING_OTP_STATUS'));
        }
      });
  };

  const authContextValue = useMemo<AuthContextData>(
    () => ({
      flowStatus,
      authCallBack: getFlow,
      userEmail,
      setUserEmail,
      updateStatus: setFlowStatus,
      isCCPA: isCCPARef.current,
      authCallbackResponse,
      profilePicture,
      authorizeUrl,
      authDevices: authDevices.current,
      resumeAuthSuccessCallback,
      flowType: flowTypeRef.current,
      clientID: clientIDRef.current,
      uiPrompts,
    }),
    [
      flowStatus,
      getFlow,
      userEmail,
      setUserEmail,
      setFlowStatus,
      isCCPARef,
      authCallbackResponse,
      profilePicture,
      authorizeUrl,
      authDevices,
      resumeAuthSuccessCallback,
      flowTypeRef,
      clientIDRef,
      uiPrompts,
    ],
  );

  const pathChange = () => {
    // Clearing email is not needed in CCPA flow
    if (isCCPARef.current) {
      setFlowStatus({ status: AUTH_STATUS.EMAIL_REQUIRED, dir: NAVIGATION_DIRECTIONS.REVERSE });
      return;
    }
    // Call clearEmail action for eligible screens
    getFlow(null, AUTH_ACTIONS.CLEAR_EMAIL, '', NAVIGATION_DIRECTIONS.REVERSE);
    deleteResendDataFromLocalStorage();
  };

  const getInitialStatus = async () => {
    // Show Skeleton loader if fetching initial flow status
    if (appContext.fetchingFlowStatus) {
      setFlowStatus({ status: CUSTOM_AUTH_STATUS.SHOW_SKELETON, dir: NAVIGATION_DIRECTIONS.FORWARD });
    }

    // Add an event listener to listen to the event triggered from initialFlowStatus.ts to get the data
    document.addEventListener(
      FLOW_STATUS_CUSTOM_EVENTS.SEND_FLOW_STATUS,
      async function handleSendFlowStatusEvent(e: Event) {
        const data = (e as CustomEvent).detail;
        if (!data) return;

        if (data?.error) {
          const parsedError = parseError(data.error);
          handleError(parsedError);
        } else {
          handleGetFlowResponse(data, NAVIGATION_DIRECTIONS.FORWARD, false);
        }
        document.removeEventListener(FLOW_STATUS_CUSTOM_EVENTS.SEND_FLOW_STATUS, handleSendFlowStatusEvent);
      },
    );
    const event = new CustomEvent(FLOW_STATUS_CUSTOM_EVENTS.FETCH_FLOW_STATUS);
    document.dispatchEvent(event);
  };

  const setIsCCPA = () => {
    isCCPARef.current = window.location.href.includes('/v2/authn/signin');
  };

  useEffect(() => {
    getInitialStatus();
    setIsCCPA();
  }, []);

  useEffect(() => {
    let handlePopState;
    if (authCallbackResponse?.response) {
      handlePopState = shouldHandleBrowserBack(
        authCallbackResponse.response?.status,
        authCallbackResponse.response.devices,
      );
    }
    // Adding popstate event listener only for flows/screens where it is needed.
    if (handlePopState) {
      // Detect when user uses Back/Forward Button of browser to navigate
      window.addEventListener('popstate', pathChange);
    }
    // remove the event listener when component unmounts
    return () => {
      window.removeEventListener('popstate', pathChange);
    };
  }, [authCallbackResponse?.response?.status, authCallbackResponse?.response?.devices]);

  /**
   * This is custom promise to wait for HCaptcha script to load.
   * This is used to ensure that the HCaptcha script is loaded before executing the HCaptcha verification.
   */
  const waitForHCaptchaScriptToLoad = () => {
    return new Promise((resolve, reject) => {
      hCaptchaPromiseRef.current = { resolve, reject };
    });
  };

  /**
   * This function is triggered when the HCaptcha script is loaded.
   * This is passed as a callback to the 'HCaptcha' component onLoad() function.
   * It resolves the promise to indicate that the script is loaded.
   */
  const onHCaptchaLoad = () => {
    hCaptchaPromiseRef.current?.resolve('');
  };

  const onHCaptchaError = () => {
    hCaptchaPromiseRef.current?.reject('');
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      <ComponentMap />
      <HCaptcha
        onHCaptchaLoad={onHCaptchaLoad}
        onHCaptchaError={onHCaptchaError}
        flow={hCaptchaFlow as KeysOfHcaptchaFlows}
        ref={hCaptchaRef}
      />
    </AuthContext.Provider>
  );
};

export default Auth;
