import lightTheme from '@digital-hig/theme-data/lib/build/json/light/design-tokens.json';
import { css } from '@emotion/react';

const userAvatarStyles = css`
  margin-top: ${lightTheme.primitives.spacings[6]};
  width: 80px;
  height: 80px;
`;

export { userAvatarStyles };
