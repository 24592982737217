import { css } from '@emotion/react';

// Below styles are used to override the default styles of Typography component and apply styles of FormHelpertext
export const errorTextStyles = css({
  fontSize: 'inherit',
  color: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
});

export const errorLinkStyles = css({
  fontSize: 'inherit',
  color: 'inherit !important',
  textDecorationColor: 'inherit !important',
});
