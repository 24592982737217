import { css } from '@emotion/react';

export const hCaptchaGlobalStyles = css`
  #root + div > div:nth-of-type(2) /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */ {
    opacity: 0.8 !important;
  }
`;

export const HcaptchaCustomIdpTheme = {
  palette: {
    primary: {
      main: '#000000',
    },
    warn: {
      main: '#EB5757',
    },
    text: {
      heading: '#000000',
      body: '#000000',
    },
    grey: {
      100: '#FAFAFA',
      200: '#F5F5F5',
      300: '#E0E0E0',
      400: '#D7D7D7',
      500: '#BFBFBF',
      600: '#919191',
      700: '#555555',
      800: '#333333',
      900: '#222222',
      1000: '#14191F',
    },
  },
  component: {
    skipButton: {
      main: {
        fill: '#000000',
        border: '#919191',
        text: '#FFFFFF',
      },
      hover: {
        fill: '#555555',
        border: '#919191',
        text: '#FFFFFF',
      },
    },
    verifyButton: {
      main: {
        fill: '#000000',
        border: '#919191',
        text: '#FFFFFF',
      },
      hover: {
        fill: '#555555',
        border: '#919191',
        text: '#FFFFFF',
      },
    },
  },
};
