// /** @jsxImportSource @emotion/react */
import {
  AUTH_ACTIONS,
  CUSTOM_AUTH_STATUS,
  EMAIL_ERRORS,
  ERROR_TYPE,
  EVENT_TEXT,
  FLOW_NAME,
  NAVIGATION_DIRECTIONS,
  PAGE_VIEWS,
  UI_PROMPTS,
} from 'appConstants';
import EmailScreen from 'common/components/EmailScreen/EmailScreen';
import AuthContext from 'context/authContext';
import authNamespaces from 'nameSpaces/authNameSpaces';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthError } from 'types';
import { getEventLocation, removeHtmlTags } from 'utilities/commonUtils';
import { getErrorMessages } from 'utilities/errorUtils';
import { trackClick, trackError } from 'utilities/tealiumAnalytics';

const CreateAccountEmailScreen = () => {
  const { t } = useTranslation([authNamespaces.createAccount, authNamespaces.common, authNamespaces.email]);
  const { authCallBack, updateStatus, userEmail, uiPrompts } = useContext(AuthContext);

  const isActivateAccount = uiPrompts.includes(UI_PROMPTS.ACTIVATE_ACCOUNT);

  const onSignInClick = () => {
    // tracking the sign in link here instead of the CustomInput component
    trackClick({
      link_location: getEventLocation(
        isActivateAccount ? FLOW_NAME.INVITE : FLOW_NAME.CREATE_ACCOUNT,
        PAGE_VIEWS.EMAIL_SCREEN,
      ),
      link_text: EVENT_TEXT.SIGN_IN_LINK,
      link_url: '#',
    });
    return authCallBack(null, AUTH_ACTIONS.SWITCH_TO_LOGIN);
  };

  const handleErrorCallback = (error: AuthError, responseStatus: string) => {
    trackError({
      error_code: responseStatus,
      error_reason: error.code ?? '',
      error_message: removeHtmlTags(
        t(getErrorMessages(error, authNamespaces.createAccount)[0], {
          ns: authNamespaces.email,
          lng: 'root',
        }),
      ),
      error_type: ERROR_TYPE.SERVER_SIDE_VALIDATION,
      error_location: getEventLocation(
        isActivateAccount ? FLOW_NAME.INVITE : FLOW_NAME.CREATE_ACCOUNT,
        PAGE_VIEWS.EMAIL_SCREEN,
      ),
    });
    if (error.code === EMAIL_ERRORS.LOGIN_DISABLED) {
      updateStatus({
        status: CUSTOM_AUTH_STATUS.CREATE_ACCOUNT_LOGIN_DISABLED,
        dir: NAVIGATION_DIRECTIONS.FORWARD,
      });
    } else {
      const errorText = t(getErrorMessages(error, authNamespaces.createAccount)[0], { ns: authNamespaces.email });
      return errorText;
    }
    return '';
  };

  return (
    <EmailScreen
      showTos
      showFooter
      heading={t(isActivateAccount ? 'ACTIVATE_ACCOUNT' : 'CREATE_YOUR_ACCOUNT')}
      bodyText={isActivateAccount ? t('ACTIVATE_ACCOUNT_DESCRIPTION') : ''}
      showEmailInput={!isActivateAccount}
      subHeading={isActivateAccount ? userEmail : ''}
      formSubmitAction={AUTH_ACTIONS.SUBMIT_EMAIL}
      footerText={t('ALREADY_HAVE_AN_ACCOUNT')}
      footerLinkText={t('SIGN_IN', { ns: authNamespaces.common })}
      handleFlowChange={onSignInClick}
      handleErrorCallback={handleErrorCallback}
      pageFlow={isActivateAccount ? FLOW_NAME.INVITE : FLOW_NAME.CREATE_ACCOUNT}
    />
  );
};

export default CreateAccountEmailScreen;
