export interface LogEntry {
  message: string;
  error?: string;
  componentStack?: string | null;
}

export interface LogLevels {
  level: number;
  name: string;
}

export interface LoggerDataPayload extends LogLevels {
  logPayload: LogEntry;
}

const defineLogLevel = (level: number, name: string) => {
  return { level, name };
};

export type LogHandler = ((logPayload: LoggerDataPayload) => void) | undefined;

export type Handler = (logPayload: LoggerDataPayload) => void;

const DEBUG = defineLogLevel(1, 'DEBUG');
const INFO = defineLogLevel(2, 'INFO');
const WARN = defineLogLevel(3, 'WARN');
const ERROR = defineLogLevel(4, 'ERROR');

const Logger = () => {
  // consumers decide the handling method, eg, they can choose to use splunk.
  let logHandler: LogHandler = (logPayload: LoggerDataPayload) => {
    console.log(logPayload);
  };

  const invoke = (level: LogLevels, logPayload: LogEntry) => {
    if (logHandler) {
      logHandler({ ...level, logPayload });
    } else {
      console.log({ ...level, logPayload });
    }
  };

  const setHandler = (handler?: Handler) => {
    logHandler = handler;
  };

  const debug = (logPayload: LogEntry) => {
    invoke(DEBUG, logPayload);
  };

  const info = (logPayload: LogEntry) => {
    invoke(INFO, logPayload);
  };

  const warn = (logPayload: LogEntry) => {
    invoke(WARN, logPayload);
  };

  const error = (logPayload: LogEntry) => {
    invoke(ERROR, logPayload);
  };

  return {
    setHandlerMethod: setHandler,
    debugLogMethod: debug,
    infoLogMethod: info,
    warnLogMethod: warn,
    errorLogMethod: error,
    DEBUG,
    INFO,
    WARN,
    ERROR,
  };
};
export default Logger;
