const obs = {
  setUserId: (userId: string | null) => {
    // Set user identifier for the current flow
    window.newrelic?.setUserId(userId ?? null);
  },
  setCustomAttribute: (attribute: string, value: string) => {
    // Set custom attribute for the given flow. If called inside interaction, it can attach this attribute to the current interaction
    window.newrelic?.setCustomAttribute(attribute, value);
  },
  setAttributeToCurrentInteraction: (attribute: string, value: string | null) => {
    // Set an attribute on the current interaction, e.g. API call
    window.newrelic?.interaction()?.setAttribute(attribute, value);
  },
  ignoreInteraction: () => {
    window.newrelic?.interaction()?.ignore();
  },
  logError: (error: Error, attributes = {}) => {
    window.newrelic?.noticeError(error, attributes);
  },
};

export default obs;
