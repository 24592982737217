import { AUTH_STATUS_BASED_PAGE_VIEWS } from 'appConstants';
import { DEFAULT_TOS_CONTENT, MARKETING_CONTENT, TOS_CONTENT, TOS_FIELDS } from 'appConstants/tosAndCommunication';
import LanguageCodeMappings from 'configs/languageMappings';
import { TosFieldsValues } from 'types';
import { getEventLocation } from './commonUtils';
import { trackClick } from './tealiumAnalytics';

const getCheckBoxListWithInitialValues = (
  uiLocale: string,
  showTos: boolean,
  showMarketingOptIn: boolean,
): TosFieldsValues[] => {
  const locale = LanguageCodeMappings[uiLocale.replace('-', '_').toLowerCase()];
  const tosCheckboxes = TOS_CONTENT[locale] ? TOS_CONTENT[locale] : DEFAULT_TOS_CONTENT;
  let checkBoxList = showTos ? tosCheckboxes : [];
  // Add marketing content checkbox if showMarketingOptIn is true
  if (showMarketingOptIn) {
    checkBoxList = [...checkBoxList, MARKETING_CONTENT];
  }
  // Set the initial value of all checkboxes to false
  return checkBoxList.map((tosData) => {
    return { ...tosData, value: false, showError: false };
  });
};

const updateAllTosErrorValues = (checkBoxList: TosFieldsValues[], updatedError: boolean): TosFieldsValues[] => {
  // Update the showError state of all tos checkboxes
  return checkBoxList.map((tosData) => {
    return { ...tosData, showError: !tosData.value ? updatedError : false };
  });
};

const getUpdatedTosValues = (
  checkBoxList: TosFieldsValues[],
  type: string,
  checkBoxValue: boolean,
): TosFieldsValues[] => {
  // Update the value of the checkbox that was clicked
  return checkBoxList.map((tosData) => {
    return tosData.type === type ? { ...tosData, value: checkBoxValue, showError: false } : tosData;
  });
};

const checkIfAllRequiredTosAccepted = (tosValues: TosFieldsValues[]): boolean => {
  // Check if all required tos are accepted
  return tosValues.reduce(
    (accepted, tosData) => (TOS_FIELDS.includes(tosData.type) ? accepted && !!tosData.value : accepted),
    true,
  );
};

const handleMarketOptInLinkClick = (event: MouseEvent, flowType: string, status: string) => {
  const target = event.target as HTMLElement;
  // Handle link click
  if (target.tagName.toLocaleLowerCase() === 'a') {
    const link = target as HTMLAnchorElement;
    const href = link.href;
    const eventText = link.innerText;

    // Track link click event
    trackClick({
      link_location: getEventLocation(flowType, AUTH_STATUS_BASED_PAGE_VIEWS[status]?.page_content_name),
      link_text: eventText,
      outbound: true,
      link_url: href, // Add the URL of the clicked link to the event
    });
  }
};

export {
  checkIfAllRequiredTosAccepted,
  getCheckBoxListWithInitialValues,
  getUpdatedTosValues,
  handleMarketOptInLinkClick,
  updateAllTosErrorValues,
};
