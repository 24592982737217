import { RESEND_COUNTDOWN_SIXTY, RESEND_DATA, RESEND_STATUS, TIMER_TYPE } from 'appConstants/otp';
import { GetInitialResendStateParams, ResendData, ShouldShowResendOptionParams } from 'types';
import { getFlowId } from './commonUtils';

export const checkFlowIdAndFlowType = (flowType: string, resendData: ResendData) => {
  // when user refreshes then flowType gets clear from authContext. That time we just check flowId.
  return getFlowId() === resendData?.flowId && (flowType ? flowType === resendData?.flowType : true);
};

export const getInitialResendState = ({ flowType, resendData }: GetInitialResendStateParams) => {
  // If the flowId and flowType matches with the resendData from localStorage, return the status from resendData
  if (checkFlowIdAndFlowType(flowType, resendData)) {
    return resendData?.status ?? RESEND_STATUS.TIMER;
  } else {
    return RESEND_STATUS.TIMER;
  }
};

export const shouldShowResendOption = ({ flowType, resendData, resendTimer }: ShouldShowResendOptionParams) => {
  const multiplicationFactorSec = resendData?.resendTimerType === TIMER_TYPE.MINUTES ? RESEND_COUNTDOWN_SIXTY : 1;

  return (
    resendData &&
    //to check if user closes the tab when timer is running and then open in new tab or refreshes the browser after 30 seconds/10 minutes having same flowId and flowType
    Number(resendData?.timeStamp) + resendTimer * multiplicationFactorSec * 1000 < Date.now() &&
    checkFlowIdAndFlowType(flowType, resendData)
  );
};

export const setResendDataInLocalStorage = ({ status, flowType, resendTimerType = TIMER_TYPE.SECONDS }: ResendData) => {
  try {
    window?.localStorage?.setItem(
      RESEND_DATA,
      JSON.stringify({ status, flowType, resendTimerType, timeStamp: Date.now(), flowId: getFlowId() }),
    );
  } catch (error) {
    console.log('Error while setting resendData in localStorage');
  }
};

export const getResendDataFromLocalStorage = (): ResendData => {
  let resendData = null;
  try {
    resendData = JSON.parse(window?.localStorage?.getItem(RESEND_DATA) as string);
  } catch (error) {
    console.log('Error while parsing resendData from localStorage');
  }
  return resendData;
};

export const deleteResendDataFromLocalStorage = () => {
  try {
    window?.localStorage?.removeItem(RESEND_DATA);
  } catch (error) {
    console.log('Error while deleting resendData from localStorage');
  }
};
