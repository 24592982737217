import { AUTH_ACTIONS, AUTH_STATUS, EVENT_TEXT, NAVIGATION_DIRECTIONS } from 'appConstants';
import AuthContext from 'context/authContext';
import authNamespaces from 'nameSpaces/authNameSpaces';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EditEmailProps } from 'types/EditEmail';
import { deleteResendDataFromLocalStorage } from 'utilities/otpScreenUtils';
import SubHeadingWithLink from '../SubHeadingWithLink/SubHeadingWithLink';

const EditEmail: React.FC<EditEmailProps> = ({ linkLocation, disableForm, setDisableForm, href, target }) => {
  const { authCallBack, userEmail, isCCPA, updateStatus } = useContext(AuthContext);
  const { t } = useTranslation(authNamespaces.common);

  const onEdit = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (isCCPA) {
      updateStatus({ status: AUTH_STATUS.EMAIL_REQUIRED, dir: NAVIGATION_DIRECTIONS.REVERSE });
    } else {
      setDisableForm(true);
      await authCallBack(null, AUTH_ACTIONS.CLEAR_EMAIL, '', NAVIGATION_DIRECTIONS.REVERSE);
      deleteResendDataFromLocalStorage();
      setDisableForm(false);
    }
  };

  return (
    <SubHeadingWithLink
      headingTextId="user-email"
      headingText={userEmail}
      disableForm={disableForm}
      tracking={{
        linkLocation: linkLocation ?? '',
        linkText: EVENT_TEXT.SWITCH_LINK,
      }}
      outBound={!!href}
      link={{
        onClick: onEdit,
        href: href ?? '#',
        text: t('SWITCH'),
        target: target,
      }}
    />
  );
};

export default EditEmail;
