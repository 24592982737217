/* eslint-disable */
import { createContext } from 'react';
import { AuthContextData, AuthPayload, CallbackResponse, Flow } from 'types';

export const authContextInitialValue = {
  flowStatus: {
    status: '',
    dir: '',
  },
  authCallBack: (
    _payload: AuthPayload | null,
    _action: string | null,
    _reauthUrl?: string,
    _dir?: Flow['dir'],
    _showIntermediateScreen?: boolean,
    _hCaptchaFlow?: string,
  ) => {
    return Promise.resolve({} as CallbackResponse);
  },
  userEmail: '',
  uiPrompts: Array<string>(),
  setUserEmail: (_email: string) => {},
  authCallbackResponse: {
    isSuccess: false,
    response: {
      status: '',
    },
    error: {
      code: '',
      message: '',
    },
    isForbidEntryScheduledToBeDisplayed: false,
    responseStatus: '',
    optInLabel: '',
  },
  profilePicture: '',
  updateStatus: (_flowStatus: Flow) => {},
  isCCPA: false,
  authorizeUrl: '',
  flowType: '',
  clientID: '',
  resumeAuthSuccessCallback: () => {},
} as AuthContextData;

const AuthContext = createContext(authContextInitialValue);

export default AuthContext;
