/** @jsxImportSource @emotion/react */
import SvgProfile from '@digital-hig/icon/lib/esm/display-icons/profile';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import AuthContext from 'context/authContext';
import { useContext } from 'react';
import { userAvatarStyles } from './ProfilePictureStyles';

interface ProfilePictureProps {
  profilePictureUrl?: string;
}

const ProfilePicture = ({ profilePictureUrl }: ProfilePictureProps) => {
  const { profilePicture } = useContext(AuthContext);
  const profilePictureToRender = profilePictureUrl ?? profilePicture;
  return (
    <Box data-testid="profile-pic" css={userAvatarStyles}>
      {profilePictureToRender ? (
        <Avatar alt="Profile Image" src={profilePictureToRender} css={userAvatarStyles} />
      ) : (
        <SvgProfile />
      )}
    </Box>
  );
};

export default ProfilePicture;
